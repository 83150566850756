import styled from "styled-components";
export const GroupItems = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  max-width: 1920px;
  background: #fff;
`;

export const Text = styled.p`
  font-family: "Open Sans", sans-serif;
  width: 90%;
  max-width: 550px;
  text-align: center;
  font-size: 18px;
  line-height: 1.5em;
  margin-bottom: 30px;
  @media (max-width: 1768px) {
    font-size: 16px;
  }
`;

export const GroupForm = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  margin-top: 60px;

  @media (max-width: 540px) {
    width: 85%;
    margin-top: 35px;
  }
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
`;

export const SubmitButton = styled.button`
  display: flex;
  width: 100%;
  max-width: 200px;

  align-items: center;
  justify-content: center;
  align-self: center;
  font-family: "GilroyBold", sans-serif;
  font-size: 14px;
  color: #fff;
  border: none;
  margin: 24px auto 55px auto;
  background-color: #e67d12;
  padding: 16px 32px;
  transition: 300ms ease;

  &:hover {
    transform: scale(0.98);
  }
`;

export const IconLoaderArea = styled.div`
  display: ${props => (props.loading ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  max-height: 30px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  p {
    width: 100%;
    max-width: 680px;
    font-size: 17px;
    line-height: 24px;
    color: #000;
    text-align: center;

    &.description {
      max-width: 530px;
      margin-bottom: 32px;
    }
  }
`;

export const ModalTitle = styled.h1`
  font-family: "Gilroy-Bold";
  font-size: 32px;
  color: #000;
  margin-bottom: 24px;

  span {
    color: #ed8c2b;
  }
`;
