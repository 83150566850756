import styled, { css } from "styled-components";

export const Container = styled.div`
    display:block;
    width:100%;
    background:#fff;
`;
export const Banner = styled.div`
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-contet: center;
    min-height:350px;
    background:#fff;
    padding:20px;
}
`;
export const tituloBanner = styled.h1`

font-family: "BaskervilleItalic";
font-size:68px;    
color:#262C40;
text-align:center;
margin: 50px 0 -20px 0;
}
span {
    display:block;
    font-family: "GilroyBold";
    font-size:56px;
}
`;
export const subBanner = styled.h2` {
    font-family: "GilroyBold";
    color:#FF7E01;
    font-size:18px;
    font-weight:bold;
    text-transform:uppercase;
    margin-top:23px;
    letter-spacing: 2px;

}`;

export const footBanner = styled.h4` {
    width:420px;
    text-align:center;
    font-family: "BaskervilleBT";
    font-size:22px;
    color:#262C40
    font-weight:normal;
    margin-top:24px;
    @media only screen and (max-width: 414px) {
        width:95%;
    }

}`;

export const warningBanner = styled.p` {
    font-family: "BaskervilleBT";
    color:#000;
    text-align:center;
    margin: 25px 0;

}`; 



export const AreaProg = styled.div` {
    display:flex;
    flex-direction:column;
    align-items:center;
    width:100%;
    background:#FFF6F2;
    padding:30px 0;
}`;

export const GroupProg = styled.div` {
    display:flex;
    justify-content:center;
    width:30%;
    @media only screen and (max-width: 1920px) {
        width:50%;
    }
    @media only screen and (max-width: 1280px) {
        width:70%;
    }
    @media only screen and (max-width: 970px) {
        width:85%;
    }
    @media only screen and (max-width: 768px) {
        width:100%;
    }
        
        
}`;

export const BoxLeft = styled.div` {
    display:flex;
    flex-direction:column;
    width:50%;
    min-height:100x;
    border-right: 1px solid #392C23;
    padding:30px 24px; 
    
    
   
}`;
export const BoxRight = styled.div` {
    display:flex;
    flex-direction:column;
    width:50%;
    min-height:400px;
    padding:30px 24px;

     
}`;

export const GroupDate = styled.div` {
    display:flex;
    flex-direction:column;
    align-items: flex-end; 
    width:100%;
    min-height:150px;
    &.last {
        min-height:70px; 
               
    }
    @media only screen and (max-width: 360px) {
        min-height:200px;
    }
   
}`;    
export const Date = styled.p` {
    font-family: "GilroyBold";
    font-size:14px;
    width:100px;
    color:#fff;
    background:#0FA592;
    padding:8px;
    text-align:center;

    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
      
}`;

export const GroupText = styled.div`{
    display:flex;
    flex-direction:column;
    flex-wrap:wrap;
    width:100%;
    min-height: 150px;
    
    &.last {
        min-height:70px;
    }
    @media only screen and (max-width: 360px) {
        min-height:200px;
    }
    
}`;

export const Week = styled.p` {
    font-family: "GilroyBold";
    font-size:16px;
    color:#392C23;
    font-weight:bold;
    margin-bottom:8px;
    
}`;
export const Text = styled.p` {
    font-family: "BaskervilleBT";
    font-size:21px;
    color:#585858;
    font-weight:normal;
     
}`;