import React, { useEffect } from "react";
import WOW from "wowjs";
import { Helmet } from "react-helmet";

import TopInterna from "../../components/TopoInterna";
import Estrutura from "../../components/Estruturas";
import Informacoes from "../../components/Informacoes";
import Menu from "../../components/Menu";
import Festas from "../../components/Festas";
import Reserva from "../../components/Reserva";
import NewReserva from "../../components/NewReserva";
import ReservaMobile from "../../components/Reserva/mobile.js";

import ImageEventos1 from "../../assets/eventos/foto-01.jpg";
import ImageEventos2 from "../../assets/eventos/foto-02.jpg";
import ImageEventos3 from "../../assets/eventos/foto-03.jpg";
import ImageEventos4 from "../../assets/eventos/foto-04.jpg";
import ImageEventos5 from "../../assets/eventos/foto-05.jpg";
import ImageEventos6 from "../../assets/eventos/foto-06.jpg";
import ImageEventos7 from "../../assets/eventos/foto-07.jpg";
import ImageEventos8 from "../../assets/eventos/foto-08.jpg";
import ImageEventos9 from "../../assets/eventos/foto-09.jpg";
import ImageEventos10 from "../../assets/eventos/foto-10.jpg";
import ImageEventos11 from "../../assets/eventos/foto-11.jpg";
import ImageEventos12 from "../../assets/eventos/foto-12.jpg";
import ImageEventos13 from "../../assets/eventos/foto-13.jpg";
import ImageEventos14 from "../../assets/eventos/foto-14.jpg";
import ImageEventos15 from "../../assets/eventos/foto-15.jpg";
import ImageEventos16 from "../../assets/eventos/foto-16.jpg";
import ImageEventos17 from "../../assets/eventos/foto-17.jpg";
import ImageEventos18 from "../../assets/eventos/foto-18.jpg";
import ImageEventos19 from "../../assets/eventos/foto-19.jpg";

export default function PaginaEventos() {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const datasTopo = {
    titulo: "Eventos",
    titulo2: "particulares",
    descricao:
      "Resort 3 Pinheiros oferece um moderno centro de convenções totalmente equipado com tecnologia de ponta, salas de apoio, wi-fi de alta performance, áreas externas, coffee break e tudo o que você precisar para garantir o sucesso de seu evento.",
    colorTitutlo: "#392C23",
    backgroundColorTitulo: "#fff",
    fontSizeTitulo: "62px",
    fontSizeTitulo2: "52px",
    colorDescricao: "#585858",
    fontSizeDescricao: "22px",
    paddingTopTP: "81px",
    displayButton: "none",
  };

  const datasImagens = [
    { id: 1, imagem: ImageEventos1 },
    { id: 2, imagem: ImageEventos2 },
    { id: 3, imagem: ImageEventos3 },
    { id: 4, imagem: ImageEventos4 },
    { id: 5, imagem: ImageEventos5 },
    { id: 6, imagem: ImageEventos6 },
    { id: 7, imagem: ImageEventos7 },
    { id: 8, imagem: ImageEventos8 },
    { id: 9, imagem: ImageEventos9 },
    { id: 10, imagem: ImageEventos10 },
    { id: 11, imagem: ImageEventos11 },
    { id: 12, imagem: ImageEventos12 },
    { id: 13, imagem: ImageEventos13 },
    { id: 14, imagem: ImageEventos14 },
    { id: 15, imagem: ImageEventos15 },
    { id: 16, imagem: ImageEventos16 },
    { id: 17, imagem: ImageEventos17 },
    { id: 18, imagem: ImageEventos18 },
    { id: 19, imagem: ImageEventos19 },
  ];

  return (
    <>
      <Helmet>
        <title>Eventos - Resort Fazenda 3 Pinheiros</title>
      </Helmet>
      <Menu />
      <NewReserva />
      {/* <Reserva /> */}

      <TopInterna
        titulo="Festas"
        titulo2="Especiais"
        subtitulo="Eventos"
        bgColor="#262C40"
        fontColor="#fff"
        texto="Aqui também tem festa e comemoração! Confira os eventos que agitam o 3 Pinheiros e planeje sua hospedagem!:"
      />
      <ReservaMobile bgColor="#262C40" />

      <Festas />
      <Estrutura
        datasTopo={datasTopo}
        datasImagens={datasImagens}
        datasButton=""
      />
      <Informacoes />
    </>
  );
}
