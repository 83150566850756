import Styled from "styled-components";
import ImagemBGG01 from "../../assets/icones/tpi01.png";
import ImagemBGG02 from "../../assets/icones/tpi02.png";
import ImagemBGG03 from "../../assets/icones/tpi03.png";

export const Container = Styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;  
  width:1100px;

  @media only screen and (max-width: 1101px) {
    width: 100%;
  }


`;

// export const TopoInterna = Styled.div(props => ({
//     background-color:'#0FA592';
//     display:'inline-block';
//     padding-top: '85px';
//     padding-bottom: '116px';
//     width: '100%';
// }));

export const TopoInterna = Styled.div`
  display: inline-block;
  padding-top: 85px;
  padding-bottom: 116px;
  width: 100%;
  ${props => props.bgColor && `background-color: ${props.bgColor}`};

  @media only screen and (max-width: 1025px) {
    padding-top: 55px;
    padding-bottom: 66px;
  }

  @media only screen and (max-width: 426px) {
    padding-top: 35px;
    padding-bottom: 46px;
  }


`;

export const Top = Styled.div`
  margin-top: 20px;  
  margin-bottom: 30px;
  text-align:center;   
  position: relative;
`;

export const Titulo = Styled.h2`
text-align: center;    
font-size: 77px;  
line-height: 63px;
font-weight: 500;  
margin-bottom: 17px;  
font-family: 'BaskervilleItalic', sans-serif;
${props => props.fontColor && `color: ${props.fontColor}`};

span {
  text-align: center;
  font-size: 63px;  
  display:block;
}   


@media only screen and (max-width: 1025px) {
  font-size: 67px;  
  span {
  font-size: 53px;  
} 


@media only screen and (max-width: 769px) {
  font-size: 57px; 
  line-height: 53px; 
  span {
  font-size: 43px;  
} 

@media only screen and (max-width: 426px) {
  font-size: 47px; 
  line-height: 43px; 
  span {
  font-size: 33px;  
} 




`;

export const SubTitulo = Styled.h3`
  color: #FF7E01;
  font-size: 18px;
  font-family: 'GilroyBold', sans-serif;
  margin-bottom: 18px;
  text-transform: uppercase;

  @media only screen and (max-width: 426px) {
    font-size: 16px;
    margin-bottom: 15px;

  }


`;

export const Descricao = Styled.p`
${props => props.fontColor && `color: ${props.fontColor}`};
  font-size: 22px;
  width: 432px;
  margin: auto;

  @media only screen and (max-width: 769px) {
    font-size: 20px;
    width: 50%;
  }

  @media only screen and (max-width: 700px) {
    width: 70%;
  }

  @media only screen and (max-width: 426px) {
    width: 90%;
    font-size: 20px;

  }



  @media only screen and (max-width: 376px) {
    font-size: 18px;
  }


`;

export const BGG01 = Styled.span`
  background-image: url(${ImagemBGG01});
  background-repeat: no-repeat;
  width: 179px;
  height: 169px;
  position: absolute;
  top: 0px;
  z-index: 2;
  left: -55px;
  background-size: 179px 169px;
  display: none;
  ${props => `display: ${props.displayImgBG}`};

  @media only screen and (max-width: 1225px) {
    width: 129px;
    height: 119px;
    background-size: 129px 119px;
    left: 3%;
  
  }
  @media only screen and (max-width: 700px) {
    display: none;
  }


`;

export const BGG02 = Styled.span`
  background-image: url(${ImagemBGG02});
  background-repeat: no-repeat;
  width: 129px;
  height: 140px;
  position: absolute;
  top: 178px;
  z-index: 2;
  left: 88px;
  background-size: 129px 140px;
  display: none;
  ${props => `display: ${props.displayImgBG}`};

  @media only screen and (max-width: 1225px) {
    width: 109px;
    height: 120px;
    left: 4%;
    background-size: 109px 120px;
  }
  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

export const BGG03 = Styled.span`
  background-image: url(${ImagemBGG03});
  background-repeat: no-repeat;
    width: 307px;
    height: 296px;
    position: absolute;
    top: 0px;
    z-index: 2;
    right: -45px;
    background-size: 307px 296px;
    display: none;
  ${props => `display: ${props.displayImgBG}`};



  @media only screen and (max-width: 1225px) {
      width: 207px;
      height: 196px;
      right: 5%;
      background-size: 207px 196px;  
  }

  @media only screen and (max-width: 700px) {
    display: none;
  }


`;
