import React from "react";
import * as S from "./styles";
import imgTop from "../../../assets/acomodacoes.jpg";

export default props => {
  return (
    <>
      <S.Container>
        <S.LeftSide>
          <img src={imgTop} alt="Acomodações" />
        </S.LeftSide>
        <S.RightSide>
          <S.TitleItalic>Nossas</S.TitleItalic>
          <S.TitleBold>Acomodações</S.TitleBold>
          <S.TitleDestaque>Acomodações</S.TitleDestaque>
          <S.Text>
            Apartamentos que misturam a rusticidade da fazenda com toda a
            elegância e conforto que nossos hospedes merecem.
          </S.Text>
        </S.RightSide>
      </S.Container>
    </>
  );
};
