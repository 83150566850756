import styled from "styled-components";

import IconCheck from "../../assets/diretrizes/hotel-bell.png";
import IconHygiene from "../../assets/diretrizes/hygiene.png";
import IconBed from "../../assets/diretrizes/bed.png";
import IconTrees from "../../assets/diretrizes/trees.png";
import IconDinner from "../../assets/diretrizes/dinner.png";

const icons = {
  check: IconCheck,
  hygiene: IconHygiene,
  bed: IconBed,
  trees: IconTrees,
  dinner: IconDinner,
};

export const Container = styled.section`
  width: 100%;
`;

export const Hero = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background-color: #fff;
  padding: 64px 32px;
`;

export const ContentHero = styled.div`
  width: 100%;
  max-width: 540px;
`;

export const Title = styled.h1`
  font-family: "BaskervilleItalic";
  font-size: 68px;
  text-align: center;
  color: #262c40;
  line-height: 60px;
  margin-bottom: 16px;
`;

export const Bold = styled.span`
  font-family: "GilroyBold";
  font-size: 56px;
`;

export const Subtitle = styled.h6`
  font-family: "GilroyBold";
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 16px;
  color: #ff7e01;
`;

export const Info = styled.article`
  font-family: "BaskervilleBT";
  font-size: 20px;
  text-align: center;
  color: #262c40;
  margin-bottom: 32px;
`;

export const Content = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff6f2;
  padding: 54px 32px;
  margin: 0 auto;
`;

export const Wrap = styled.div`
  width: 100%;
  max-width: 660px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 54px;
`;

export const Icon = styled.img.attrs(
  ({ type }) => type && { src: icons[type] }
)`
  width: max-content;
  height: auto;
  margin-bottom: 24px;
  align-self: center;
`;

export const ItemTitle = styled.h4`
  font-family: "GilroyBold";
  font-size: 24px;
  color: #392c23;
  text-align: center;
  margin-bottom: 24px;
`;

export const Text = styled.article`
  font-family: "BaskervilleBT";
  font-size: 20px;
  text-align: center;
  color: #585858;
`;
