import styled from "styled-components";

import ImagemIconButton from "../../assets/icones/seta-btn_reservar.png";
import ImagemIconCalendario from "../../assets/icones/calendar.png";
import ImagemIconSeta from "../../assets/icones/down.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  position: relative;
  ${props => `bottom: ${props.bottomReserva}`};
  @media only screen and (max-width: 1250px) {
    /* bottom: 49px; */
    /* display: inline-block; */
    margin: auto;
    width: 90%;
  }

  @media only screen and (max-width: 990px) {
    width: 100%;
    bottom: 0px;
  }
`;

export const Reserva = styled.div`
  display: inline-block;
  width: 100%;
  background: #f4f4f4;
  ${props => `background: ${props.backgroundBlock}`};
  ${props => `z-index: ${props.zindexReserva}`};
  ${props => `position: ${props.positionReserva}`};

  @media only screen and (max-width: 990px) {
    position: relative;
  }

  @media only screen and (max-width: 650px) {
    display: none;
  }
`;

export const Content = styled.div`
  width: 1100px;
  padding: 55px;
  display: flex;
  margin: 0 auto;
  background-color: #f4f4f4;
  ${props => `background-color: ${props.backgroundReserva}`};
`;

export const Conteudo = styled.form`
  width: 1100px;
  padding: 55px;
  /* top: 808px; */
  display: inline-block;
  margin: auto;
  background-color: #f4f4f4;
  ${props => `background-color: ${props.backgroundReserva}`};

  input {
    background-color: #f4f4f4;
    ${props => `background-color: ${props.backgroundReserva}`};

    font-size: 20px;
    color: #000000;
    padding-bottom: 12px;
    border: none;
    border-bottom: 1px solid #c3c3c3;
    margin-right: 34px;
  }

  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #000000;
    font-size: 20px;
    padding-right: 80%;
  }
  input::-moz-placeholder {
    /* Firefox 19+ */
    color: #000000;
    font-size: 20px;
    padding-right: 80%;
  }
  input:-ms-input-placeholder {
    /* IE 10+ */
    color: #000000;
    font-size: 20px;
    padding-right: 80%;
  }
  input:-moz-placeholder {
    /* Firefox 18- */
    color: #000000;
    font-size: 20px;
    padding-right: 80%;
  }

  button.reserva {
    font-size: 18px;
    color: #000;
    text-transform: uppercase;
    border: none;
    background: transparent;
    font-family: "GilroyBold", sans-serif;
    cursor: pointer;
    padding: 15px;
    width: 140px;
    height: 41px;
    text-align: left;
    top: 4px;
    position: relative;
  }

  i {
    background-image: url(${ImagemIconButton});
    background-repeat: no-repeat;
    width: 41px;
    height: 41px;
    position: absolute;
    background-size: 41px 41px;
    right: -10px;
    top: 4px;
  }

  @media only screen and (max-width: 1250px) {
    width: 100%;
    padding: 40px;
    z-index: 10;
    /* top: -107px; */
    display: inline-block;
    margin: auto;
  }

  @media only screen and (max-width: 1100px) {
    padding: 20px;
  }
`;

export const Data = styled.div`
  position: relative;
  display: inline-block;
  i {
    background-image: url(${ImagemIconCalendario});
    background-repeat: no-repeat;
    width: 18px;
    height: 17px;
    position: absolute;
    background-size: 18px 17px;
    left: 167px;
  }
  input {
    width: 190px;
  }

  input::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    width: 71px;
  }
  input::-moz-placeholder {
    /* Firefox 19+ */
    width: 71px;
  }
  input:-ms-input-placeholder {
    /* IE 10+ */
    width: 71px;
  }
  input:-moz-placeholder {
    /* Firefox 18- */
    width: 71px;
  }
`;

export const Quantidade = styled.div`
  position: relative;
  display: inline-block;

  input {
    width: 90px;
  }

  i {
    background-image: url(${ImagemIconSeta});
    background-repeat: no-repeat;
    width: 12px;
    height: 7px;
    position: absolute;
    background-size: 12px 7px;
    left: 79px;
    top: 10px;
    z-index: 1;
  }
`;

export const FacaSuaReserva = styled.div`
  width: 100%;
  text-align: center;
  display: none;
  padding: 19px;
  margin-top: -1px;

  ${props => `background-color: ${props.bgColor}`};

  a.btnAction {
    cursor: pointer;
    text-transform: uppercase;
    font-size: 17px;
    color: #fff;
    /* height: 62px; */
    display: block;
    background-color: #ff7e01;
    border: none;
    padding: 13px 20px;
    margin: auto;
    border-radius: 20px;
    width: 240px;
  }
  @media only screen and (max-width: 650px) {
    display: inline-block;
  }

  @media only screen and (max-width: 540px) {
    a {
      font-size: 16px;
    }
  }
`;
