import React, { useState } from "react";
import Swiper from "react-id-swiper";
import "react-id-swiper/lib/styles/css/swiper.css";
import "./app.css";

import { BlocoDeFotos, BlocoDeFotosMobile } from "./styles";

import CampoDeFutebol from "../../assets/lazer/campo-de-futebol.png";
import PiscinaDia from "../../assets/lazer/piscina-dia.png";
import PiscinaNoite from "../../assets/lazer/piscina-noite.png";

function CompontentBlocoDeFotos() {
  const [swiper, updateSwiper] = useState(null);

  const params = {
    Swiper,
    initialSlide: 0,
    pagination: {
      el: ".swiper-pagination",
    },
    spaceBetween: 30,
    getSwiper: updateSwiper,
  };

  return (
    <>
      <BlocoDeFotos className="bloco-fotos">
        <div className="wow fadeInDown" data-wow-delay="350ms">
          <img src={PiscinaDia} />
        </div>

        <div className="wow fadeInDown" data-wow-delay="550ms">
          <img src={PiscinaNoite} />
        </div>

        <div className="wow fadeInDown" data-wow-delay="750ms">
          <img src={CampoDeFutebol} />
        </div>
      </BlocoDeFotos>
      <BlocoDeFotosMobile>
        <Swiper {...params}>
          <div className="wow fadeInDown" data-wow-delay="350ms">
            <img src={PiscinaDia} />
          </div>

          <div className="wow fadeInDown" data-wow-delay="550ms">
            <img src={PiscinaNoite} />
          </div>

          <div className="wow fadeInDown" data-wow-delay="750ms">
            <img src={CampoDeFutebol} />
          </div>
        </Swiper>
      </BlocoDeFotosMobile>
    </>
  );
}

export default CompontentBlocoDeFotos;
