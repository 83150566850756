import React from "react";
import Menu from "../Menu";
import * as S from "./styles";
import BnImg from "../../assets/banner-casamentos.jpg";

const BannerCasamentos = () => {
  return (
    <S.Container>
      <S.AreaMenu>
        <Menu />
      </S.AreaMenu>
      <S.GroupContent>
        <S.LeftPannel>
          <img src={BnImg} alt="Evento Casamento" />
          <div className="textMobile">
            <h2 className="orange">Realize seu Casamento</h2>
            <h2 className="white">em um Resort Fazenda</h2>
            <h2 className="white">Completo</h2>
            <p>
              Toda a estrutura que você precisa para tornar esse momento único,
              muito mais que especial, inesquecível.
            </p>
            <S.CallToAction>Fale com a gente</S.CallToAction>
          </div>
        </S.LeftPannel>
        <S.RightPannel>
          <S.GroupTitle>
            <h2 className="orange">Realize seu Casamento</h2>
            <h2 className="white">em um</h2>
            <h2 className="white">Resort Fazenda</h2>
            <h2 className="white">Completo</h2>
          </S.GroupTitle>
          <p>
            Toda a estrutura que você precisa para tornar esse momento único,
            muito mais que especial, inesquecível.
          </p>
          <S.CallToAction href="mailto:reserva01@3pinheiros.com.br">
            Fale com a gente
          </S.CallToAction>
        </S.RightPannel>
      </S.GroupContent>
    </S.Container>
  );
};
export default BannerCasamentos;
