import styled from "styled-components";

export const Cointainer = styled.section`
  width: 100%;
  height: auto;
  background: #efcfba;
  /* @media only screen and (max-width: 540px) {
    width: 400px;
  } */
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 1100px;
  height: auto;
`;

export const Title = styled.h2`
  font-family: "LibreBaskervilleItalic";
  font-size: 55px;
  letter-spacing: -1px;
  color: #1d4550;
  margin-bottom: -5px;
`;
export const Subtitle = styled.h3`
  font-family: "GilroySemiBold";
  font-size: 45px;
  letter-spacing: -1px;
  color: #1d4550;
  margin-bottom: 20px;
`;
