import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: #262c40;
  @media only screen and (max-width: 540px) {
    flex-direction: column;
  }
`;
export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  img {
    width: 100%;
    height: auto;
  }
  @media only screen and (max-width: 540px) {
    width: 100%;
  }
`;
export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  width: 50%;
  @media only screen and (max-width: 540px) {
    width: 100%;
  }
`;
export const TitleItalic = styled.div`
  font-family: "BaskervilleItalic";
  font-size: 77px;
  color: #fff;
  margin-bottom: -15px;
  @media only screen and (max-width: 540px) {
    font-size: 47px;
  }
`;
export const TitleBold = styled.div`
  font-family: "GilroyBold";
  font-size: 63px;
  color: #fff;
  margin-bottom: 13px;
  @media only screen and (max-width: 540px) {
    font-size: 28px;
  }
`;
export const TitleDestaque = styled.div`
  font-family: "GilroyBold";
  font-size: 18px;
  text-transform: uppercase;
  color: #ff7e01;
  letter-spacing: 2px;
  margin-bottom: 20px;
  @media only screen and (max-width: 540px) {
    font-size: 14px;
  }
`;
export const Text = styled.div`
  font-family: "BaskervilleRegular";
  color: #fff;
  font-size: 22px;
  width: 430px;
  text-align: center;
  @media only screen and (max-width: 540px) {
    width: 95%;
    font-size: 18px;
  }
`;
