import React, { useState } from "react";
import MaskedInput from "react-text-mask";
import DatePicker from "react-datepicker";
import { format, parseISO } from "date-fns";

import "react-datepicker/dist/react-datepicker.css";

import { Container, Reserva, Conteudo, Data, Quantidade } from "./styles";

function CompontentReserva({
  positionReserva,
  backgroundBlock,
  backgroundReserva,
  zindexReserva,
  topReserva,
  bottomReserva,
}) {
  const idHotel = "6628";
  const [quartos, setQuartos] = useState("");
  const [entrada, setEntrada] = useState(new Date());
  const [saida, setSaida] = useState(new Date());
  const [adultos, setAdultos] = useState("");
  const [criancas, setCriancas] = useState("");

  const [quartosError, setQuartosError] = useState(false);
  const [entradaError, setEntradaError] = useState(false);
  const [saidaError, setSaidaError] = useState(false);
  const [adultosError, setAdultosError] = useState(false);
  const [criancasError, setCriancasError] = useState(false);

  function SetErros(value, SetError) {
    if (value === "") {
      SetError(true);
    } else {
      SetError(false);
    }
  }

  const handleSubmit = evt => {
    evt.preventDefault();

    SetErros(entrada, setEntradaError);
    SetErros(quartos, setQuartosError);
    SetErros(saida, setSaidaError);
    SetErros(adultos, setAdultosError);
    SetErros(criancas, setCriancasError);

    if (
      entrada !== "" &&
      quartos !== "" &&
      saida !== "" &&
      adultos !== "" &&
      criancas !== ""
    ) {
      const newEntrada = format(entrada, "ddMMyyyy");
      const newSaida = format(saida, "ddMMyyyy");
      window.location.href = `https://myreservations.omnibees.com/default.aspx?q=${idHotel}&NRooms=${quartos}&CheckIn=${newEntrada}&CheckOut=${newSaida}&ad=${adultos}&ch=${criancas}`;
    } else {
      const newEntrada = format(entrada, "ddMMyyyy");
      const newSaida = format(saida, "ddMMyyyy");
      window.location.href = `https://myreservations.omnibees.com/default.aspx?q=${idHotel}&NRooms=${quartos}&CheckIn=${newEntrada}&CheckOut=${newSaida}&ad=${adultos}&ch=${criancas}`;
    }
  };

  return (
    <Reserva
      className="wow bounceInRight"
      data-wow-delay="50ms"
      positionReserva={positionReserva}
      backgroundBlock={backgroundBlock}
      zindexReserva={zindexReserva}
      topReserva={topReserva}>
      <Container bottomReserva={bottomReserva}>
        <Conteudo backgroundReserva={backgroundReserva} onSubmit={handleSubmit}>
          <Data>
            <DatePicker
              selected={entrada}
              onChange={date => setEntrada(date)}
              dateFormat="dd/MM/yyyy"
              // name="setEntrada"
              // style={{ borderBottom: entradaError ? "1px solid red" : "" }}
              // type="text"
              // value={entrada}
              // onChange={e => setEntrada(e.target.value)}
              // placeholder="Data de Entrada"
              // mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            />
            <i />
          </Data>

          <Data>
            <DatePicker
              selected={saida}
              onChange={date => setSaida(date)}
              dateFormat="dd/MM/yyyy"
              // type="text"
              // style={{ borderBottom: saidaError ? "1px solid red" : "" }}
              // value={saida}
              // onChange={e => setSaida(e.target.value)}
              // placeholder="Data de Saída"
              // mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
            />
            <i />
          </Data>
          <Quantidade>
            <input
              type="number"
              // style={{ borderBottom: quartosError ? "1px solid red" : "" }}
              value={quartos}
              onChange={e => setQuartos(e.target.value)}
              min="1"
              placeholder="Quartos"
            />
          </Quantidade>

          <Quantidade>
            <input
              type="number"
              // style={{ borderBottom: adultosError ? "1px solid red" : "" }}
              value={adultos}
              onChange={e => setAdultos(e.target.value)}
              min="0"
              placeholder="Adultos"
            />
          </Quantidade>

          <Quantidade>
            <input
              type="number"
              // style={{ borderBottom: criancasError ? "1px solid red" : "" }}
              value={criancas}
              onChange={e => setCriancas(e.target.value)}
              min="0"
              placeholder="Crianças"
            />
          </Quantidade>

          <button className="reserva" type="submit" value="Submit">
            Reservar
            <i />
          </button>
        </Conteudo>
      </Container>
    </Reserva>
  );
}
export default CompontentReserva;
