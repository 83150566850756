import Styled from "styled-components";

import pointer from "../../assets/icones/local.png";
import desktopMap from "../../assets/image-maps.jpg";
import src from "react-map-gl";

export const Container = Styled.div`
 display:flex;
 width: 100%; 
 min-height: 300px;
 background:color:orange
 align-content:stretch;
 div {
  display:flex;
  flex-direction:column;
  width:50%;
  height:auto;
  @media only screen and (max-width: 1100px) {
    width:100%;
  }

  &.mapMobile {
    display:none;
    @media only screen and (max-width: 1100px) {
    display:flex;
    width: 100%;
    height: 400px;
    background:yellow;
    }
  }

  &.map {
    position:relative;
    height:auto;
    @media only screen and (max-width: 1100px) {
    display:none;
  }
    div {
      height:100% !important;
   
    }

  }

  &.orange {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    background:#ff7e01;
    padding: 20px;
    height:auto;
    & img.point {
       width: 80%;
       max-width: 60px;
       height:auto;
       margin-bottom: 10px;
    }
  }
 }

  @media only screen and (max-width: 1100px) {
    flex-direction:column;
  }
`;

export const Title = Styled.h2`
  font-family: "GilroyBold", sans-serif;
  font-size: 48px;
  margin-top: 15px;
  color:#fff;
`;

export const subtitle = Styled.h3`
font-family: "BaskervilleRegular", sans-serif;
font-size: 18px;
letter-spacing: 1px;
color: #fff;
text-align:center
margin: 15px 0;
`;

export const Separator = Styled.span`
  width: 34px;
  height: 6px;
  font-family: "GilroyBold", sans-serif;
  display: block;
  background-color: rgb(255, 255, 255);
  margin-bottom:25px;

`;

export const GroupCards = Styled.article`
  display:flex
  flex-direction: row !important;
  flex-wrap:wrap;
  width:95%;
  max-width: 600px;
  height:auto;

`;

export const CardM = Styled.div`
  display:flex;
  flex-direction:column;
  width: 100%;
  max-width: 200px;
  height:auto;
  padding: 15px;
  margin:0;
 
  span.black {
    font-family: "GilroyBold", sans-serif;
    font-size:large;
    color:#000;
    margin-bottom: 15px;
  }
  span.white {
    font-family: "BaskervilleRegular", sans-serif;
    color:#fff;
    font-size:large;
    
  }
`;

export const Helico = Styled.p`
 font-family: "BaskervilleRegular", sans-serif;
    color:#fff;
    font-size:large;
    margin-top: 20px;
`;

export const GoogleMapDesktop = Styled.img`
  width: auto;
  height: 100%;

`;
export const GoogleMapMobile = Styled.img`
  width: 100%;
  height: auto;

`;
