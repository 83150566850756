import React, { useEffect } from "react";
import WOW from "wowjs";
import { Helmet } from "react-helmet";

import TopInterna from "../../components/TopoInterna";
import BlocoDeFotos from "../../components/BlocoDeFotos";
import ResortFazenda from "../../components/ResortFazenda";
import Estrutura from "../../components/Estruturas";
import Informacoes from "../../components/Informacoes";
import Diversao from "../../components/Diversao";
import Menu from "../../components/Menu";
import Reserva from "../../components/Reserva";
import NewReserva from "../../components/NewReserva";
import ReservaMobile from "../../components/Reserva/mobile";
import ProjetoPiscinas from "../../components/ProjetoPiscinas";

import ImageEstrutura1 from "../../assets/estrutura/lazer/lazer-01.jpg";
import ImageEstrutura2 from "../../assets/estrutura/lazer/lazer-02.jpg";
import ImageEstrutura3 from "../../assets/estrutura/lazer/lazer-03.jpg";
import ImageEstrutura4 from "../../assets/estrutura/lazer/lazer-04.jpg";
import ImageEstrutura5 from "../../assets/estrutura/lazer/lazer-05.jpg";
import ImageEstrutura6 from "../../assets/estrutura/lazer/lazer-06.jpg";
import ImageEstrutura7 from "../../assets/estrutura/lazer/lazer-07.jpg";
import ImageEstrutura8 from "../../assets/estrutura/lazer/lazer-08.jpg";
import ImageEstrutura118 from "../../assets/estrutura/lazer/lazer-09.jpg";
import ImageEstrutura119 from "../../assets/estrutura/lazer/lazer-10.jpg";
import ImageEstrutura120 from "../../assets/estrutura/lazer/lazer-11.jpg";
import ImageEstrutura121 from "../../assets/estrutura/lazer/lazer-12.jpg";
import ImageEstrutura122 from "../../assets/estrutura/lazer/lazer-13.jpg";
import ImageEstrutura123 from "../../assets/estrutura/lazer/lazer-14.jpg";
import ImageEstrutura124 from "../../assets/estrutura/lazer/lazer-15.jpg";
import ImageEstrutura125 from "../../assets/estrutura/lazer/lazer-16.jpg";
import ImageEstrutura126 from "../../assets/estrutura/lazer/lazer-17.jpg";
import ImageEstrutura127 from "../../assets/estrutura/lazer/lazer-18.jpg";
import ImageEstrutura128 from "../../assets/estrutura/lazer/lazer-19.jpg";
import ImageEstrutura129 from "../../assets/estrutura/lazer/lazer-20.jpg";
import ImageEstrutura130 from "../../assets/estrutura/lazer/lazer-21.jpg";
import ImageEstrutura131 from "../../assets/estrutura/lazer/lazer-22.jpg";
import ImageEstrutura132 from "../../assets/estrutura/lazer/lazer-23.jpg";
import ImageEstrutura133 from "../../assets/estrutura/lazer/lazer-24.jpg";
import ImageEstrutura134 from "../../assets/estrutura/lazer/lazer-25.jpg";
import ImageEstrutura135 from "../../assets/estrutura/lazer/lazer-26.jpg";
import ImageEstrutura136 from "../../assets/estrutura/lazer/lazer-27.jpg";
import ImageEstrutura137 from "../../assets/estrutura/lazer/lazer-28.jpg";
import ImageEstrutura138 from "../../assets/estrutura/lazer/lazer-29.jpg";
import ImageEstrutura139 from "../../assets/estrutura/lazer/lazer-30.jpg";
import ImageEstrutura140 from "../../assets/estrutura/lazer/lazer-31.jpg";
import ImageEstrutura141 from "../../assets/estrutura/lazer/lazer-32.jpg";
import ImageEstrutura142 from "../../assets/estrutura/lazer/lazer-33.jpg";
import ImageEstrutura143 from "../../assets/estrutura/lazer/lazer-34.jpg";
import ImageEstrutura144 from "../../assets/estrutura/lazer/lazer-35.jpg";
import ImageEstrutura145 from "../../assets/estrutura/lazer/lazer-36.jpg";
import ImageEstrutura146 from "../../assets/estrutura/lazer/lazer-37.jpg";
import ImageEstrutura147 from "../../assets/estrutura/lazer/lazer-38.jpg";
import ImageEstrutura148 from "../../assets/estrutura/lazer/lazer-39.jpg";
import ImageEstrutura149 from "../../assets/estrutura/lazer/lazer-40.jpg";
import ImageEstrutura150 from "../../assets/estrutura/lazer/lazer-41.jpg";
import ImageEstrutura151 from "../../assets/estrutura/lazer/lazer-42.jpg";
import ImageEstrutura152 from "../../assets/estrutura/lazer/lazer-43.jpg";
import ImageEstrutura153 from "../../assets/estrutura/lazer/lazer-44.jpg";
import ImageEstrutura154 from "../../assets/estrutura/lazer/lazer-45.jpg";
import ImageEstrutura155 from "../../assets/estrutura/lazer/lazer-46.jpg";

import rf00 from "../../assets/lazer/resort/rf-00.png";
import rf01 from "../../assets/lazer/resort/rf-01.png";
import rf02 from "../../assets/lazer/resort/rf-02.png";
import rf03 from "../../assets/lazer/resort/rf-03.png";
import rf04 from "../../assets/lazer/resort/rf-04.png";
import rf05 from "../../assets/lazer/resort/rf-05.png";
import rf06 from "../../assets/lazer/resort/rf-06.png";
import rf07 from "../../assets/lazer/resort/rf-07.png";

import ImageSpa1 from "../../assets/estrutura/spa/spa_01.jpg";
import ImageSpa2 from "../../assets/estrutura/spa/spa_02.jpg";
import ImageSpa3 from "../../assets/estrutura/spa/spa_03.jpg";
import ImageSpa4 from "../../assets/estrutura/spa/spa_04.jpg";
import ImageSpa5 from "../../assets/estrutura/spa/spa_05.jpg";
import ImageSpa6 from "../../assets/estrutura/spa/spa_06.jpg";
import ImageSpa7 from "../../assets/estrutura/spa/spa_07.jpg";
import ImageSpa8 from "../../assets/estrutura/spa/spa_08.jpg";
import ImageSpa9 from "../../assets/estrutura/spa/spa_09.jpg";
import ImageSpa10 from "../../assets/estrutura/spa/spa_10.jpg";
import ImageSpa11 from "../../assets/estrutura/spa/spa_11.jpg";
import ImageSpa12 from "../../assets/estrutura/spa/spa_12.jpg";

export default function PaginaLazer() {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const datasTopo = {
    titulo: "Nossa",
    titulo2: "estrutura",
    descricao:
      "Natureza exuberante e diversos ambientes de lazer, esporte e diversão para toda a família curtir e relaxar.",
    colorTitutlo: "#392C23",
    backgroundColorTitulo: "#fff",
    fontSizeTitulo: "62px",
    fontSizeTitulo2: "52px",
    colorDescricao: "#585858",
    fontSizeDescricao: "22px",
    paddingTopTP: "64px",
  };

  const datasImagens = [
    { id: 1, imagem: ImageEstrutura1 },
    { id: 2, imagem: ImageEstrutura2 },
    { id: 3, imagem: ImageEstrutura3 },
    { id: 4, imagem: ImageEstrutura4 },
    { id: 5, imagem: ImageEstrutura5 },
    { id: 6, imagem: ImageEstrutura6 },
    { id: 7, imagem: ImageEstrutura7 },
    { id: 8, imagem: ImageEstrutura8 },
    { id: 9, imagem: ImageEstrutura118 },
    { id: 10, imagem: ImageEstrutura119 },
    { id: 11, imagem: ImageEstrutura120 },
    // { id: 12, imagem: ImageEstrutura121 },
    // { id: 13, imagem: ImageEstrutura122 },
    { id: 14, imagem: ImageEstrutura123 },
    { id: 15, imagem: ImageEstrutura124 },
    { id: 16, imagem: ImageEstrutura125 },
    { id: 17, imagem: ImageEstrutura126 },
    { id: 18, imagem: ImageEstrutura127 },
    { id: 19, imagem: ImageEstrutura128 },
    { id: 20, imagem: ImageEstrutura129 },
    { id: 21, imagem: ImageEstrutura130 },
    { id: 22, imagem: ImageEstrutura131 },
    { id: 23, imagem: ImageEstrutura132 },
    { id: 24, imagem: ImageEstrutura133 },
    { id: 25, imagem: ImageEstrutura134 },
    { id: 26, imagem: ImageEstrutura135 },
    { id: 27, imagem: ImageEstrutura136 },
    { id: 28, imagem: ImageEstrutura137 },
    { id: 29, imagem: ImageEstrutura138 },
    // { id: 30, imagem: ImageEstrutura139 },
    { id: 31, imagem: ImageEstrutura140 },
    { id: 32, imagem: ImageEstrutura141 },
    { id: 33, imagem: ImageEstrutura142 },
    { id: 34, imagem: ImageEstrutura143 },
    // { id: 35, imagem: ImageEstrutura144 },
    { id: 36, imagem: ImageEstrutura145 },
    { id: 37, imagem: ImageEstrutura146 },
    { id: 38, imagem: ImageEstrutura147 },
    { id: 39, imagem: ImageEstrutura148 },
    { id: 40, imagem: ImageEstrutura149 },
    { id: 41, imagem: ImageEstrutura150 },
    { id: 42, imagem: ImageEstrutura151 },
    { id: 43, imagem: ImageEstrutura152 },
    { id: 44, imagem: ImageEstrutura153 },
    { id: 45, imagem: ImageEstrutura154 },
    { id: 46, imagem: ImageEstrutura155 },
    { id: 47, imagem: ImageSpa1 },
    { id: 48, imagem: ImageSpa2 },
    { id: 49, imagem: ImageSpa3 },
    { id: 50, imagem: ImageSpa4 },
    { id: 51, imagem: ImageSpa5 },
    { id: 52, imagem: ImageSpa6 },
    { id: 53, imagem: ImageSpa7 },
    { id: 54, imagem: ImageSpa8 },
    { id: 55, imagem: ImageSpa9 },
    { id: 56, imagem: ImageSpa10 },
    { id: 57, imagem: ImageSpa11 },
    { id: 58, imagem: ImageSpa12 },
  ];

  const ListaImagensResortFazenda = [
    { id: 1, imagem: rf00, nome: "Piscina com toboágua" },
    { id: 2, imagem: rf01, nome: "Piscina Aquecida" },
    { id: 3, imagem: rf02, nome: "Pedalinho" },
    { id: 4, imagem: rf03, nome: "Bar e Sauna" },
    { id: 5, imagem: rf04, nome: "Quadra de Futebol" },
    { id: 6, imagem: rf05, nome: "Quadra de Vôlei" },
    { id: 7, imagem: rf06, nome: "Quadra de Tênis" },
    { id: 8, imagem: rf07, nome: "Caiaque e Arvorismo" },
  ];

  return (
    <>
      <Helmet>
        <title>Lazer - Resort Fazenda 3 Pinheiros</title>
      </Helmet>
      <Menu />
      <NewReserva />
      {/* <Reserva /> */}
      <TopInterna
        titulo="Esporte"
        titulo2="e lazer"
        subtitulo="Diversão"
        bgColor="#0FA592"
        fontColor="#fff"
        texto="Venha se divertir e relaxar com toda a família com nossas diversas opções de lazer e esporte."
      />
      <ReservaMobile bgColor="#0FA592" />
      <BlocoDeFotos />
      <ResortFazenda ListaImagensResortFazenda={ListaImagensResortFazenda} />
      <Diversao />
      <Estrutura
        datasTopo={datasTopo}
        datasImagens={datasImagens}
        datasButton="/fotos"
      />
      <ProjetoPiscinas />
      <Informacoes />
    </>
  );
}
