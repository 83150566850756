import React, { useState, useCallback } from "react";
import { FacaSuaReserva } from "./styles";
import ComponentModal from "../ModalCaptureEmail";

function CompontentFacaSuaReserva({ bgColor }) {
  const [modalEmail, setModalEmail] = useState(false);

  const OpenModal = useCallback(() => {
    setModalEmail(true);
  }, []);

  return (
    <FacaSuaReserva bgColor={bgColor}>
      <ComponentModal
        modalController={modalEmail}
        onClose={() => setModalEmail(false)}
      />

      <a href="javascript:void(0)" className="btnAction" onClick={OpenModal}>
        Faça sua Reserva
      </a>
    </FacaSuaReserva>
  );
}
export default CompontentFacaSuaReserva;
