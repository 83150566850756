import React, { useEffect } from "react";
import WOW from "wowjs";
import { Helmet } from "react-helmet";

import TopInterna from "../../components/TopoInterna";
import Estrutura from "../../components/Estruturas";
import Informacoes from "../../components/Informacoes";
import Menu from "../../components/Menu";
import Refeicoes from "../../components/Refeicoes";
import Reserva from "../../components/Reserva";
import NewReserva from "../../components/NewReserva";
import ReservaMobile from "../../components/Reserva/mobile";

import ImagemPO1 from "../../assets/gastronomia/PO_00.jpg";
import ImagemPO2 from "../../assets/gastronomia/PO_01.jpg";
import ImagemPO3 from "../../assets/gastronomia/PO_02.jpg";
import ImagemPO4 from "../../assets/gastronomia/PO_03.jpg";
import ImagemPO5 from "../../assets/gastronomia/PO_04.jpg";
import ImagemPO6 from "../../assets/gastronomia/PO_05.jpg";
import ImagemPO7 from "../../assets/gastronomia/PO_06.jpg";
import ImagemPO8 from "../../assets/gastronomia/PO_07.jpg";

export default function PaginaGastronomia() {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const datasTopo = {
    titulo: "Produção",
    titulo2: "orgânica",
    descricao:
      "Natureza exuberante e diversos ambientes de lazer, esporte e diversão para toda a família curtir e relaxar.",
    colorTitutlo: "#392C23",
    backgroundColorTitulo: "#fff",
    fontSizeTitulo: "62px",
    fontSizeTitulo2: "52px",
    colorDescricao: "#585858",
    fontSizeDescricao: "22px",
    paddingTopTP: "64px",
    displayDescricao: "none",
    displayButton: "none",
    displayBGTop: "block",
  };

  const datasImagens = [
    { id: 1, imagem: ImagemPO1 },
    { id: 2, imagem: ImagemPO2 },
    { id: 3, imagem: ImagemPO3 },
    { id: 4, imagem: ImagemPO4 },
    { id: 5, imagem: ImagemPO5 },
    { id: 6, imagem: ImagemPO6 },
    { id: 7, imagem: ImagemPO7 },
    { id: 8, imagem: ImagemPO8 },
  ];

  return (
    <>
      <Helmet>
        <title>Gastronomia - Resort Fazenda 3 Pinheiros</title>
      </Helmet>
      <Menu />
      <NewReserva />
      {/* <Reserva /> */}
      <TopInterna
        titulo="Restaurante"
        titulo2="Antiqua"
        subtitulo="Do campo à mesa"
        bgColor="#fff"
        fontColor="#262C40"
        texto="Nossas refeições são servidas em um casarão histórico de quase 300 anos, com ingredientes orgânicos e aromas encantadores. À mesa ingredientes de produção própria ou de produtores da região. Pensão completa, ou seja, café da manhã, almoço, chá da tarde e jantar estão inclusos na diária."
        displayImgBG="block"
      />
      <ReservaMobile bgColor="#fff" />

      <Refeicoes />

      <Estrutura
        datasTopo={datasTopo}
        datasImagens={datasImagens}
        datasButton="/fotos"
      />
      <Informacoes />
    </>
  );
}
