import React from "react";
import  * as S from "./styles";

const modCarnaval = () => {
    return (
        <S.Container>
            <S.Banner>
                <S.tituloBanner>
                    Carnaval <span>3 Pinheiros</span>
                </S.tituloBanner>
                <S.subBanner>Confira a programação</S.subBanner>
                <S.footBanner>Aproveite o feriado de carnaval para relaxar em um lugar tranquilo</S.footBanner>
                <S.warningBanner>* Programação sujeita a alteração</S.warningBanner>
            </S.Banner>
            <S.AreaProg>
                <S.GroupProg>
                    <S.BoxLeft>
                        <S.GroupDate>
                            <S.Date>12/02/2021</S.Date>    
                        </S.GroupDate>
                        <S.GroupDate>
                            <S.Date>13/02/2021</S.Date>    
                        </S.GroupDate>
                        <S.GroupDate>
                            <S.Date>14/02/2021</S.Date>    
                        </S.GroupDate>
                        <S.GroupDate>
                            <S.Date>15/02/2021</S.Date>    
                        </S.GroupDate>
                        <S.GroupDate className="last">
                            <S.Date>16/02/2021</S.Date>    
                        </S.GroupDate>
                        
                    </S.BoxLeft>
                    <S.BoxRight>
                          <S.GroupText>
                              <S.Week>Sexta</S.Week>
                              <S.Text>Check-in</S.Text>
                              <S.Text>21h - Bingo </S.Text>
                          </S.GroupText>
                          <S.GroupText>
                              <S.Week>Sábado</S.Week>
                              <S.Text>Almoço: Feijoada</S.Text>
                              <S.Text>21h - Violão e Voz / Suzano</S.Text>
                          </S.GroupText>  
                          <S.GroupText>
                              <S.Week>Domingo</S.Week>
                              <S.Text>Almoço: Churrasco</S.Text>
                              <S.Text>21h - Show Duo Instrumental / Bar piscina</S.Text>
                          </S.GroupText>  
                          <S.GroupText>
                              <S.Week>Segunda</S.Week>
                              <S.Text>19h – Pizza na Piscina / Bar Piscina</S.Text>
                              <S.Text>21h – Show Izzy Gordon / Bar Piscina</S.Text>
                              
                          </S.GroupText>
                          
                          <S.GroupText className="last">
                              <S.Week>Terça</S.Week>
                              <S.Text>21h – Violão e Voz / Suzano</S.Text>
                          </S.GroupText>  
                    </S.BoxRight>
                </S.GroupProg>
            </S.AreaProg>
        </S.Container>
    )    
}
export default modCarnaval;
