import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  background: #1d4550;
`;
export const Reserva = styled.a`
  display: none;
  width: 90%;
  max-width: 150px;
  padding: 15px;
  color: #fff;
  font-size: medium;
  background: #ff7e01;
  border-radius: 10px;
  margin-bottom: 50px;
  @media only screen and (max-width: 1024px) {
    display: block;
    margin: -15px auto 30px auto;
  }
`;
export const Bar = styled.div`
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  margin: 140px auto 0 auto;
  width: 100%;
  max-width: 1100px;
  min-height: 50px;
  color:#fff
  padding: 10px;
  a.reserva {
  display: none;
  width: 90%;
  max-width: 150px;
  padding: 15px;
  color: #fff;
  font-size: medium;
  background: #ff7e01;
  border-radius: 10px;
  margin-bottom: 50px;
  cursor:pointer;
  @media only screen and (max-width: 1024px) {
    display: flex;
    align-items:center;
    justify-content:center;
    margin: 20px auto 30px auto;
  }
  }
  .link {
    cursor: pointer;
  }
  @media only screen and (max-width: 1080px) {
    margin: 40px auto 20px auto;
  }
  @media only screen and (max-width: 1024px) {
    margin: 40px auto 20px auto;
  }
  @media only screen and (max-width: 820px) {
    margin: 40px auto 20px auto;
  }
  @media only screen and (max-width: 540px) {
    margin: 50px auto 20px auto;
  }
`;
export const GroupText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
`;

export const TextNormal = styled.span`
  font-size: x-large;
  color: #fff;
  margin-right: 10px;
  @media only screen and (max-width: 350px) {
    font-size: large;
  }
`;

export const TextTour = styled.span`
  font-family: "LibreBaskervilleItalic";
  color: #fff;
  font-size: x-large;
  margin-right: 5px;
  @media only screen and (max-width: 350px) {
    font-size: large;
  }
`;

export const Bold360 = styled.span`
  font-family: "GilroyExtraBold";
  font-size: x-large;
  color: #fff;
  @media only screen and (max-width: 350px) {
    font-size: large;
  }
`;
