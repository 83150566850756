import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  position: relative;
  z-index: 999;
  background-color: ${({ absolute }) => (absolute ? "transparent" : "#f4f4f4")};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 48px;
  width: 100%;
  max-width: 1100px;
  height: 158px;
  margin: 0 auto;
  background-color: ${({ absolute }) => (absolute ? "#fff" : "#f4f4f4")};
  position: ${({ absolute }) => (absolute ? "absolute" : "relative")};
  z-index: 999;
  top: ${({ absolute }) => (absolute ? "-79px" : "auto")};
  a.reserva {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff7e01;
    color: #fff;
    padding: 15px;
  }

  @media only screen and (max-width: 990px) {
    position: relative;
    top: auto;
  }

  @media only screen and (max-width: 650px) {
    display: none;
  }
`;

export const Wrap = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h1`
  font-family: "GilroyBold";
  font-size: 28px;
  color: #000;
  width: 100%;
  max-width: 220px;
  padding: 0 24px;

  span {
    color: #ff7e01;
  }
`;

export const Text = styled.p`
  font-size: 20px;
  color: #292929;
  width: 100%;
  max-width: 448px;
  padding: 0 24px;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

export const ButtonReserva = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ff7e01;
  padding: 16px 24px;
  border-radius: 4px;
  font-family: "GilroyBold";
  font-size: 17px;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
  transition: 300ms ease;

  &:hover {
    transform: scale(0.95);
  }
`;
