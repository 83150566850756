import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #fff6f2;
  min-height: 200px;
  padding: 100px 0;
`;
export const GroupAll = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 1100px;
  background: #fff6f2;
  height: auto;
`;
export const GroupTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
export const OrangeTitle = styled.span`
  /* font-family: "BaskervilleRegular"; */
  font-family: "LibreBaskervilleItalic";
  font-size: 51px;
  letter-spacing: -1px;
  color: #fe7422;
  margin-right: 10px;
`;
export const SubTitle = styled.p`
  font-family: "BaskervilleRegular";
  font-size: 20px;
  color: #000;
  margin: 30px 0;
  text-align: center;
`;
export const BoldTitle = styled.span`
  /* font-family: "BaskervilleRegular"; */
  font-family: "GilroyExtraBold";
  font-size: 62px;
  letter-spacing: -2px;
  color: #000;
`;

export const Reserva = styled.a`
  display: none;
  width: 90%;
  max-width: 150px;
  padding: 15px;
  color: #fff;
  font-size: medium;
  background: #ff7e01;
  border-radius: 10px;
  margin-bottom: 50px;
  @media only screen and (max-width: 540px) {
    display: block;
  }
`;

export const GroupIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 90%;
  min-height: 200px;
  column-gap: 30px;
  row-gap: 30px;
  margin-top: 20px;

  @media only screen and (max-width: 1364px) {
    max-width: 720px;
  }
  @media only screen and (max-width: 1017px) {
    max-width: 480px;
  }
  @media only screen and (max-width: 670px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const Card = styled.a`
  display: flex;
  width: 220px;
  min-height: 220px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #c5c5c5;
  padding: 20px;
  transition: 0.5s;
  cursor: pointer;
  img {
    margin-bottom: 15px;
  }
  p {
    font-family: "GilroyBold";
    font-size: 18px;
    color: #000;
  }
  &:hover {
    background: #fff;
  }
`;
