import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 1100px;

  @media only screen and (max-width: 1250px) {
    width: 849px;
    margin-top: 48px;
    margin: auto;
    padding-top: 48px;
  }

  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const Vantagens = styled.div`
  padding-top: 160px;
  padding-bottom: 93px;
  background-color: #ffffff;

  @media only screen and (max-width: 1250px) {
    padding-top: 100px;
  }

  @media only screen and (max-width: 1025px) {
    padding-top: 0px;
  }

  @media only screen and (max-width: 600px) {
    padding-bottom: 20px;
  }
`;

export const Top = styled.div`
  text-align: center;
  h2 {
    color: #392c23;
    font-size: 62px;
    line-height: 55px;
    font-weight: 500;
  }

  p {
    color: #585858;
    font-size: 22px;
    margin-top: 15px;
  }

  span {
    font-size: 52px;
  }
`;

export const Conteudo = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 48px;

  .Vantagem {
    width: 250px;
    height: 301px;
    padding: 48px 31px;
    border: 1px solid #d1bebe;
    float: left;
    margin-bottom: 33px;
    margin-right: 33px;
  }

  .Vantagem:nth-child(4n) {
    margin-right: 0px;
  }

  img {
    margin-bottom: 24px;
  }

  h3 {
    margin-bottom: 14px;
    color: #392c23;
    font-size: 18px;
    font-family: "GilroyBold", sans-serif;
    min-height: 43px;
  }

  p {
    font-size: 18px;
    max-width: 177px;
  }

  @media only screen and (max-width: 1250px) {
    .Vantagem:nth-child(4n) {
      margin-right: 33px;
    }
  }
  @media only screen and (max-width: 910px) {
    width: 566px;
    margin-top: 48px;
    margin: auto;
    padding-top: 50px;
  }

  @media only screen and (max-width: 600px) {
    width: 253px;
    margin-top: 48px;
    margin: auto;
    padding-top: 50px;
    display: none;

    .Vantagem {
      margin-right: 0px;
    }
  }
`;

export const ConteudoMobile = styled.div`
  display: none;
  width: 100%;
  margin: auto;
  .Vantagem {
    width: 250px;
    height: 301px;
    padding: 48px 31px;
    border: 1px solid #d1bebe;
    float: left;
    margin-bottom: 33px;
    margin-right: 33px;
  }

  img {
    margin-bottom: 24px;
  }

  h3 {
    margin-bottom: 14px;
    color: #392c23;
    font-size: 18px;
    font-family: "GilroyBold", sans-serif;
    min-height: 43px;
  }

  p {
    font-size: 18px;
    max-width: 177px;
  }

  @media only screen and (max-width: 600px) {
    width: 253px;
    margin-top: 48px;
    margin: auto;
    padding-top: 50px;
    display: block;

    .Vantagem {
      margin-right: 0px;
    }
  }
`;
