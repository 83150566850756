import React, { useState } from "react";
import * as S from "./styles";
import Banner01 from "../../assets/banner_top/illustration.jpg";
import videoUrl from "../../assets/videos/video-resort.mp4";
//import videoUrl from "../../assets/videos/video-ano-novo.mp4";
import Ornament from "../../assets/banner_top/ornament.png";
import Sheet from "../../assets/banner_top/folha.png";
import ReactPlayer from "react-player";

const BannerTop = () => {
  const [control, setControl] = useState(true);
  function handleClick() {
    control === true ? setControl(false) : setControl(true);
  }
  return (
    <S.GroupBanner>
      <S.AreaBanner>
        <S.VideoResort>
          <ReactPlayer
            url={videoUrl}
            width="100%"
            height="auto"
            loop={true}
            playing={true}
            muted={control}
            controls={false}
          />
          {control === true ? (
            <S.SoundOff onClick={() => handleClick()} size={30} />
          ) : (
            <S.SoundOn onClick={() => handleClick()} size={30} />
          )}
        </S.VideoResort>
        {/* <img src={Banner01} className="responsive" alt="Box" /> */}
        {/* <S.AreaText>
          <S.BigText>O hotel fazenda</S.BigText>
          <S.SmallText>consegue, harmoniosamente,</S.SmallText>
          <S.SmallText>unir o conforto, o requinte e o rústico.</S.SmallText>
          <img src={Ornament} alt="ornamento" className="ornament" />
          <img src={Sheet} alt="Folha" className="sheet" />
        </S.AreaText> */}
        {/* <S.AreaBannerMobile>
          <img src={Sheet} alt="Folha" className="sheet" />
          <S.BigText>O hotel fazenda</S.BigText>
          <S.SmallText>
            consegue, harmoniosamente,unir o conforto, o requinte e o rústico.
          </S.SmallText>
        </S.AreaBannerMobile> */}
      </S.AreaBanner>
    </S.GroupBanner>
  );
};
export default BannerTop;
