import Styled from "styled-components";

export const Sobrenos = Styled.div`
    display: inline-block;
    width: 100%;
    min-height: 460px;
    background-color: #262C40;
    margin-bottom: -4px;

} 
`;

export const Imagem = Styled.div`
  display: inline-block;
  width: 50%;
  float: left;
  img {
    width: 100%;
    cursor: pointer;
    height: 100%;


  }
  @media only screen and (max-width: 900px) {  
    width: 100%;

  }


`;

export const Textos = Styled.div`
  display: inline-block;
  width: 50%;
  float: left;
  /* padding-top: 89px; */
  padding-left: 98px;
  /* background-color: #262C40; */
  min-height: 460px;
  position: relative;
  text-align: center;

  div {
    position: absolute;
    width: 80%;
    /* height: 100%; */
    top: 50%;
    transform: translate(0, -50%);
  }

  @media only screen and (max-width: 650px) {
    min-height: 300px;
    div {
      width: 100%;
      left: 0;
    }
    p {
      margin: 0 auto;
    }
  }


  h1{
    font-size: 77px;
    color: #fff;
    font-weight: 500;
    font-family: 'BaskervilleItalic', sans-serif;
    line-height: 62px;
  }

  span{
    display: block;
    font-family: 'GilroyBold', sans-serif;
    font-size: 63px;
  }

  h2 {
    font-family: 'GilroyBold',sans-serif;
    font-size: 18px;
    color: #FF7E01;   
    text-transform: uppercase;
    margin-top: 23px;
  }

  p{
    font-size: 22px;
    line-height: 25px;
    color: #fff;
    margin: 17px auto 0 auto;
    max-width: 432px;
  }   


  button{
    background-color: #262C40;
    padding:  15px 27px;
    color: #fff;
    font-size: 18px;
    border: none;
    font-family: 'GilroyBold', sans-serif;
    max-height: 50px;
    max-width: 140px;
    margin-top: 50px;
    cursor: pointer;

  }

  @media only screen and (max-width: 1200px) {    
    padding-top: 40px;
    padding-left: 61px;
    h1 {
    font-size: 57px;
    line-height: 50px;

    }
    span{
      font-size: 43px;
    }  


  }

  @media only screen and (max-width: 900px) {  
    width: 100%;
    padding-bottom: 50px;

  }
  @media only screen and (max-width: 650px) {  
    padding: 30px;

    h1 {
      font-size: 47px;
      line-height: 42px;
    }

    h2 {
      font-size: 16px;
      margin-top: 14px;
    }

    span{
      font-size: 33px;
    }  

    p {
      font-size: 20px;
      margin-top: 16px;
    }
  }

  @media only screen and (max-width: 426px) {  

    h1 {
      font-size: 37px;
      line-height: 42px;
    }

    h2 {
      font-size: 14px;
      margin-top: 8px;
    }

    span{
      font-size: 28px;
    }  

    p {
      font-size: 18px;
      margin-top: 16px;
    }

  }
    


    
`;
