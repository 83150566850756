import React, { useEffect } from "react";
import WOW from "wowjs";
import { Helmet } from "react-helmet";

import TopInterna from "../../components/TopoInterna";
import Informacoes from "../../components/Informacoes";
import ComoChegar from "../../components/ComoChegar";
import Menu from "../../components/Menu";
import Reserva from "../../components/Reserva";
import NewReserva from "../../components/NewReserva";
import ReservaMobile from "../../components/Reserva/mobile";

export default function PaginaFuncionamento() {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <>
      <Helmet>
        <title>Local - Resort Fazenda 3 Pinheiros</title>
      </Helmet>
      <Menu />
      <NewReserva />
      {/* <Reserva /> */}
      <TopInterna
        titulo="Saiba"
        titulo2="Como Chegar"
        subtitulo="Local"
        bgColor="#fff"
        fontColor="#262C40"
        texto="Nosso Hotel está localizado em lugar privilegiado, em Engenheiro Passos, distrito de Resende/RJ e facilmente acessível para viajantes dos estados do Rio de Janeiro, São Paulo e Minas Gerais."
      />
      <ReservaMobile bgColor="#fff" />
      <ComoChegar
        InformacoesWidth="100%"
        mapaWidth="100%"
        chmaxHeight="initial"
      />
      <Informacoes />
    </>
  );
}
