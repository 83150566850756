import React from "react";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";

import Whatsapp from "./components/Whatsapp";
import history from "./history";
import Routes from "./routes";
import GlobalStyle from "./styles/global";

// ReactGA.initialize("UA-21140795-46");
// ReactGA.pageview(window.location.pathname);

export default function App() {
  const tagManagerArgs = {
    gtmId: "GTM-55K3RHT",
  };
  TagManager.initialize(tagManagerArgs);

  return (
    <BrowserRouter basename="/">
      {/* <Whatsapp /> */}
      <GlobalStyle />
      <Routes history={history} />
    </BrowserRouter>
  );
}
