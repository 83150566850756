import React, { useState } from "react";
import Swiper from "react-id-swiper";
import "react-id-swiper/lib/styles/css/swiper.css";
import { Diversao, Imagens, Textos, ButtonPrev, ButtonNext } from "./styles";

import "./app.css";
import ImagemDiversao00 from "../../assets/lazer/diversao/diversao00.jpg";
import ImagemDiversao01 from "../../assets/lazer/diversao/diversao01.jpg";
import ImagemDiversao02 from "../../assets/lazer/diversao/diversao02.jpg";
import ImagemDiversao03 from "../../assets/lazer/diversao/diversao03.jpg";
import ImagemDiversao04 from "../../assets/lazer/diversao/diversao04.jpg";
import ImagemDiversao05 from "../../assets/lazer/diversao/diversao05.jpg";
import ImagemDiversao06 from "../../assets/lazer/diversao/diversao06.jpg";
import ImagemDiversao07 from "../../assets/lazer/diversao/diversao07.jpg";

function CompontentDiversao() {
  const [swiper, updateSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const params = {
    Swiper,
    initialSlide: 0,
    pagination: {
      el: ".swiper-pagination",
    },
    spaceBetween: 30,

    getSwiper: updateSwiper,
  };

  return (
    <Diversao className="diversao">
      <Imagens className="wow fadeIn" data-wow-delay="350ms">
        <Swiper {...params}>
          <img src={ImagemDiversao00} alt="Diversão" />
          <img src={ImagemDiversao01} alt="Diversão" />
          <img src={ImagemDiversao02} alt="Diversão" />
          <img src={ImagemDiversao03} alt="Diversão" />
          {/* <img src={ImagemDiversao04} alt="Diversão" /> */}
          {/* <img src={ImagemDiversao05} alt="Diversão" /> */}
          <img src={ImagemDiversao06} alt="Diversão" />
          <img src={ImagemDiversao07} alt="Diversão" />
        </Swiper>
        <ButtonPrev onClick={goPrev} />
        <ButtonNext onClick={goNext} />
      </Imagens>
      <Textos className="wow fadeIn" data-wow-delay="550ms">
        <h2>
          Diversão <span>infantil</span>
        </h2>
        <p>
          Muito espaço e brincadeiras dedicados aos pequenos!! No Resort
          contamos com super recreadores, que têm uma programação diária para as
          crianças maiores de 5 anos.
        </p>
        <p>
          Aqui ninguém fica parado! Parquinho, galpão para brincadeiras, sala de
          tv, totó, futebol de botão, videokê e muito mais! Além das
          brincadeiras mais radicais como arvorismo, caiaque, pedalinho, entre
          outros.
        </p>
        <p>
          Para ficarmos mais em contato direto com a natureza as atividades
          oferecidas são: tirar leite da vaca, conhecer a fazendinha com
          bichinhos e bichões, ponte “balança mais não cai”, mini tirolesa com
          direito a banho de lama, andar à cavalo, passear de charrete, pônei,
          balaio na mula, carro de boi, mini carro de boi puxado por 4 ovelhas e
          charretinha de bode.
        </p>
      </Textos>
    </Diversao>
  );
}

export default CompontentDiversao;
