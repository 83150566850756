import styled from "styled-components";

export const Modal = styled.div`
  display: ${props => (props.open ? "flex" : "none")};
  align-items: flex-start;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 50;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
`;

export const BtnFecharModal = styled.a`
  position: fixed;
  left: 63%;
  top: 15px;
  transform: translateX(-63%);
  max-width: 30px;

  z-index: 5000;
  cursor: pointer;
  background: transparent;
  img {
    width: 70%;
    max-width: 80px;
    height: auto;
  }
  @media screen and (max-width: 540px) {
    left: 53%;
    top: 15px;
    transform: translateX(-53%);
  }
`;

export const Group = styled.div`
  position:relative
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top:50px;
  position: relative;
  width: 90%;
  max-width:600px;
  min-height: 200px;
  max-height:90%;
  padding: 0px 50px 20px 50px;
  background:#434343;
  border: 1px solid #7f7f7f;
  overflow-y:scroll;
  img.logopet {
    width:90%;
    max-width:150px;
    height:auto;
    margin: 80px 0 20px 0;
  }
  ul li {
    font-family:"GilroyRegular";
    font-size:18px;
    color:#fff;
    line-height:1.6em;
    margin-bottom: 15px;
  }

  ::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    background: #000;
}

::-webkit-scrollbar-track {
    background: #434343;
  }

::-webkit-scrollbar-thumb {
    background: #555;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px rgba(67, 67, 67, 0.75);
}

::-webkit-scrollbar-corner {
    background: #000;
}

  @media screen and (max-width: 540px) {
    margin-top:50px;
    padding: 0px 20px 200px 20px;
    max-height:80%;
  }
 
`;

export const TitleItalic = styled.h2`
  font-family: "LibreBaskervilleItalic";
  font-size: 35px;
  color: #fff;
  text-align: center;
  line-height: 1.4em;
  margin-bottom: 15px;
  letter-spacing: -1px;
`;
export const TextItalic = styled.p`
  font-family: "LibreBaskervilleItalic";
  font-size: 18px;
  color: #fff;
  text-align: center;
  line-height: 1.4em;
  margin-bottom: 50px;
`;
