import React, { useState } from "react";
import Swiper from "react-id-swiper";
import "react-id-swiper/lib/styles/css/swiper.css";
import { Festa, Imagens, Textos, ButtonPrev, ButtonNext } from "./styles";

import "./app.css";

function CompontentSlide({
  Titulo,
  Titulo2,
  Descricao,
  ImagensFesta,
  ImgFloat,
  pLink,
}) {
  const [swiper, updateSwiper] = useState(null);

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const params = {
    Swiper,
    initialSlide: 0,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },

    spaceBetween: 30,
    // loop: true,
    // autoplay: true,
    getSwiper: updateSwiper, // Get swiper instance callback
  };

  const RenderListaImagens = ImagensFesta.map(todo => (
    <img key={todo.id} src={todo.imagem} />
  ));

  return (
    <Festa>
      <Imagens ImgFloat={ImgFloat}>
        <Swiper {...params}>{RenderListaImagens}</Swiper>
        <ButtonPrev onClick={goPrev} />
        <ButtonNext onClick={goNext} />
      </Imagens>
      <Textos>
        <h2>
          {Titulo}
          <span>{Titulo2}</span>
        </h2>

        <p>{Descricao}</p>

        <a href={pLink}>
          <button>Mais Fotos</button>
        </a>
      </Textos>
    </Festa>
  );
}

export default CompontentSlide;
