import styled from "styled-components";
import nhBar from "../../assets/nossa_historia/nh-bar.png";
import nhFolha1 from "../../assets/nossa_historia/nh-folha-1.png";
import nhFolha2 from "../../assets/nossa_historia/nh-folha-2.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 1100px;
  position: relative;

  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const NossaHistoria = styled.div`
  background-color: #0fa592;
  display: inline-block;
  padding-top: 62px;
  padding-bottom: 77px;
  width: 100%;

  @media only screen and (max-width: 426px) {
    padding-top: 32px;
    padding-bottom: 57px;
  }
`;

export const Top = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;

  @media only screen and (max-width: 426px) {
    margin-top: 15px;
    margin-bottom: 20px;
  }
`;

export const Titulo = styled.h2`
  text-align: center;
  color: #fff;
  font-size: 74px;
  line-height: 60px;
  font-weight: 500;
  margin-top: 25px;

  span {
    text-align: center;
    color: #e6cd9d;
    font-size: 62px;
    display: block;
  }

  @media only screen and (max-width: 1100px) {
    font-size: 64px;
    line-height: 55px;

    span {
      font-size: 52px;
    }
  }
  @media only screen and (max-width: 900px) {
    font-size: 54px;
    line-height: 50px;

    span {
      font-size: 41px;
    }
  }

  @media only screen and (max-width: 650px) {
    font-size: 40px;
    line-height: 40px;

    span {
      font-size: 31px;
    }
  }
`;

export const Texto = styled.p`
  text-align: center;
  font-size: 22px;
  color: #fff;
  line-height: 25px;
  margin-bottom: 15px;

  @media only screen and (max-width: 900px) {
    width: 95%;
    margin: auto;
    margin-bottom: 23px;
  }

  @media only screen and (max-width: 768px) {
    width: 60%;
  }

  @media only screen and (min-width: 768px) {
    .hidden-desktop {
      display: none;
    }
  }

  @media only screen and (max-width: 767.97px) {
    .hidden-mobile {
      display: none;
    }

    .hidden-desktop p {
      margin-bottom: 20px;
    }
  }

  @media only screen and (max-width: 650px) {
    width: 85%;
    font-size: 20px;
  }

  @media only screen and (max-width: 376px) {
    width: 80%;
    font-size: 19px;
  }

  @media only screen and (max-width: 321px) {
    /* width: 96%; */
    font-size: 18px;
  }
`;

export const IconenhBar = styled.span`
  background-image: url(${nhBar});
  background-repeat: no-repeat;
  width: 513px;
  height: 38px;
  margin: auto;
  background-size: 513px 38px;

  @media only screen and (max-width: 900px) {
    width: 60%;
    height: 30px;
    background-size: 100% 30px;
  }

  @media only screen and (max-width: 426px) {
    width: 80%;
  }
`;

export const IconenhFolha1 = styled.span`
  background-image: url(${nhFolha1});
  background-repeat: no-repeat;
  width: 106px;
  height: 130px;
  margin: auto;
  position: absolute;
  z-index: 1;
  background-size: 106px 130px;
  display: block;

  @media only screen and (max-width: 651px) {
    width: 86px;
    height: 100px;
    background-size: 86px 100px;
  }
`;
export const IconenhFolha2 = styled.span`
  background-image: url(${nhFolha2});
  width: 84px;
  height: 101px;
  margin: auto;
  position: absolute;
  z-index: 1;
  background-size: 84px 101px;
  display: block;
  right: 0;
  bottom: 0px;

  @media only screen and (max-width: 426px) {
    bottom: -107px;
  }
`;
