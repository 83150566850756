import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import ComponentModal from "../ModalCaptureEmail";
import {
  Informacoes,
  Container,
  Reserva,
  Button,
  Curriculo,
  Desenvolvido,
  GroupParther,
} from "./styles";

function CompontentInformacoes() {
  const [modalEmail, setModalEmail] = useState(false);
  const OpenModal = useCallback(() => {
    setModalEmail(true);
  }, []);
  return (
    <Informacoes>
      <ComponentModal
        modalController={modalEmail}
        onClose={() => setModalEmail(false)}
      />
      <Container>
        <h2>Informações</h2>
        {/* <h3>Em caso de dúvidas ligue: (24) 2108-1000</h3> */}
        <Reserva>
          <p>
            Dúvidas? Envie um e-mail para:{" "}
            <strong>reserva01@3pinheiros.com.br</strong>.
          </p>
          <p>
            Mais informações <strong>(24) 2108-1000</strong>.
          </p>
        </Reserva>
        <Button>
          <a href="javascript:void(0)" onClick={OpenModal}>
            <button type="button">Reserva online</button>
          </a>
        </Button>

        <Curriculo>
          Quer fazer parte da nossa equipe?{" "}
          <a href="http://3pinheiros.com.br/trabalhe-conosco">
            &nbsp;Envie seu currículo
          </a>
        </Curriculo>
        <GroupParther>
          <span>Parcerias e imprensa:</span>
          <a href="mailto:saulo@lumocomunica.com.br">
            saulo@lumocomunica.com.br
          </a>
        </GroupParther>
      </Container>
      <Desenvolvido>
        Desenvolvido por
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.fococomunicacao.com/">
          &nbsp;Foco Comunicação
        </a>
      </Desenvolvido>
    </Informacoes>
  );
}

export default CompontentInformacoes;
