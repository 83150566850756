import React, { useState } from "react";
import Swiper from "react-id-swiper";

import Deluxe01 from "../../assets/hospedagem/quartos/Deluxe/01.jpg";
import Deluxe02 from "../../assets/hospedagem/quartos/Deluxe/02.jpg";
import Deluxe03 from "../../assets/hospedagem/quartos/Deluxe/03.jpg";
import Deluxe04 from "../../assets/hospedagem/quartos/Deluxe/04.jpg";
import Deluxe05 from "../../assets/hospedagem/quartos/Deluxe/05.jpg";
import Deluxe06 from "../../assets/hospedagem/quartos/Deluxe/06.jpg";
import Deluxe07 from "../../assets/hospedagem/quartos/Deluxe/07.jpg";
import Deluxe08 from "../../assets/hospedagem/quartos/Deluxe/08.jpg";
import Deluxe09 from "../../assets/hospedagem/quartos/Deluxe/09.jpg";
import Deluxe10 from "../../assets/hospedagem/quartos/Deluxe/10.jpg";
import Deluxe11 from "../../assets/hospedagem/quartos/Deluxe/11.jpg";
import Deluxe12 from "../../assets/hospedagem/quartos/Deluxe/12.jpg";
import Deluxe13 from "../../assets/hospedagem/quartos/Deluxe/13.jpg";
import Deluxe14 from "../../assets/hospedagem/quartos/Deluxe/14.jpg";
import Deluxe15 from "../../assets/hospedagem/quartos/Deluxe/15.jpg";
import Deluxe16 from "../../assets/hospedagem/quartos/Deluxe/16.jpg";
import Deluxe17 from "../../assets/hospedagem/quartos/Deluxe/17.jpg";
import Deluxe18 from "../../assets/hospedagem/quartos/Deluxe/18.jpg";
import Deluxe19 from "../../assets/hospedagem/quartos/Deluxe/19.jpg";
import Deluxe20 from "../../assets/hospedagem/quartos/Deluxe/20.jpg";
import Deluxe21 from "../../assets/hospedagem/quartos/Deluxe/21.jpg";
import Deluxe22 from "../../assets/hospedagem/quartos/Deluxe/22.jpg";
import Deluxe23 from "../../assets/hospedagem/quartos/Deluxe/23.jpg";
import Deluxe24 from "../../assets/hospedagem/quartos/Deluxe/24.jpg";

import DexulePNE1 from "../../assets/hospedagem/quartos/Deluxe_pne/01.jpg";
import DexulePNE2 from "../../assets/hospedagem/quartos/Deluxe_pne/02.jpg";
import DexulePNE3 from "../../assets/hospedagem/quartos/Deluxe_pne/03.jpg";
import DexulePNE4 from "../../assets/hospedagem/quartos/Deluxe_pne/04.jpg";

import MasterLuxo1 from "../../assets/hospedagem/quartos/Master_Luxo/01.jpg";
import MasterLuxo2 from "../../assets/hospedagem/quartos/Master_Luxo/02.jpg";
import MasterLuxo3 from "../../assets/hospedagem/quartos/Master_Luxo/03.jpg";
import MasterLuxo4 from "../../assets/hospedagem/quartos/Master_Luxo/04.jpg";
import MasterLuxo5 from "../../assets/hospedagem/quartos/Master_Luxo/05.jpg";

import Real1 from "../../assets/hospedagem/quartos/Real/01.jpg";
import Real2 from "../../assets/hospedagem/quartos/Real/02.jpg";
import Real3 from "../../assets/hospedagem/quartos/Real/03.jpg";
import Real4 from "../../assets/hospedagem/quartos/Real/04.jpg";
import Real5 from "../../assets/hospedagem/quartos/Real/05.jpg";
import Real6 from "../../assets/hospedagem/quartos/Real/06.jpg";
import Real7 from "../../assets/hospedagem/quartos/Real/07.jpg";
import Real8 from "../../assets/hospedagem/quartos/Real/08.jpg";
import Real9 from "../../assets/hospedagem/quartos/Real/09.jpg";
import Real10 from "../../assets/hospedagem/quartos/Real/10.jpg";
import Real11 from "../../assets/hospedagem/quartos/Real/11.jpg";
import Real12 from "../../assets/hospedagem/quartos/Real/12.jpg";
import Real13 from "../../assets/hospedagem/quartos/Real/13.jpg";
import Real14 from "../../assets/hospedagem/quartos/Real/14.jpg";

import MasterLuxoHidro1 from "../../assets/hospedagem/quartos/Master_Luxo_Hidro/01.jpg";
import MasterLuxoHidro2 from "../../assets/hospedagem/quartos/Master_Luxo_Hidro/02.jpg";
import MasterLuxoHidro3 from "../../assets/hospedagem/quartos/Master_Luxo_Hidro/03.jpg";
import MasterLuxoHidro4 from "../../assets/hospedagem/quartos/Master_Luxo_Hidro/04.jpg";

import LuxoLaraira1 from "../../assets/hospedagem/quartos/Luxo_Lareira/01.jpg";
import LuxoLaraira2 from "../../assets/hospedagem/quartos/Luxo_Lareira/02.jpg";
import LuxoLaraira3 from "../../assets/hospedagem/quartos/Luxo_Lareira/03.jpg";

import DeluxeHidro1 from "../../assets/hospedagem/quartos/Deluxe_Hidro/01.jpg";
import DeluxeHidro2 from "../../assets/hospedagem/quartos/Deluxe_Hidro/02.jpg";
import DeluxeHidro3 from "../../assets/hospedagem/quartos/Deluxe_Hidro/03.jpg";
import DeluxeHidro4 from "../../assets/hospedagem/quartos/Deluxe_Hidro/04.jpg";

import ImagemQuartoComPet from "../../assets/quartos/com_pet/1.jpg";
import ImagemQuartoHidro1 from "../../assets/quartos/luxo_hidro/1.jpg";
import ImagemLuxoLareira from "../../assets/quartos/luxo_lareira/1.jpg";
import ImagemMasterLuxo from "../../assets/quartos/master_luxo/1.jpg";
import ImagemReal from "../../assets/quartos/real/1.png";
import ImagemStandardSuperior from "../../assets/quartos/standard_superior/1.jpg";
import ImagemStandard from "../../assets/quartos/standard/1.jpg";

import Internet from "../InternetInfo";
// import "./swiper.css";

import {
  Box,
  ButtonNext,
  ButtonPrev,
  CloseButton,
  Container,
  Conteudo,
  Modal,
  Quartos,
  Textos,
} from "./styles";

import "react-id-swiper/lib/styles/css/swiper.css";

const datasImagens = [
  {
    id: 1,
    imagem: [DeluxeHidro1, DeluxeHidro2, DeluxeHidro3, DeluxeHidro4],
  },
  {
    id: 2,
    imagem: [
      Deluxe01,
      Deluxe02,
      Deluxe03,
      Deluxe04,
      // Deluxe05,
      Deluxe06,
      Deluxe07,
      Deluxe08,
      Deluxe09,
      Deluxe10,
      Deluxe11,
      Deluxe12,
      Deluxe13,
      Deluxe14,
      Deluxe15,
      Deluxe16,
      Deluxe17,
      Deluxe18,
      Deluxe19,
      Deluxe20,
      Deluxe21,
      Deluxe22,
      Deluxe23,
      Deluxe24,
    ],
  },
  {
    id: 3,
    imagem: [DexulePNE1, DexulePNE2, DexulePNE3, DexulePNE4],
  },
  {
    id: 4,
    imagem: [MasterLuxo1, MasterLuxo2, MasterLuxo3, MasterLuxo4, MasterLuxo5],
  },
  {
    id: 5,
    imagem: [
      MasterLuxoHidro1,
      MasterLuxoHidro2,
      MasterLuxoHidro3,
      MasterLuxoHidro4,
    ],
  },
  {
    id: 6,
    imagem: [LuxoLaraira1, LuxoLaraira2, LuxoLaraira3],
  },
  {
    id: 7,
    imagem: [
      Real1,
      Real2,
      Real3,
      Real4,
      Real5,
      Real6,
      Real7,
      Real8,
      Real9,
      Real10,
      Real11,
      Real12,
      Real13,
      Real14,
    ],
  },
];

function CompontentQuartos() {
  const [swiper, updateSwiper] = useState(null);

  const [open, setOpen] = useState(false);
  const [point, setPoint] = useState(0);

  function handlePoint(id) {
    setPoint(id);
    setOpen(true);
  }

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const params = {
    Swiper,
    initialSlide: 0,
    pagination: {
      el: ".swiper-pagination",
    },

    spaceBetween: 30,
    getSwiper: updateSwiper,
  };

  return (
    <>
      <Quartos>
        <Container>
          <Conteudo className="wow fadeIn" data-wow-delay="350ms">
            <div>
              <img src={ImagemStandardSuperior} />
              <Textos>
                <h2>Suíte</h2>
                <h3>Deluxe (Pet Friendly)</h3>
                <p>
                  Apartamentos sofisticados e com o máximo de conforto que você
                  merece. Todos possuem banheiro, tv, frigobar e
                  ar-condicionado.
                </p>
                <button onClick={() => handlePoint(2)}>Ver fotos</button>
              </Textos>
            </div>

            <div>
              <img src={ImagemQuartoComPet} />
              <Textos>
                <h2>Suíte</h2>
                <h3>Deluxe adaptada PNE (Pet Friendly)</h3>
                <p>
                  Apartamentos sofisticados adaptados para hospedes com animais
                  de estimação e com o máximo de conforto que você merece. Todos
                  possuem banheiro, tv, frigobar e ar-condicionado.
                </p>
                <button onClick={() => handlePoint(3)}>Ver fotos</button>
              </Textos>
            </div>

            <div>
              <img src={ImagemStandard} />
              <Textos>
                <h2>Suíte</h2>
                <h3>Deluxe Hidro</h3>
                <p>
                  Nossas suítes são equipadas com todo conforto que você e sua
                  família merecem. Todos os apartamentos possuem banheiro, tv,
                  frigobar e ar-condicionado.
                </p>
                <button onClick={() => handlePoint(1)}>Ver fotos</button>
              </Textos>
            </div>

            <div>
              <img src={ImagemLuxoLareira} />
              <Textos>
                <h2>Suíte</h2>
                <h3>Luxo Lareira (Pet Friendly)</h3>
                <p>
                  Apartamentos especiais com lareira. Além de banheiro, tv,
                  frigobar e ar-condicionado.
                </p>

                <button onClick={() => handlePoint(6)}>Ver fotos</button>
              </Textos>
            </div>

            <div>
              <img src={ImagemMasterLuxo} />
              <Textos>
                <h2>Suíte</h2>
                <h3>Master Luxo</h3>
                <p>
                  Nossas suítes são equipadas com todo conforto que você e sua
                  família merecem. Todas possuem TV Led, cama queen size e
                  banheiros. Tudo para fazer da sua estadia uma experiência
                  inesquecível.
                </p>
                <button onClick={() => handlePoint(4)}>Ver fotos</button>
              </Textos>
            </div>

            <div>
              <img src={ImagemQuartoHidro1} />
              <Textos>
                <h2>Suíte</h2>
                <h3>Master Luxo Hidro</h3>
                <p>
                  Apartamentos especiais com banheira de hidromassagem ou ofurô.
                  Além de banheiro, tv, frigobar e ar-condicionado..
                </p>

                <button onClick={() => handlePoint(5)}>Ver fotos</button>
              </Textos>
            </div>

            <div>
              <img src={ImagemReal} />
              <Textos>
                <h2>Suíte</h2>
                <h3>Real</h3>
                <p>
                  Apartamentos amplos, com cama king-size e sofisticados, com o
                  máximo de conforto que você merece. Possui banheiro,
                  poltronas, tv, frigobar e ar-condicionado.
                </p>
                <button onClick={() => handlePoint(7)}>Ver fotos</button>
              </Textos>
            </div>
          </Conteudo>
        </Container>
        <Modal show={open}>
          <Box>
            {datasImagens.map(
              p =>
                p.id === point && (
                  <>
                    <Swiper {...params}>
                      {p.imagem.map(i => (
                        <img src={i} />
                      ))}
                    </Swiper>
                    <ButtonPrev onClick={goPrev} />
                    <ButtonNext onClick={goNext} />
                  </>
                )
            )}

            <CloseButton onClick={() => setOpen(false)} />
          </Box>
        </Modal>
      </Quartos>
      {/* <Internet /> */}
    </>
  );
}

export default CompontentQuartos;
