import React, { useRef, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import "swiper/css/swiper.css";
import { Form } from "@unform/web";
import { Input } from "../Form";
import * as Yup from "yup";
import swal from "@sweetalert/with-react";
import api from "../../services/api";

import {
  Modal,
  BoxModal,
  BtnFecharModal,
  Title,
  GroupForm,
  FormFieldn,
  GroupSubmit,
  ButtonSubmit,
  Loader,
  GroupTerms,
  LegalTerms,
} from "./styles";
import closeButton from "../../assets/modal/closeButton.svg";

const ComponentModal = ({ modalController, onClose }) => {
  const formCapEmail = useRef();
  const [loading, setLoading] = useState(false);
  const [controlTerms, setControlTerms] = useState(false);
  function handleClose() {
    onClose();
  }

  useEffect(() => {
    const myLnk = document.querySelector("a#showTerms");
    if (myLnk) {
      myLnk.addEventListener("click", () => {
        setControlTerms(!controlTerms);
      });
    }
  }, [controlTerms]);

  const handlePost = async (data, { reset }) => {
    try {
      const checkTerms = document.querySelector("input#terms");
      if (!checkTerms.checked) {
        alert("Você precisa concordar com os termos de utilização de email");
        return false;
      }

      setLoading(true);

      formCapEmail.current.setErrors({});
      const schema = Yup.object().shape({
        email: Yup.string()
          .email()
          .required("Campo Obrigatório"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append("email", data.email);

      const resp = await api.post("contacts", formData, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      });

      if (resp.status === 200) {
        setLoading(false);
        window.location = "https://book.omnibees.com/hotel/6628";
        return;
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        return formCapEmail.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <>
      <Modal open={modalController}>
        <BoxModal>
          <BtnFecharModal onClick={handleClose}>
            <img src={closeButton} alt="Close" />
          </BtnFecharModal>

          <Title>Preencha o formulário abaixo para prosseguir</Title>

          <GroupForm>
            <Form ref={formCapEmail} onSubmit={handlePost}>
              <FormFieldn>
                <Input name="email" />
              </FormFieldn>
              <GroupSubmit>
                <ButtonSubmit>
                  {loading === false ? "Continue" : <Loader />}
                </ButtonSubmit>
              </GroupSubmit>
            </Form>
          </GroupForm>
          <GroupTerms>
            <input id="terms" type="checkbox" name="terms" value="" />
            <p>Declaro que li e aceito os</p>
            <a id="showTerms" href="javascript:void(0)">
              termos
            </a>
            <p>para solicitar uma reserva</p>
          </GroupTerms>
          <LegalTerms controlTerms={controlTerms}>
            <h3>AUTORIZAÇÃO PARA UTILIZAÇÃO DE E-MAIL NO CADASTRO</h3>
            <p>
              Autorizo expressamente o Resort Fazenda 3 Pinheiros a coletar e
              utilizar meu endereço de e-mail fornecido no momento do cadastro,
              para fins de comunicação referente aos produtos e serviços
              oferecidos por esta empresa. Declaro estar ciente de que o
              fornecimento do meu endereço de e-mail é opcional, e que posso
              revogar esta autorização a qualquer momento, mediante solicitação
              por escrito ao Resort Fazenda 3 Pinheiros. Declaro ter lido e
              compreendido esta autorização, estando de acordo com seus termos.
            </p>
          </LegalTerms>
        </BoxModal>
      </Modal>
    </>
  );
};

export default withRouter(ComponentModal);
