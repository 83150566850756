import React, { useState, useRef, useCallback } from "react";
import { Link } from "react-router-dom";

import {
  Container,
  Menu,
  Conteudo,
  Logo,
  RedesSociais,
  BtnFecharModal,
  BtnAbrirMenu,
  MenuMobile,
} from "./styles";

import ImagemLogo from "../../assets/icones/logo.png";
import ImagemInstagram from "../../assets/icones/instagram.png";
import ImagemFacebook from "../../assets/icones/facebook.png";
import ComponentModal from "../ModalCaptureEmail";
import ComponentModalPet from "../ModalPet";
import PetFriendly from "../../assets/pet-friendly.png";

function CompontentMenu({ menuPosition, props }) {
  const [open, setOpen] = useState(false);
  const [modalEmail, setModalEmail] = useState(false);

  const [modalPet, setModalPet] = useState(false);

  const submenu = useRef();

  function handleClick() {
    setOpen(!open);
  }

  function handleClickClose() {
    setOpen(false);
  }

  const OpenModal = useCallback(() => {
    setModalEmail(true);
  }, []);
  const OpenModalPet = useCallback(() => {
    setModalPet(true);
  }, []);

  const isLocalhost =
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1";

  let joker = null;
  isLocalhost
    ? (joker = "http://localhost:3000/")
    : (joker = "http://3pinheiros.com.br/");

  return (
    <Container>
      <ComponentModal
        modalController={modalEmail}
        onClose={() => setModalEmail(false)}
      />
      <ComponentModalPet
        modalController={modalPet}
        onClose={() => setModalPet(false)}
      />

      <Menu
        menuPosition={menuPosition}
        className="wow fadeInDown"
        data-wow-delay="250ms">
        <Logo>
          <a href="/" alt="Logo Resort Fazenda Três Pinheiros">
            <img src={ImagemLogo} alt="logo" />
          </a>
        </Logo>
        <Conteudo>
          <ul>
            <li>
              <a href={joker + "hotel"}>O Resort</a>
            </li>
            <li>
              <a href={joker + "acomodacoes"}>Acomodações</a>
            </li>
            {/* <li>
              <a href="https://pacotes3pinheiros.com.br/" target="_blank">
                Pacotes
              </a>
            </li> */}
            <li>
              <a href={joker + "gastronomia"}>Gastronomia</a>
            </li>
            <li>
              <a href={joker + "spa"}>SPA</a>
            </li>
            <li>
              <a href={joker + "lazer"}>Lazer</a>
            </li>
            <li>
              <a href={joker + "eventos"}>Eventos</a>
            </li>
            <li>
              <a href={joker + "fotos"}>Fotos</a>
            </li>
            <li>
              <a href={joker + "local"}>Local</a>
            </li>
            <li className="group">
              {/* <a
                className="callToAction"
                href="javascript:void(0)"
                onClick={OpenModal}
                className="reserva">
                Reserve já!
              </a> */}
              <Link
                to="/aviso-reserva"
                className="callToAction"
                className="reserva">
                Reserve já!
              </Link>
              <a
                onClick={OpenModalPet}
                className="pet"
                href="javascript:void(0)">
                <img src={PetFriendly} alt="Pet Friendly logo" />
              </a>
            </li>
          </ul>
          <RedesSociais>
            <a
              href="https://www.facebook.com/Resort3pinheiros/"
              target="_blank"
              rel="noopener noreferrer">
              <img src={ImagemFacebook} alt="facebook" />
            </a>
            <a
              href="https://www.instagram.com/3pinheirosoficial/"
              target="_blank"
              rel="noopener noreferrer">
              <img src={ImagemInstagram} alt="Instagram" />
            </a>
          </RedesSociais>
        </Conteudo>

        <BtnAbrirMenu onClick={() => handleClick()} />

        <MenuMobile ref={submenu} open={open}>
          <BtnFecharModal onClick={() => handleClickClose()}>X</BtnFecharModal>

          <ul>
            <li>
              {/* <a onClick={OpenModal}>Faça sua Reserva</a> */}
              {/* <a href="http://3pinheiros.com.br/reserva/reserva.html">
                Faça sua Reserva3
              </a> */}
              <Link to="/aviso-reserva">Faça sua Reserva</Link>
            </li>

            <li>
              <a href="hotel">O Hotel</a>
            </li>
            <li>
              <a href="acomodacoes">Acomodações</a>
            </li>
            <li>
              <a href="gastronomia">Gastronomia</a>
            </li>
            <li>
              <a href="spa">SPA</a>
            </li>
            <li>
              <a href="lazer">Lazer</a>
            </li>
            <li>
              <a href="eventos">Eventos</a>
            </li>
            <li>
              <a href="fotos">Fotos</a>
            </li>
            <li>
              <a href="local">Local</a>
            </li>
            <li>
              <a href="javascript:void(0)" onClick={OpenModalPet}>
                Pet Friendly
              </a>
            </li>
          </ul>
          <RedesSociais>
            <a
              href="https://www.facebook.com/Resort3pinheiros/"
              target="_blank"
              rel="noopener noreferrer">
              <img src={ImagemFacebook} alt="social" />
            </a>
            <a
              href="https://www.instagram.com/3pinheirosoficial/"
              target="_blank"
              rel="noopener noreferrer">
              <img src={ImagemInstagram} alt="social" />
            </a>
          </RedesSociais>
        </MenuMobile>
      </Menu>
    </Container>
  );
}
export default CompontentMenu;
