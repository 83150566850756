import Styled from "styled-components";

import ImagemButtonNext from "../../assets/icones/seta_direita.png";
import ImagemButtonPrev from "../../assets/icones/seta_esquerda.png";

export const Festas = Styled.div`  
text-align: left; 
display: inline-block;
width:100%;
background-color: #FFF6F2;
`;

export const Festa = Styled.div`  
text-align: left; 
display: inline-block;
width:100%;
margin-bottom: -4px;
`;

export const Imagens = Styled.div`
  display: display: inline-block;
  width: 50%;
  position: relative;  
  ${props => `float: ${props.ImgFloat}`};
  
  img{
    width:100%;
    max-height: 487px;
    object-fit: cover;
  }

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
  
`;

export const Textos = Styled.div`
  
  display: display: inline-block;
  width: 50%;
  float: left;
  margin-bottom: 24px;
  padding: 73px 0px 0px 97px;
  h2 {   
      color: #262C40;
      font-size:56px;
      line-height: 49px;
      font-weight: 500;
  }  

  span {  
    font-size: 47px;
    display:block;
    font-family: 'GilroyBold', sans-serif;
  }   


  p {font-size: 20px; line-height: 25px;margin-top: 24px;max-width: 494px;}

  button {     
    background-color: #FF7E01;
    padding: 15px 27px;
    color: #fff;
    font-size: 18px;
    border: none;
    font-family: 'GilroyBold',sans-serif;
    max-height: 50px;
    max-width: 150px;
    margin-top: 50px;
    cursor: pointer;
  }

  @media only screen and (max-width: 1300px) {  
    padding: 43px 0px 0px 77px;
  }

  @media only screen and (max-width: 1200px) {  
    padding: 20px 0px 0px 40px;

    button {
      margin-top: 30px;
    }



    
      
  }



  @media only screen and (max-width: 1000px) {  

      width: 100%;
      margin-bottom: 60px;

      h2 {   
        font-size:46px;
        line-height: 42px;
      }  

      span {  
        font-size: 37px;  
      }

  }



  @media only screen and (max-width: 650px) {  
    padding: 20px;

    h2 {   
      font-size:38px;
      line-height: 37px;
    }  

    span {  
      font-size: 28px;  
    }

    button{
      padding: 11px 23px;
      font-size: 17px;
    }

  }
  
`;

export const ButtonPrev = Styled.button`
  background-image: url(${ImagemButtonPrev});  
  background-repeat: no-repeat;
    width: 38px;
    height: 38px;
    background-size: 38px 38px;
    position: absolute;
    z-index: 2;
    display: block;
    top: 50%;
    left: 55px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    @media only screen and (max-width: 426px) {  
      display: none;
    }
`;

export const ButtonNext = Styled.button`
  background-image: url(${ImagemButtonNext});
  background-repeat: no-repeat;
  width: 38px;
  height: 38px;
  background-size: 38px 38px;
  position: absolute;
  z-index: 2;  
  display: block;
  top: 50%;  
  right: 55px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  @media only screen and (max-width: 426px) {  
    display: none;
  }  


`;
