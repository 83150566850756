import React from "react";
import { Estrutura, Container } from "./styles";

import Titulo from "../TituloPadrao/index";
import Imagens from "./images";
import ImagensResponsivo from "./images_mobile";
import BtnVerMais from "./button";
import Producao from "./producao";

function CompontentEstrutura({ datasTopo, datasImagens, datasButton }) {
  return (
    <Estrutura>
      <Container>
        <Titulo
          titulo={datasTopo.titulo}
          titulo2={datasTopo.titulo2}
          descricao={datasTopo.descricao}
          colorTitutlo={datasTopo.colorTitutlo}
          backgroundColorTitulo={datasTopo.backgroundColorTitulo}
          fontSizeTitulo={datasTopo.fontSizeTitulo}
          fontSizeTitulo2={datasTopo.fontSizeTitulo2}
          colorDescricao={datasTopo.colorDescricao}
          fontSizeDescricao={datasTopo.fontSizeDescricao}
          paddingTopTP={datasTopo.paddingTopTP}
          displayDescricao={datasTopo.displayDescricao}
        />

        <Producao displayBGTop={datasTopo.displayBGTop} />
        <Imagens ListaImagens={datasImagens} />
        <ImagensResponsivo ListaImagens={datasImagens} />
        <BtnVerMais
          Url={datasButton}
          displayButton={datasTopo.displayButton}
          buttonText={datasTopo.buttonText}
          buttonBg={datasTopo.buttonBg}
          buttonTextColor={datasTopo.buttonTextColor}
        />
      </Container>
    </Estrutura>
  );
}

export default CompontentEstrutura;
