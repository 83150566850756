import React from "react";

import { Festas } from "./styles";

import Slide from "./slide";

import ImagemArraia01 from "../../assets/festas/arraia/fe01.jpg";
import ImagemArraia02 from "../../assets/festas/arraia/fe02.jpg";
import ImagemArraia03 from "../../assets/festas/arraia/fe03.jpg";
import ImagemArraia04 from "../../assets/festas/arraia/fe04.jpg";
import ImagemReveillon01 from "../../assets/festas/reveillon/fe01.jpg";
import ImagemReveillon02 from "../../assets/festas/reveillon/fe02.jpg";
import ImagemReveillon03 from "../../assets/festas/reveillon/fe03.jpg";
import ImagemReveillon04 from "../../assets/festas/reveillon/fe04.jpg";

import ImagemNatal01 from "../../assets/estrutura/natal/natal_01.jpg";
import ImagemNatal02 from "../../assets/estrutura/natal/natal_02.jpg";
import ImagemNatal03 from "../../assets/estrutura/natal/natal_03.jpg";
import ImagemNatal04 from "../../assets/estrutura/natal/natal_04.jpg";

const ImagemArraia = [
  { id: 1, imagem: ImagemArraia01 },
  { id: 2, imagem: ImagemArraia02 },
  { id: 3, imagem: ImagemArraia03 },
  { id: 4, imagem: ImagemArraia04 },
];

const ImagemReveillon = [
  { id: 1, imagem: ImagemReveillon01 },
  { id: 2, imagem: ImagemReveillon02 },
  { id: 3, imagem: ImagemReveillon03 },
  { id: 4, imagem: ImagemReveillon04 },
];

const ImagemNatal = [
  { id: 1, imagem: ImagemNatal01 },
  { id: 2, imagem: ImagemNatal02 },
  { id: 3, imagem: ImagemNatal03 },
  { id: 4, imagem: ImagemNatal04 },
];

function CompontentFestas() {
  return (
    <Festas className="wow zoomInUp" data-wow-delay="350ms">
      <Slide
        Titulo="Arraiá do"
        Titulo2="3 Pinheiros"
        Descricao="Um legítimo arraiá está a sua espera, com comidas típicas, decoração a caráter e uma quadrilha daquelas pra não deixar ninguém parado ao som de uma banda ao vivo. A festa é somente para hóspedes do Hotel."
        ImgFloat="left"
        ImagensFesta={ImagemArraia}
        pLink="fotos/arraia"
      />
      <Slide
        Titulo="Festa de"
        Titulo2="Réveillon"
        Descricao="Não falta animação na noite de Réveillon do 3 Pinheiros! Nosso hotel te aguarda com muita música, diversão e comidas especiais! Planeje já sua hospedagem para passar a virada do ano conosco e toda sua família!"
        ImgFloat="right"
        ImagensFesta={ImagemReveillon}
        pLink="fotos/revellion"
      />

      <Slide
        Titulo="Noite de"
        Titulo2="Natal"
        Descricao="Na noite de Natal, junte-se a nós para uma ceia verdadeiramente memorável. Delicie-se com um banquete preparado com carinho, onde pratos tradicionais se encontram com sabores locais, criando uma explosão de aromas e sabores."
        ImgFloat="left"
        ImagensFesta={ImagemNatal}
        pLink="fotos/natal"
      />
    </Festas>
  );
}

export default CompontentFestas;
