import styled from "styled-components";

import ImagemBtnAbrirMenu from "../../assets/icones/btn_sidebar.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 1100px;

  @media only screen and (max-width: 1100px) {
    width: 100%;
    height: 102px;
  }

  @media only screen and (max-width: 650px) {
    height: 72px;
  }
`;

export const Menu = styled.div`
  background-color: #fff;
  ${props => props.menuPosition && `position: ${props.menuPosition}`};
  z-index: 11;
  width: 100%;
  max-width: 1100px;
  max-height: 102px;

  @media only screen and (max-width: 1100px) {
    width: 100%;
    max-width: 100%;
    position: fixed;
  }
`;

export const Logo = styled.div`
  width: 206px;
  display: inline-block;
  float: left;
  img {
    width: 100%;
  }
  @media only screen and (max-width: 426px) {
    width: 150px;
    margin-bottom: -4px;
  }
`;

export const Conteudo = styled.div`
  width: 81%;
  display: flex;
  padding: 24px;

  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  ul li.group {
    position:relative
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    

  }
  ul li a.pet {
    position:absolute;
    bottom:-35px;
    left:10px;
  }

  ul li a {
    color: #000;
    font-size: 16px;
    cursor: pointer;
    display: block;

    &.reserva {
      color: #fff;
      background-color: #ff7e01;
      padding: 8px 16px;
      border-radius: 6px;
      transition: 300ms ease;
      &:hover {
        transform: scale(0.95);
      }
    }
  }

  @media only screen and (max-width: 1100px) {
    width: 35%;

    position: fixed;
    z-index: 2;
    height: 100%;
    background-color: #fff;
    right: 0px;
    display: none;

    li {
      width: 100%;
    }
  }

  @media only screen and (max-width: 650px) {
    width: 60%;
    padding: 30px 22px;
  }
`;
export const MenuMobile = styled.div`
  width: 35%;
  display: ${props => (props.open ? "inline-block" : "none")};
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 2;
  background-color: #fff;
  right: 0px;
  /* display: block; */
  padding: 20px;

  ul li {
    display: inline-block;
    float: left;
    width: 100%;
  }

  ul li a {
    color: #000;
    font-size: 20px;
    cursor: pointer;
    padding: 9px 15px;
    display: block;
  }

  /* display: none;
    @media only screen and (max-width: 1100px) {
        display: block;
    } 
  */

  @media only screen and (max-width: 1100px) {
    width: 40%;
  }
  @media only screen and (max-width: 769px) {
    width: 45%;
    ul li a {
      font-size: 19px;
    }
  }

  @media only screen and (max-width: 650px) {
    width: 60%;
    ul li a {
      font-size: 18px;
    }
  }
`;

export const RedesSociais = styled.div`
  width: 8%;
  display: inline-block;
  float: right;
  padding: 0px;

  a {
    cursor: pointer;
    padding: 9px 9px;
    display: inline-block;
  }

  @media only screen and (max-width: 1100px) {
    width: 100%;
    float: left;
    padding-top: 18px;
    padding-left: 7px;
  }
`;

export const BtnFecharModal = styled.a`
  font-size: 23px;
  color: #000;
  float: right;
  display: none;
  font-weight: bold;

  @media only screen and (max-width: 1100px) {
    display: block;
  }
`;

export const BtnAbrirMenu = styled.a`
  background-image: url(${ImagemBtnAbrirMenu});
  background-repeat: no-repeat;
  width: 31px;
  height: 31px;
  position: absolute;
  background-size: 31px 31px;
  right: 14px;
  top: 35px;
  cursor: pointer;
  display: none;

  @media only screen and (max-width: 1100px) {
    display: block;
    width: 32px;
    height: 32px;
    background-size: 32px 32px;
  }

  @media only screen and (max-width: 650px) {
    width: 28px;
    height: 28px;
    background-size: 28px 28px;
    right: 14px;
    top: 35px;
  }

  @media only screen and (max-width: 650px) {
    top: 25px;
    width: 24px;
    height: 24px;
    background-size: 24px 24px;
  }
`;
