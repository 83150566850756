import React, { useState, useEffect } from "react";
import {
  Estrutura,
  Container,
  MenuNav,
  BtnMaisFotos,
  InfoPhotos,
} from "./styles";
import Titulo from "../TituloPadrao/index";
import Imagens from "./images";
import ImagensResponsivo from "./images_mobile";
import BtnVerMais from "./button";
import Producao from "./producao";

function CompontentEstrutura({
  datasTopo,
  galleryId,
  datasButton,
  GetdatasImagensHotel,
  GetdatasImagensAcomodacoes,
  GetdatasImagensRestaurante,
  GetdatasImagensAreasDeLazer,
  GetdatasImagensFazenda,
  GetdatasImagensEventos,
  GetdatasImagensHistoria,
  GetdatasImagensSpa,
  GetdatasImagensRevellion,
  GetdatasImagensArraia,
  GetdatasImagensNatal,
}) {
  const [datasImagens, setdatasImagens] = useState(GetdatasImagensHotel);

  const [HotelStyles, setStylesHotel] = useState(true);
  const [AcomodacoesStyles, setStylesAcomodacoes] = useState(false);
  const [RestauranteStyles, setStylesRestaurante] = useState(false);
  const [AreasDelazerStyles, setStylesAreasDelazer] = useState(false);
  const [FazendaStyles, setStylesFazenda] = useState(false);
  const [EventosStyles, setStylesEventos] = useState(false);
  const [HistoriaStyles, setStylesHistoria] = useState(false);
  const [SpaStyles, setStylesSpa] = useState(false);
  const [revellionStyles, setStylesRevellion] = useState(false);
  const [arraiaStyles, setStylesArraia] = useState(false);
  const [natalStyles, setStylesNatal] = useState(false);

  const [galleryTitle, setGalleryTitle] = useState("O Hotel");
  const [gallerySubTitle, setGallerySubTitle] = useState(
    "Venha aproveitar momentos inesquecíveis com sua família, desfrutanto de todo conforto em meio a natureza."
  );

  function ClearStylesBtn() {
    setStylesAcomodacoes(false);
    setStylesHotel(false);
    setStylesRestaurante(false);
    setStylesAreasDelazer(false);
    setStylesFazenda(false);
    setStylesEventos(false);
    setStylesHistoria(false);
    setStylesSpa(false);
    setStylesRevellion(false);
    setStylesArraia(false);
    setStylesNatal(false);
  }

  function SetChange(GetDatasImages, setStyles) {
    ClearStylesBtn();
    setdatasImagens(GetDatasImages);
    setStyles(true);
  }

  useEffect(() => {
    if (galleryId && galleryId === "revellion") {
      ClearStylesBtn();
      SetChange(GetdatasImagensRevellion, setStylesRevellion);
      setGalleryTitle("Reveillon");
      setGallerySubTitle(
        "Nada melhor que começar o ano em grande estilo, nossa festa de reveillon irá restaurar suas energias para que começe o ano com o pé direito."
      );
    }
    if (galleryId && galleryId === "arraia") {
      ClearStylesBtn();
      SetChange(GetdatasImagensArraia, setStylesArraia);
      setGalleryTitle("Arraiá");
      setGallerySubTitle(
        "Junte-se a nós e venha aproveitar uma das festas brasileiras mais tradicionais, música, culinária e a tradicional quadrilha"
      );
    }
    if (galleryId && galleryId === "natal") {
      ClearStylesBtn();
      SetChange(GetdatasImagensNatal, setStylesNatal);
      setGalleryTitle("Natal");
      setGallerySubTitle(
        "Venha desfrutar dessa época especial, aproveite nossa ceia natalina com diversas opções para agradar o seu paladar."
      );
    }
  }, []);

  function BtnHotel() {
    SetChange(GetdatasImagensHotel, setStylesHotel);
    setGalleryTitle("O Hotel");
    setGallerySubTitle(
      "Venha aproveitar momentos inesquecíveis com sua família, desfrutanto de todo conforto em meio a natureza."
    );
  }
  function BtnAcomodacoes() {
    SetChange(GetdatasImagensAcomodacoes, setStylesAcomodacoes);
    setGalleryTitle("Acomodações");
    setGallerySubTitle(
      "Conheça nossas acomodações, diversas opções de hospedagem que certamente lhe agradará. "
    );
  }
  function BtnRestaurante() {
    SetChange(GetdatasImagensRestaurante, setStylesRestaurante);
    setGalleryTitle("Restaurante");
    setGallerySubTitle(
      "Aproveite nossa gastronomia, pratos variádos feitos com produtos orgânicos e frescos."
    );
  }
  function BtnAreasDelazer() {
    SetChange(GetdatasImagensAreasDeLazer, setStylesAreasDelazer);
    setGalleryTitle("Áreas de Lazer");
    setGallerySubTitle(
      "Desfrute das diversas opção de atividades ao ar livre, caminhadas, quadras para a prática de esportes e claro, nossas piscinas."
    );
  }
  function BtnFazenda() {
    SetChange(GetdatasImagensFazenda, setStylesFazenda);
    setGalleryTitle("Fazenda");
    setGallerySubTitle(
      "Nossa fazenda preza pela qualidade de vida de nossos animais, proporcionando a nossos hóspedes e principalmente às crianças uma experiência agradável e divertida."
    );
  }

  function BtnEventos() {
    SetChange(GetdatasImagensEventos, setStylesEventos);
  }
  function BtnHistoria() {
    SetChange(GetdatasImagensHistoria, setStylesHistoria);
    setGalleryTitle("História");
    setGallerySubTitle(
      "Conheça um pouco da nossa história e daquelas que fizeram o Resort 3 Pinheiros um lugar mais do que especial."
    );
  }
  function BtnSpa() {
    SetChange(GetdatasImagensSpa, setStylesSpa);
    setGalleryTitle("Área de Spa");
    setGallerySubTitle(
      "Nossa área de spa tem o que você precisa para esquecer o stress do dia a dia e curtiro som da natureza enquanto desfruta de momentos relaxantes."
    );
  }
  function BtnRevellion() {
    SetChange(GetdatasImagensRevellion, setStylesRevellion);
    setGalleryTitle("Reveillon");
    setGallerySubTitle(
      "Nada melhor que começar o ano em grande estilo, nossa festa de reveillon irá restaurar suas energias para que começe o ano com o pé direito."
    );
  }
  function BtnArraia() {
    SetChange(GetdatasImagensArraia, setStylesArraia);
    setGalleryTitle("Arraiá");
    setGallerySubTitle(
      "Junte-se a nós e venha aproveitar uma das festas brasileiras mais tradicionais, música, culinária e a tradicional quadrilha"
    );
  }
  function BtnNatal() {
    SetChange(GetdatasImagensNatal, setStylesNatal);
    setGalleryTitle("Natal");
    setGallerySubTitle(
      "Venha desfrutar dessa época especial, aproveite nossa ceia natalina com diversas opções para agradar o seu paladar."
    );
  }
  return (
    <Estrutura>
      <Container>
        <Titulo
          titulo={datasTopo.titulo}
          titulo2={datasTopo.titulo2}
          descricao={datasTopo.descricao}
          colorTitutlo={datasTopo.colorTitutlo}
          backgroundColorTitulo={datasTopo.backgroundColorTitulo}
          fontSizeTitulo={datasTopo.fontSizeTitulo}
          fontSizeTitulo2={datasTopo.fontSizeTitulo2}
          colorDescricao={datasTopo.colorDescricao}
          fontSizeDescricao={datasTopo.fontSizeDescricao}
          paddingTopTP={datasTopo.paddingTopTP}
          displayDescricao={datasTopo.displayDescricao}
        />

        <MenuNav>
          <ul>
            <li>
              <a
                style={{ color: HotelStyles ? "#FF7E01" : "" }}
                onClick={() => BtnHotel()}>
                O hotel
              </a>
            </li>
            <li>
              <a
                style={{ color: AcomodacoesStyles ? "#FF7E01" : "" }}
                onClick={() => BtnAcomodacoes()}>
                Acomodações
              </a>
            </li>
            <li>
              <a
                style={{ color: RestauranteStyles ? "#FF7E01" : "" }}
                onClick={() => BtnRestaurante()}>
                Restaurante
              </a>
            </li>
            <li>
              <a
                style={{ color: AreasDelazerStyles ? "#FF7E01" : "" }}
                onClick={() => BtnAreasDelazer()}>
                Áreas de Lazer
              </a>
            </li>
            <li>
              <a
                style={{ color: FazendaStyles ? "#FF7E01" : "" }}
                onClick={() => BtnFazenda()}>
                Fazenda
              </a>
            </li>
            {/* <li>
              <a
                style={{ color: EventosStyles ? "#FF7E01" : "" }}
                onClick={() => BtnEventos()}>
                Eventos
              </a>
            </li> */}
            <li>
              <a
                style={{ color: HistoriaStyles ? "#FF7E01" : "" }}
                onClick={() => BtnHistoria()}>
                História
              </a>
            </li>
            <li>
              <a
                style={{ color: SpaStyles ? "#FF7E01" : "" }}
                onClick={() => BtnSpa()}>
                Área de Spa
              </a>
            </li>
            <li>
              <a
                style={{ color: revellionStyles ? "#FF7E01" : "" }}
                onClick={() => BtnRevellion()}>
                Reveillon
              </a>
            </li>
            <li>
              <a
                style={{ color: arraiaStyles ? "#FF7E01" : "" }}
                onClick={() => BtnArraia()}>
                Arraiá
              </a>
            </li>
            <li>
              <a
                style={{ color: natalStyles ? "#FF7E01" : "" }}
                onClick={() => BtnNatal()}>
                Natal
              </a>
            </li>
          </ul>
        </MenuNav>
        <InfoPhotos>
          <h2>{galleryTitle}</h2>
          <p>{gallerySubTitle}</p>
        </InfoPhotos>
        <Producao displayBGTop={datasTopo.displayBGTop} />
        <Imagens ListaImagens={datasImagens} />
        <ImagensResponsivo ListaImagens={datasImagens} />
        <BtnMaisFotos displayButton={datasTopo.displayButtonFotos}>
          Teste de button
        </BtnMaisFotos>
        <BtnVerMais Url={datasButton} displayButton={datasTopo.displayButton} />
      </Container>
    </Estrutura>
  );
}

export default CompontentEstrutura;
