import React from "react";
import {
  Container,
  NossaHistoria,
  Top,
  Titulo,
  Texto,
  IconenhBar,
  IconenhFolha1,
  IconenhFolha2,
} from "./styles";

function CompontentNossaHistoria() {
  return (
    <NossaHistoria>
      <Container>
        <IconenhBar />
        <Top>
          <IconenhFolha1
            className="wow rotateInUpRight"
            data-wow-delay="450ms"
          />

          <Titulo className="wow fadeIn" data-wow-delay="350ms">
            Nossa<span>história</span>
          </Titulo>
        </Top>
        <Texto className="wow fadeInDown" data-wow-delay="550ms">
          <p className="hidden-mobile">
            Nossa sede transcende ao tempo do Império no Brasil. <br />
            Desde o Brasil colônia o hotel era um entreposto comercial entre São
            Paulo, Rio
            <br /> de Janeiro e Minas gerais. No Império o hotel era caminho e
            parada de D.
            <br /> Pedro I em suas visitas à Domitila de Castro Canto e Mello
            (Marquesa de
            <br /> Santos). E também foi parada e local de troca das tropas de
            burros, transporte
            <br /> da época, da Princesa Isabel em suas visitas as estâncias
            hidrominerais de Minas
            <br /> Gerais. <br /> <br />
            O Hotel Fazenda 3 Pinheiros é um dos mais antigos do País em sua
            categoria. <br />
            O conceito de Hotel Fazenda, no Brasil, nasceu aqui, em Engenheiro
            Passos. <br />O hotel resistiu heroicamente aos tempos e se mantém
            vigoroso e pronto a<br /> recebe-los de braços abertos.
          </p>
          <p className="hidden-desktop">
            <p>Nossa sede transcende ao tempo do Império no Brasil. Desde o Brasil colônia o hotel era um entreposto comercial 
            entre São Paulo, Rio de Janeiro e Minas gerais.</p>
            
            <p>No Império o hotel era caminho e parada de D. Pedro I em suas visitas à Domitila de Castro Canto e Mello 
            (Marquesa de Santos). E também foi parada e local de troca das tropas de
            burros, transporte da época, da Princesa Isabel em suas visitas as estâncias
            hidrominerais de Minas Gerais.</p>
            
            <p>O Hotel Fazenda 3 Pinheiros é um dos mais antigos do País em sua categoria. O conceito de Hotel Fazenda, no Brasil, 
            nasceu aqui, em Engenheiro Passos. O hotel resistiu heroicamente aos tempos e se mantém vigoroso e pronto a 
            recebe-los de braços abertos.</p>
          </p>
          <IconenhFolha2
            className="wow rotateInUpRight"
            data-wow-delay="450ms"
          />
        </Texto>
        <IconenhBar />
      </Container>
    </NossaHistoria>
  );
}

export default CompontentNossaHistoria;
