import styled from "styled-components";

export const BlocoDeFotos = styled.div`
  display: flex;
  width: 100%;
  float: left;

  div {
    width: 33.33%;
    display: inline-block;
  }
  img {
    width: 100%;
  }

  @media only screen and (max-width: 650px) {
    display: none;
  }
`;

export const BlocoDeFotosMobile = styled.div`
  display: none;
  width: 100%;
  img {
    width: 100%;
  }

  @media only screen and (max-width: 650px) {
    display: inline-block;
  }
`;
