import React, { useEffect } from "react";
import WOW from "wowjs";

import ImageEstrutura1 from "../../assets/estrutura/estrurura-home-01.jpg";
import ImageEstrutura2 from "../../assets/estrutura/estrurura-home-02.jpg";
import ImageEstrutura3 from "../../assets/estrutura/estrurura-home-03.jpg";
import ImageEstrutura4 from "../../assets/estrutura/estrurura-home-04.jpg";
import ImageEstrutura5 from "../../assets/estrutura/estrurura-home-05.jpg";
import ImageEstrutura6 from "../../assets/estrutura/estrurura-home-06.jpg";
import ImageEstrutura7 from "../../assets/estrutura/estrurura-home-07.jpg";
import ImageEstrutura8 from "../../assets/estrutura/estrurura-home-08.jpg";
import BannerTop from "../../components/BannerTop";
import BoxPacotes from "../../components/BoxPacotes";
import ComoChegar from "../../components/ComoChegar";
import Estrutura from "../../components/Estruturas";
import Historia from "../../components/Historia";
import Informacoes from "../../components/Informacoes";
import Casamentos from "../../components/Casamentos";
import Menu from "../../components/Menu";
import Vantagens from "../../components/Vantagens";
import Tour360 from "../../components/Tour360";
import FloatMenuBar from "../../components/FloatMenuBar";
import PopPup from "../../components/PopUp";

export default function Home() {
  const datasTopo = {
    titulo: "Nossa",
    titulo2: "estrutura",
    descricao:
      "Natureza exuberante e diversos ambientes de lazer, esporte e diversão para toda a família curtir e relaxar.",
    colorTitutlo: "#392C23",
    backgroundColorTitulo: "#fff",
    fontSizeTitulo: "62px",
    fontSizeTitulo2: "52px",
    colorDescricao: "#585858",
    fontSizeDescricao: "22px",
    paddingTopTP: "70px",
  };

  const datasTopoVantagem = {
    titulo: "Por que se hospedar",
    titulo2: "no 3 Pinheiros",
    descricao:
      "Confira as vantagens de se hospedar no Hotel Fazenda mais tradicional do estado.",
    colorTitutlo: "#392C23",
    backgroundColorTitulo: "#fff6f2",
    fontSizeTitulo: "62px",
    fontSizeTitulo2: "52px",
    colorDescricao: "#392C23",
    fontSizeDescricao: "22px",
    paddingTopTP: "70px",
  };

  const datasImagens = [
    { id: 1, imagem: ImageEstrutura1 },
    { id: 2, imagem: ImageEstrutura2 },
    { id: 3, imagem: ImageEstrutura3 },
    { id: 4, imagem: ImageEstrutura4 },
    { id: 5, imagem: ImageEstrutura5 },
    { id: 6, imagem: ImageEstrutura6 },
    { id: 7, imagem: ImageEstrutura7 },
    { id: 8, imagem: ImageEstrutura8 },
  ];

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <>
      <PopPup />
      <Menu menuPosition="absolute" />
      <FloatMenuBar />
      <BannerTop />
      <BoxPacotes />
      <Casamentos />
      {/* <Diretrizes /> */}

      <Historia />

      <Vantagens datasTopo={datasTopoVantagem} />
      <Tour360 />
      <Estrutura
        datasTopo={datasTopo}
        datasImagens={datasImagens}
        datasButton="/fotos"
      />

      <ComoChegar bgColorMobile="#FF7E01" />
      <Informacoes />
    </>
  );
}
