import React, { useState, useCallback } from "react";
import { FiArrowRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Container, Content, Wrap, Title, Text, ButtonReserva } from "./styles";
import iconReserva from "../../assets/icones/reserva.png";
import ComponentModal from "../ModalCaptureEmail";

export default function NewReserva({ absolute }) {
  const [modalEmail, setModalEmail] = useState(false);
  const OpenModal = useCallback(() => {
    setModalEmail(true);
  }, []);
  return (
    <Container className="wow fadeIn" data-wow-delay="350ms">
      <ComponentModal
        modalController={modalEmail}
        onClose={() => setModalEmail(false)}
      />
      <Content absolute={absolute}>
        <Wrap>
          <img src={iconReserva} alt="Reserva" />
          <Title>
            Faça já <br />
            sua <span>Reserva</span>
          </Title>
        </Wrap>
        <Text>
          Diversos tipos de acomodações para hospedar você e toda sua família
          com todo o conforto e elegância que vocês merecem!
        </Text>
        <Link to="/aviso-reserva" className="reserva">
          Reserve já
          <FiArrowRight size={20} />
        </Link>
      </Content>
    </Container>
  );
}
