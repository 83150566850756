import React, { useRef, useState } from "react";
import { Form } from "@unform/web";
import swal from "@sweetalert/with-react";
import { Input, InputMask, Textarea, InputFile } from "../Form";
import * as S from "./styles";
import * as Yup from "yup";
import api from "../../services/api";
import loader from "../../assets/loading/ajax-loader.gif";

export default function Contact() {
  const formContact = useRef();
  const [loading, setLoading] = useState(false);
  const [attach, setAttach] = useState(undefined);
  const Message = ({ name }) => (
    <S.ModalContent>
      <S.ModalTitle>
        Obrigado <span>{name}!</span>
      </S.ModalTitle>
      <p>
        Obrigado por se interessar em fazer parte do nosso time de
        colaboradores. Vamos analisar o seu currículo e entrar em contato caso
        esteja no perfil que procuramos.
      </p>
    </S.ModalContent>
  );
  const handlePost = async (data, { reset }) => {
    const SUPPORTED_FORMATS = ["application/pdf"];
    // Site com a listagem de tipologias de MIME: https://developer.mozilla.org/pt-BR/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    try {
      setLoading(true);
      formContact.current.setErrors({});
      const schema = Yup.object().shape({
        file: Yup.mixed()
          .test(
            "fileSize",
            "O Tamanho Máximo do arquivo é de 10Mb",
            value => value === null || (value && value.size <= 10000000)
          )
          .test(
            "fileFormat",
            "Formato não suportado use apenas arquivos no formato PDF",
            value =>
              value === null ||
              (value && SUPPORTED_FORMATS.includes(value.type))
          )
          .required("Campo Obrigatório"),
        nome: Yup.string().required("Campo Obrigatório"),
        email: Yup.string()
          .email()
          .required("Campo Obrigatório"),
        celular: Yup.string().required("Campo Obrigatório"),
        mensagem: Yup.string().required("Campo Obrigatório"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      const formData = new FormData();
      formData.append("nome", data.nome);
      formData.append("email", data.email);
      formData.append("celular", data.celular);
      formData.append("mensagem", data.mensagem);
      formData.append("file", data.file);

      const resp = await api.post("sendmail", formData, {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      });

      if (resp.status === 200) {
        setLoading(false);
        swal({
          content: <Message name={data.nome} />,
          buttons: false,
        });
        return reset();
      }
      setLoading(false);
      return swal("Ops!", "Algo deu errado, tente novamente.", "error");
    } catch (err) {
      setLoading(false);
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        return formContact.current.setErrors(validationErrors);
      }
    }
  };

  return (
    <>
      <S.GroupItems id="contato">
        <S.GroupForm>
          <Form ref={formContact} onSubmit={handlePost}>
            <S.FormColumn>
              <InputFile
                name="file"
                label="Anexe seu Currículo (PDF até 10Mb)"
              />
            </S.FormColumn>
            <S.FormColumn>
              <Input
                name="nome"
                placeholder="Informe Nome Completo"
                label="Nome Completo:"
              />
            </S.FormColumn>
            <S.FormColumn>
              <Input
                name="email"
                placeholder="Digite seu E-mail"
                label="E-mail:"
              />
            </S.FormColumn>
            <S.FormColumn>
              <InputMask
                type="celular"
                placeholder="Digite seu Celular"
                name="celular"
                label="Celular:"
              />
            </S.FormColumn>
            <S.FormColumn>
              <Textarea
                name="mensagem"
                placeholder="Conte-nos um pouco sobre seu perfil profissional"
                label="Mensagem:"
              />
            </S.FormColumn>

            <S.SubmitButton>
              {loading ? (
                <img
                  src={loader}
                  width="16px"
                  height="auto"
                  alt="loading image"
                />
              ) : (
                "Enviar Currículo"
              )}
            </S.SubmitButton>
          </Form>
        </S.GroupForm>
      </S.GroupItems>
    </>
  );
}
