import React from "react";
import { Top, Descricao, Titulo } from "./styles";

function CompontentTituloPadrao({
  titulo,
  titulo2,
  descricao,
  colorTitutlo,
  backgroundColorTitulo,
  fontSizeTitulo,
  fontSizeTitulo2,
  colorDescricao,
  fontSizeDescricao,
  paddingTopTP,
  displayDescricao,
}) {
  return (
    <Top
      paddingTop={paddingTopTP}
      className="wow fadeIn"
      data-wow-delay="250ms">
      <Titulo
        fontSizeTitulo={fontSizeTitulo}
        colorTitutlo={colorTitutlo}
        backgroundColorTitulo={backgroundColorTitulo}
        fontSizeTitulo2={fontSizeTitulo2}>
        {titulo}
        <span>{titulo2}</span>
      </Titulo>
      <Descricao
        colorDescricao={colorDescricao}
        fontSizeDescricao={fontSizeDescricao}
        displayDescricao={displayDescricao}>
        {descricao}
      </Descricao>
    </Top>
  );
}

export default CompontentTituloPadrao;
