import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: 100%;
  background: #fff;
`;
export const FakeModal = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
`;

export const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 50px;
  width: 90%;
  max-width: 600px;
  height: auto;
  background: #fbefe6;
  height: auto;
  h1 {
    font-family: "AvenirDemi";
    color: #ffaa24;
    font-size: 40px;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.2em;
    margin-bottom: 20px;
    @media only screen and (max-width: 540px) {
      font-size: 30px;
      line-height: 1em;
      margin-bottom: 15px;
    }
  }
  p {
    font-family: "AvenirNext";
    color: #006a47;
    font-size: 25px;
    line-height: 1em;
    text-align: center;
    margin-bottom: 15px;
    &.destaque {
      font-size: 18px;
      font-style: Italic;
      margin: -10px 0px 0px 0;
      @media only screen and (max-width: 540px) {
        font-size: 16px;
      }
    }
    @media only screen and (max-width: 540px) {
      font-size: 20px;
    }
  }
  img.top-left {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 1;
  }
  img.top-right {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
  }
  img.bottom-left {
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: 1;
  }
  img.bottom-right {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 1;
  }
  img.logo {
    margin-top: 30px;
  }
`;

export const BtnCount = styled.button`
  width: 100%;
  max-width: 200px;
  padding: 10px;
  font-family: "AvenirNext";
  font-size: 20px;
  background: #006a47;
  color: #fbefe6;
  margin: 50px auto;
  border: none;
  cursor: pointer;
`;
