import styled from "styled-components";

export const Main = styled.section`
  width: 100%;
  height: auto;
  background: #fff6f2;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1920px;
  padding: 80px 50px;

  margin: 0 auto;
  @media only screen and (max-width: 540px) {
    padding: 50px 15px;
  }
`;

export const Title = styled.h1`
  font-family: "GilroyBold", sans-serif;
  font-weight: bold;
  color: #000;
  font-size: 45px;
  text-align: center;
  max-width: 900px;
  margin: 0 auto 15px auto;
  @media only screen and (max-width: 540px) {
    font-size: 30px;
  }
`;

export const SubTitle = styled.h2`
  font-family: "BaskervilleItalic";
  font-style: italic;
  font-weight: normal;
  color: #000;
  font-size: 35px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 35px auto;
  @media only screen and (max-width: 540px) {
    font-size: 25px;
  }
`;

export const Text = styled.p`
  font-family: "GilroyRegular";
  font-size: 20px;
  margin-bottom: 15px;
  width: 90%
  max-width: 900px;
  margin: 0 auto 20px auto;
  line-height: 1.5em;
  span {
    display:block;
    font-family: "GilroyBold", sans-serif;
  }
  @media only screen and (max-width: 540px) {
    text-align:center;
  }
`;

export const LinkEmail = styled.a`
  text-decoration: none;
  font-family: "GilroyRegular";
  cursor: pointer;
  color: blue;
`;

export const GroupImages = styled.div`
  width: 80%;
  max-width: 900px;
  margin: 30px auto 30px auto;
  &.first {
    margin-top: 80px;
  }
  img {
    width: 100%;
    height: auto;
  }
  @media only screen and (max-width: 540px) {
    width: 100%;
    margin: 0 auto;
  }
`;
