import React from "react";
import { Hospedagem, Container, Conteudo, Firula } from "./styles";

import Titulo from "../TituloPadrao/index";

function CompontentHospedagem() {
  return (
    <Hospedagem>
      <Container>
        <Titulo
          titulo="Hospedagem"
          titulo2="de grupos"
          descricao=""
          colorTitutlo="#fff"
          fontSizeTitulo="62px"
          fontSizeTitulo2="52px"
          colorDescricao="#585858"
          fontSizeDescricao="22px"
        />

        <Conteudo className="wow fadeIn" data-wow-delay="350ms">
          <Firula />
          <p>
            O Hotel Fazenda 3 Pinheiros está pronto para receber grupos que
            <br />
            também queiram curtir a vida durante a semana e passar dias
            <br /> incríveis em meio a natureza.
            <br />
            <br /> Chegando na segunda-feira no horário do almoço e saindo na
            sexta-
            <br />
            feira após o café da manhã, confira os preços e condições especiais.
            <br />
            <br />
            Fale com um de nossos agentes e saiba mais: (24) 2108-1000
          </p>
        </Conteudo>
      </Container>
    </Hospedagem>
  );
}

export default CompontentHospedagem;
