import styled from "styled-components";
import { Speaker2 } from "@styled-icons/fluentui-system-filled/Speaker2";
import { SpeakerOff } from "@styled-icons/fluentui-system-filled/SpeakerOff";

export const GroupBanner = styled.div`
  width: 100%;
  background: #1d4550;
  height: auto;
  padding-top: 45px;
  margin-bottom: -80px;
  @media only screen and (max-width: 1200px) {
    padding-top: 3px;
  }
`;

export const AreaBanner = styled.div`
  position: relative;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  height: auto;
  background: #efcfba;
  padding: 20px;
  .responsive {
    width: 100%;
    height: auto;
  }
  @media only screen and (max-width: 540px) {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 0px;
  }
`;
export const AreaBannerMobile = styled.div`
  display: none;
  @media only screen and (max-width: 540px) {
    position: absolute;
    bottom: -90px;
    left: 18px;
    padding: 15px;
    display: flex;
    width: 90%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #262c40;
    border: 5px solid #1d2532;
    img.sheet {
      width: 100px;
      height: auto;
      position: absolute;
      top: -60px;
      left: -24px;
      z-index: 3;
    }
  }
`;

export const AreaText = styled.div`
  position: absolute;
  bottom: 90px;
  left: -48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: #262c40;
  width: 80%;
  max-width: 413px;
  padding: 32px 48px 32px 64px;
  z-index: 1;
  img.ornament {
    position: absolute;
    bottom: -28px;
    right: -34px;
    z-index: 2;
  }
  img.sheet {
    position: absolute;
    top: -115px;
    left: -34px;
    z-index: 3;
  }
  @media only screen and (max-width: 540px) {
    display: none;
  }
`;

export const BigText = styled.p`
  font-size: 48px;
  font-family: "BaskervilleRegular";
  color: #fff6f2;
  letter-spacing: -1px;
  @media only screen and (max-width: 540px) {
    font-size: 30px;
  }
`;
export const SmallText = styled.p`
  font-size: 22px;
  font-family: "GilroyBold";
  color: #fff6f2;
  letter-spacing: -1px;
  @media only screen and (max-width: 540px) {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 1px;
    text-align: center;
  }
`;

export const VideoResort = styled.div`
  width: 100%;
  height: auto;
`;

export const SoundOn = styled(Speaker2)`
  position: absolute;
  bottom: 35px;
  left: 35px;
  z-index: 10;
  color: black;
  cursor: pointer;
  opacity: 0.5;
`;
export const SoundOff = styled(SpeakerOff)`
  position: absolute;
  bottom: 35px;
  left: 35px;
  z-index: 10;
  color: black;
  cursor: pointer;
  opacity: 0.5;
`;
