import Styled from "styled-components";

export const Container = Styled.div`
display:flex;
flex-direction: column;
margin: auto;
width:1100px;

@media only screen and (max-width: 1101px) {
  width:100%;

}


`;

export const Refeicoes = Styled.div`
padding-top: 74px;
background-color: #FFF6F2;
padding-bottom: 72px;

h2 {
  font-size: 48px;
  color: #392C23;
  font-weight: 500;
  text-align: center;
  margin-bottom: 54px;
}

span{
  font-size: 41px;
}

  

@media only screen and (max-width: 1001px) {
    padding-top: 44px;
    padding-bottom: 42px;
}  

@media only screen and (max-width: 426px) {

  h2 {
      font-size: 33px;
      margin-bottom: 24px;
  }
  span{
    font-size: 28px;
  }
}
  
`;

export const Conteudo = Styled.div`
  display: inline-block;
  width: 100%;

  div {
    margin-right: 32px;
    text-align: left;
    display: inline-block;
    max-width: 345px;
    float: left;
  }

  div:nth-child(3n) {
    margin-right: 0px;
  }

  @media only screen and (max-width: 1100px) {
    padding: 20px;

  div {
    margin-right: 32px;
    text-align: left;
    display: inline-block;
    max-width: 305px;
    float: left;
  }

  img {
    width: 100%;
  }
}


  @media only screen and (max-width: 1000px) {
    img {width: 100%;}

    div {
      margin-right: 3%;
      width: 30%;
      max-width: 100%;
    }
  }

  @media only screen and (max-width: 1001px) {

    padding: 0% 20%;

    div {
      margin-right: 0%;
      width: 100%;
      margin: auto;
      display: inline-block;
      margin-bottom: 20px;
    }

  }

  @media only screen and (max-width: 900px) {
    padding: 0% 15%;
  }


`;

export const Textos = Styled.div`
  


  h3 {
    font-family: 'GilroyBold', sans-serif;
    font-size: 24px;
    color: #392C23;
    margin-top: 24px;

  }

  p{
    font-size: 20px;
    line-height: 26px;
    color: #6F6D6D;
    margin-top: 20px;
  }   


  @media only screen and (max-width: 426px) {

    h3 {
      font-size: 22px;
    }    

    p {
      font-size: 18px;
      line-height: 23px;
    }


  }

`;
