import { Whatsapp } from "@styled-icons/boxicons-logos";
import styled from "styled-components";

export const Container = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: rgb(18, 140, 126);
  background: linear-gradient(
    351deg,
    rgba(18, 140, 126, 1) 0%,
    rgba(37, 211, 102, 1) 100%
  ); */
  background-color: #039e5e;
  position: fixed;
  z-index: 99;
  bottom: 150px;
  right: 30px;
  color: #fff;
  font-family: "Barlow", sans-serif;
  font-weight: 600;
  font-size: 16px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: 300ms ease;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  /* border: 3px #fff solid; */
  &:hover {
    transform: scale(1.1);
  }
  @media only screen and (max-width: 540px) {
    right: 15px;
    width: 60px;
    height: 60px;
    bottom: 140px;
  }
`;

export const Icon = styled(Whatsapp).attrs({ size: 40 })``;
