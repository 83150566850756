import { createGlobalStyle } from "styled-components";

import BaskervilleItalic from "../assets/fonts/Baskerville-Italic.ttf";
import BaskervilleRegular from "../assets/fonts/Baskerville-Regular.ttf";
import GilroyBold from "../assets/fonts/Gilroy-Bold.otf";
import GilroyExtraBold from "../assets/fonts/Gilroy-ExtraBold.otf";
import GilroySemiBold from "../assets/fonts/Gilroy-SemiBold.otf";
import GilroyMedium from "../assets/fonts/Gilroy-Medium.otf";
import GilroyRegular from "../assets/fonts/Gilroy-Regular.otf";
import LibreBaskervilleItalic from "../assets/fonts/LibreBaskerville-Italic.ttf";
import BaskervilleBTRoman from "../assets/fonts/Baskerville-BT-Roman.ttf";
import GilroyLight from "../assets/fonts/Gilroy-Light.ttf";

import AvenirNext from "../assets/fonts/AvenirNextLTPro-Regular.otf";
import AvenirDemi from "../assets/fonts/AvenirNextLTPro-Demi.otf";

const GlobalStyle = createGlobalStyle`  
   

   @font-face {
    font-family: "AvenirNext";
    src: url(${AvenirNext}) format("opentype");
  }
   @font-face {
    font-family: "AvenirDemi";
    src: url(${AvenirDemi}) format("opentype");
  }
   @font-face {
    font-family: "BaskervilleItalic";
    src: url(${BaskervilleItalic}) format("opentype");
  }
   

  @font-face {
    font-family: "BaskervilleRegular";
    src: url(${BaskervilleRegular}) format("opentype");
  }
  @font-face {
    font-family: "LibreBaskervilleItalic";
    src: url(${LibreBaskervilleItalic}) format("opentype");
  }

  @font-face {
    font-family: "BaskervilleBT";
    src: url(${BaskervilleBTRoman}) format("opentype");
  }
   
  
  @font-face {
    font-family: "GilroyBold"; 
    src: url(${GilroyBold}) format("opentype");
  }
   
  @font-face {
    font-family: "GilroyExtraBold";
    src: url(${GilroyExtraBold}) format("opentype");
  }

  @font-face {
    font-family: "GilroySemiBold";
    src: url(${GilroySemiBold}) format("opentype");
  }
  
  @font-face {
    font-family: "GilroyMediun";
    src: url(${GilroyMedium}) format("opentype");
  }

  @font-face {
    font-family: "GilroyRegular";
    src: url(${GilroyRegular}) format("opentype");
  }
  @font-face {
    font-family: "GilroyLight";
    src: url(${GilroyLight}) format("opentype");
  }


  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  html,
  body,  
  #root {
    width:100%;
    height: 100%;
    scroll-behavior: smooth;
  }
  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background:#fff;
    color:#717171;
    font-weight: normal;
    overflow-x: hidden;
  }
  a {
    text-decoration: none;
  }
  ul {
    list-style: none;     
  }
  
  h2, h3, p, button, input {    
    font-family: "BaskervilleRegular", sans-serif;
  }  
       
 
  span, a {    
    font-family: "GilroyBold", sans-serif;
  }   

`;

export default GlobalStyle;
