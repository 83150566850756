import styled from "styled-components";

import ImagemButtonNext from "../../assets/icones/seta_direita.png";
import ImagemButtonPrev from "../../assets/icones/seta_esquerda.png";

export const Diversao = styled.div`
  text-align: left;
  display: inline-block;
  width: 100%;
  background-color: #fff6f2;
`;

export const Imagens = styled.div`
  display: display: inline-block;
  width: 50%;
  float:left;
  position: relative;    

  img{
    width:100%;
  }
   

  @media only screen and (max-width: 900px) {  
    width: 100%;  
    padding: 20px;
  }
`;

export const Textos = styled.div`
  display: inline-block;
  width: 50%;
  float: left;
  margin-bottom: 24px;
  padding: 94px 103px 0px 87px;

  h2 {
    color: #ff7e01;
    font-size: 56px;
    line-height: 49px;
    font-weight: 500;
  }

  span {
    font-size: 47px;
    display: block;
    font-family: "GilroyBold", sans-serif;
  }

  p {
    font-size: 20px;
    line-height: 25px;
    margin-top: 24px;
  }

  @media only screen and (max-width: 1390px) {
    padding: 44px 63px 0px 57px;
  }

  @media only screen and (max-width: 1225px) {
    padding: 25px;

    p {
      font-size: 18px;
    }

    h2 {
      font-size: 46px;
      line-height: 39px;
    }

    span {
      font-size: 37px;
    }
  }

  @media only screen and (max-width: 900px) {
    width: 100%;
  }

  @media only screen and (max-width: 426px) {
    h2 {
      font-size: 36px;
      line-height: 33px;
    }
    span {
      font-size: 27px;
    }
  }
`;

export const ButtonPrev = styled.button`
  background-image: url(${ImagemButtonPrev});
  background-repeat: no-repeat;
  width: 38px;
  height: 38px;
  background-size: 38px 38px;
  position: absolute;
  z-index: 2;
  display: block;
  top: 50%;
  left: 55px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  @media only screen and (max-width: 426px) {
    display: none;
  }
`;

export const ButtonNext = styled.button`
  background-image: url(${ImagemButtonNext});
  background-repeat: no-repeat;
  width: 38px;
  height: 38px;
  background-size: 38px 38px;
  position: absolute;
  z-index: 2;
  display: block;
  top: 50%;
  right: 55px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  @media only screen and (max-width: 426px) {
    display: none;
  }
`;
