import React from "react";
import * as S from "./styles";
import iconCircle from "../../assets/icones/download.png";
import iconLuxo from "../../assets/icones/master-luxo.png";
import iconSpa from "../../assets/icones/spa.png";
import iconSwimming from "../../assets/icones/piscina.png";
import iconRestaurant from "../../assets/icones/restaurante.png";
import iconFlowers from "../../assets/icones/flores.png";
import iconBirds from "../../assets/icones/passaros.png";
import iconFruits from "../../assets/icones/frutas.png";
import iconKids from "../../assets/icones/kids.png";

function Tour360() {
  return (
    <>
      <S.Container id="tour">
        <S.GroupAll>
          {/* <S.Reserva
            href="https://book.omnibees.com/hotel/6628"
            target="_blank">
            Reserve Agora
          </S.Reserva> */}
          <S.GroupTitle>
            <S.OrangeTitle>Tour</S.OrangeTitle>
            <S.BoldTitle>360º</S.BoldTitle>
          </S.GroupTitle>
          <img src={iconCircle} alt="circle" />
          <S.SubTitle>
            Faça um tour 360° pelas áreas do nosso hotel.
            <br /> Clique nos links abaixo e navegue!
          </S.SubTitle>
          <S.GroupIcons>
            <S.Card
              href="https://www.google.com.br/maps/@-22.494929,-44.6881754,3a,75y,101.46h,91.56t/data=!3m7!1e1!3m5!1sAF1QipNVRqBOw0qytnFqVknvSffuI-g7MRMLYPyKlEs!2e10!3e12!7i6720!8i3360"
              target="_blank">
              <img src={iconLuxo} alt="Área Master Luxo" />
              <p>Ala Master Luxo</p>
            </S.Card>
            <S.Card
              href="https://www.google.com.br/maps/@-22.4944633,-44.6894874,3a,75y,262.47h,87.04t/data=!3m7!1e1!3m5!1sAF1QipOnIpWlGgS-gSn58vL2z7eGoF_slWe16u2z-tA!2e10!3e12!7i6720!8i3360"
              target="_blank">
              <img src={iconSpa} alt="SPA" />
              <p>SPA</p>
            </S.Card>
            <S.Card
              href="https://www.google.com.br/maps/@-22.4947058,-44.6892127,3a,75y,223.9h,96.05t/data=!3m7!1e1!3m5!1sAF1QipM2VRWSjaLdL_uv8jIqSt_2UfrcBU8jPCP6OCA!2e10!3e12!7i6720!8i3360"
              target="_blank">
              <img src={iconSwimming} alt="Piscina" />
              <p>Piscina</p>
            </S.Card>
            <S.Card
              href="https://www.google.com.br/maps/@-22.4938765,-44.6891013,3a,75y,97.57h,81.82t/data=!3m7!1e1!3m5!1sAF1QipNvVfi8PFOKs9uLMNw20sccEBX_EdQ8Hdhmr84!2e10!3e12!7i6720!8i3360"
              target="_blanl">
              <img src={iconRestaurant} alt="Restaurante" />
              <p>Restaurante</p>
            </S.Card>
            <S.Card
              href="https://www.google.com.br/maps/@-22.4946283,-44.6885995,3a,75y,8.8h,90.93t/data=!3m7!1e1!3m5!1sAF1QipOKCDlvSHQq3gI9lvEP3Ha8jvVZOWwFv-IBEZ8!2e10!3e12!7i6720!8i3360"
              target="_blank">
              <img src={iconFlowers} alt="Trila das Flores" />
              <p>Trilha das Flores</p>
            </S.Card>
            <S.Card
              href="https://www.google.com.br/maps/@-22.4945238,-44.6890991,3a,75y,6.01h,85.15t/data=!3m7!1e1!3m5!1sAF1QipMMFm-MtB2qrU57xX4vGyKg_7d4wzyielchtGc!2e10!3e12!7i6720!8i3360"
              target="_blank">
              <img src={iconBirds} alt="Trila dos Pássaros" />
              <p>Trilha dos Pássaros</p>
            </S.Card>
            <S.Card
              href="https://www.google.com.br/maps/@-22.4944804,-44.6883134,3a,75y,358.96h,86.95t/data=!3m7!1e1!3m5!1sAF1QipMZyupuaV4_k4QI-LZ8agVxpLNcjAgNuJBybjk!2e10!3e12!7i6720!8i3360"
              target="_blank">
              <img src={iconFruits} alt="Trila das Frutas" />
              <p>Trilha das Frutas</p>
            </S.Card>
            <S.Card
              href="https://www.google.com.br/maps/@-22.4942304,-44.6879442,3a,75y,16.19h,87.53t/data=!3m7!1e1!3m5!1sAF1QipNR-jvfgEJ_exF36Ky8_ui33MJIvRU3Blftd_k!2e10!3e12!7i6720!8i3360"
              target="_blank">
              <img src={iconKids} alt="Kids" />
              <p>Kids</p>
            </S.Card>
          </S.GroupIcons>
        </S.GroupAll>
      </S.Container>
    </>
  );
}
export default Tour360;
