import styled from "styled-components";
import { Whatsapp } from "@styled-icons/boxicons-logos/Whatsapp";
import bg from "../../assets/fim_de_ano_2023.jpg";

export const Under = styled.a`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${({ open }) => (open ? "flex" : "none")};
  z-index: 900;
  background-color: rgb(0, 0, 0, 0.8);
  overflow: auto;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 540px) {
    padding-top: 50px;
    align-items: flex-start;
  }
`;

export const Container = styled.div`
  position: relative;
  display: ${({ open }) => (open ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 600px;
  height: auto;
  img.image {
    width: 100%;
    height: auto;
  }
  @media only screen and (max-width: 1250px) {
    max-width: 400px;
  }
`;

export const CloseButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: 25px;
  width: 30px;
  height: 30px;
  background: transparent;
  color: #fff;
  font-size: 25px;
  border-radius: 50%;
  border: 2px solid #fff;
  cursor: pointer;
  opacity: 0.8;
`;
export const Vlink = styled.a`
  display: flex;
`;
