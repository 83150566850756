import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  background: #1d4550;
  padding: 20px 0 50px 0;
`;
