import React, { useState } from "react";
import Swiper from "react-id-swiper";
import "react-id-swiper/lib/styles/css/swiper.css";

import { Vantagens, Container, Top, Conteudo, ConteudoMobile } from "./styles";

import ImageVantagens01 from "../../assets/vantagens/v01.png";
import ImageVantagens02 from "../../assets/vantagens/v02.png";
import ImageVantagens03 from "../../assets/vantagens/v03.png";
import ImageVantagens04 from "../../assets/vantagens/v04.png";
import ImageVantagens05 from "../../assets/vantagens/v05.png";
import ImageVantagens06 from "../../assets/vantagens/v06.png";
import ImageVantagens07 from "../../assets/vantagens/v07.png";
import ImageVantagens08 from "../../assets/vantagens/v08.png";

import Titulo from "../TituloPadrao";
import "./app.css";

const datasVantagens = [
  {
    id: 1,
    imagem: ImageVantagens01,
    titulo: "Café da manhã e buffet de alta gastronomia",
    titulo2: "",
    descricao: "Delicioso café da manhã e buffet com cardápio completo.",
  },
  {
    id: 2,
    imagem: ImageVantagens02,
    titulo: "Piscina",
    titulo2: "e sauna",
    descricao:
      "Área de lazer com piscina com toboágua, piscina infantil e sauna.",
  },
  {
    id: 3,
    imagem: ImageVantagens03,
    titulo: "Cerveja e",
    titulo2: "drinks",
    descricao: "Bar com diversas opções de bebidas, drinks e cervejas.",
  },
  {
    id: 4,
    imagem: ImageVantagens04,
    titulo: "Áreas",
    titulo2: "de Esporte",
    descricao: "Quadra de tênis, vôlei e futebol, além de caiaque e arvorismo.",
  },
  {
    id: 5,
    imagem: ImageVantagens05,
    titulo: "Acomodações",
    titulo2: "de alto padrão",
    descricao:
      "Suítes amplas, com TV,  ar-condicionado e frigobar disponíveis.",
  },
  {
    id: 6,
    imagem: ImageVantagens06,
    titulo: "Pratos",
    titulo2: "gastronômicos",
    descricao: "Pratos de chef com alimentos produzidos e cultivados aqui.",
  },
  {
    id: 7,
    imagem: ImageVantagens07,
    titulo: "Animais",
    titulo2: "do campo",
    descricao: "Diversas espécies de animais, haras e passeio a cavalo.",
  },
  {
    id: 8,
    imagem: ImageVantagens08,
    titulo: "Diversão",
    titulo2: "infantil",
    descricao: "Área de recreação com brinquedos e monitores para a garotada.",
  },
];

function CompontentVantagens({ datasTopo }) {
  const [swiper, updateSwiper] = useState(null);

  const params = {
    Swiper,
    initialSlide: 0,
    pagination: {
      el: ".swiper-pagination",
    },
    spaceBetween: 30,
    getSwiper: updateSwiper,
  };

  const RenderListaVantagens = datasVantagens.map(todo => (
    <div className="Vantagem" key={todo.id}>
      <img src={todo.imagem} alt="hotel" title="hotel" />
      <h3>
        {todo.titulo} <br /> {todo.titulo2}
      </h3>
      <p>{todo.descricao}</p>
    </div>
  ));

  return (
    <Vantagens className="vantagens">
      <Container>
        <Titulo
          titulo={datasTopo.titulo}
          titulo2={datasTopo.titulo2}
          descricao={datasTopo.descricao}
          colorTitutlo={datasTopo.colorTitutlo}
          backgroundColorTitulo={datasTopo.backgroundColorTitulo}
          fontSizeTitulo={datasTopo.fontSizeTitulo}
          fontSizeTitulo2={datasTopo.fontSizeTitulo2}
          colorDescricao={datasTopo.colorDescricao}
          fontSizeDescricao={datasTopo.fontSizeDescricao}
          paddingTopTP={datasTopo.paddingTopTP}
          displayDescricao={datasTopo.displayDescricao}
        />

        <Conteudo className="wow fadeInRight" data-wow-delay="350ms">
          {RenderListaVantagens}
        </Conteudo>

        <ConteudoMobile className="wow fadeInRight" data-wow-delay="350ms">
          <Swiper {...params}>{RenderListaVantagens}</Swiper>
        </ConteudoMobile>
      </Container>
    </Vantagens>
  );
}

export default CompontentVantagens;
