import React from "react";
import {
  Container,
  Historia,
  Conteudo,
  Imagem,
  Textos,
  IconeFolha,
  IconeEsp,
  IconeFolha2,
  IconeDireita,
  IconeEsquerda,
} from "./styles";

import ImagemHotelImperial from "../../assets/historia/hotel_imperial.png";

function CompontentHistoria() {
  return (
    <Historia>
      <Container>
        <Conteudo>
          <Imagem>
            <IconeFolha
              className="wow rotateInDownRight"
              data-wow-delay="350ms"
            />
            <IconeEsp className="wow rotateInDownLeft" data-wow-delay="350ms" />
            <div>
              <img src={ImagemHotelImperial} />
            </div>
          </Imagem>
          <Textos className="wow fadeIn" data-wow-delay="250ms">
            <h2>
              Um hotel <br />
              <span>colonial</span>
            </h2>
            <p>
              O 3 Pinheiros é um dos hotéis fazenda mais <br /> antigos do país
              e transcende ao tempo do <br /> café, resistindo heroicamente ao
              tempo.
            </p>

            <a href="/nossa-historia">Nossa História</a>

            <IconeFolha2 />
          </Textos>
        </Conteudo>
      </Container>
      <IconeDireita />
      <IconeEsquerda />
    </Historia>
  );
}

export default CompontentHistoria;
