import React from "react";
import { Helmet } from "react-helmet";

import Menu from "../../components/Menu";
import NewReserva from "../../components/NewReserva";
import Informacoes from "../../components/Informacoes";

import {
  Container,
  Hero,
  ContentHero,
  Title,
  Bold,
  Subtitle,
  Info,
  Content,
  Wrap,
  Item,
  Icon,
  ItemTitle,
  Text,
} from "./styles";

function Diretrizes() {
  return (
    <Container>
      <Helmet>
        <title>Gastronomia - Resort Fazenda 3 Pinheiros</title>
      </Helmet>
      <Menu />
      <NewReserva />
      <Hero>
        <ContentHero>
          <Title>
            Sua estadia
            <br />
            <Bold>com mais segurnaça</Bold>
          </Title>
          <Subtitle>diretrizes de funcionamento</Subtitle>
          <Info>
            Confira as novas diretrizes de funcionamento e os cuidamos a serem
            tomados no Hotel para que todos possam se hospedar e desfrutar dos
            espaços com segurança.
          </Info>
        </ContentHero>
      </Hero>
      <Content>
        <Wrap>
          <Item>
            <Icon type="check" />
            <ItemTitle>Check-in e Check-out</ItemTitle>
            <Text>
              Pré check-in enviado por e-mail;
              <br />
              Check in apenas com o titular da reserva;
              <br />
              Medição de temperatura no check in.
            </Text>
          </Item>
          <Item>
            <Icon type="hygiene" />
            <ItemTitle>Áreas Comuns</ItemTitle>
            <Text>
              Uso de máscara obrigatório nas áreas comuns do hotel; <br />
              Manter distanciamento de 1,5m entre grupos familiares; <br />
              Álcool em Gel disponíveis nos bares, restaurante e recepção;
              <br />
              Limpeza e desinfecção intensificadas;
            </Text>
          </Item>
          <Item>
            <Icon type="bed" />
            <ItemTitle>Acomodação Segura</ItemTitle>
            <Text>
              Arrumações feitas a cada 3 dias ou sob solicitação do hóspede;{" "}
              <br />
              (Caso queira arrumação, o serviço deverá ser solicitado à
              recepção) <br />
              Kit de amenities extra para evitar a entrada de funcionários na
              suíte;
            </Text>
          </Item>
          <Item>
            <Icon type="trees" />
            <ItemTitle>Lazer Seguro</ItemTitle>
            <Text>
              Atividades ao ar livre;
              <br />
              Uso de máscaras para crianças: cada pai ou mãe devem ser
              responsáveis pela utilização da máscara de seus filhos;
            </Text>
          </Item>
          <Item>
            <Icon type="dinner" />
            <ItemTitle>Comer Bem e em Segurança</ItemTitle>
            <Text>
              Café da manhã 8h00 às 10h00
              <br />
              Infantil - Almoço: 12h00 às 13h00 - Jantar: 20h00 às 22h00
              <br />
              Adultos - Almoço: 13h00 às 15h00 - Jantar: 20:00 às 22h00
              <br />
              Será disponibilizado luvas na área de buffet e o uso será
              obrigatório;
              <br />
              Todos os hóspedes deverão estar utilizando máscaras e luvas na
              hora de se servirem;
              <br />
              Redução da capacidade máxima de pessoas no Restaurante;
              <br />
              Mais distanciamento entre as mesas;
              <br />
              Serviço em cada estação para minimizar o contato entre talheres;{" "}
              <br />
              Refeições servidas à mesa ou com um funcionário (utilizando EPI)
              servindo no buffet;
              <br />
              Uso obrigatório de máscaras no restaurante, podendo ser retirada
              apenas ao se sentar à mesa.
            </Text>
          </Item>
        </Wrap>
      </Content>
      <Informacoes />
    </Container>
  );
}

export default Diretrizes;
