import React, { useEffect } from "react";
import WOW from "wowjs";
import { Helmet } from "react-helmet";

import TopInterna from "../../components/TopoInterna";
import Informacoes from "../../components/Informacoes";
import Menu from "../../components/Menu";
import NewReserva from "../../components/NewReserva";
import FormTrabalheConosco from "../../components/FormTrabalheConosco";

export default function TrabalheConosco() {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <>
      <Helmet>
        <title>Trabalhe Conosco - Resort Fazenda 3 Pinheiros</title>
      </Helmet>
      <Menu />

      <NewReserva />

      <TopInterna
        titulo="Trabalhe"
        titulo2="Conosco"
        subtitulo="Envie seu currículo:"
        bgColor="#262C40"
        fontColor="#fff"
        texto="O Hotel Fazenda 3 Pinheiros é um dos mais antigos do País em sua categoria e buscamos pessoas que se identifiquem com nossa cultura.
        "
      />
      <FormTrabalheConosco />
      <Informacoes />
    </>
  );
}
