import React from "react";
import { Refeicoes, Container, Conteudo, Textos } from "./styles";

import ImagemRF00 from "../../assets/gastronomia/refeicoes/rf_00.png";
import ImagemRF01 from "../../assets/gastronomia/refeicoes/rf_01.png";
import ImagemRF02 from "../../assets/gastronomia/refeicoes/rf_02.png";
import alta_gastronomia from "../../assets/gastronomia/alta-gastronomia.jpg";
import sobremesas from "../../assets/gastronomia/doce.jpg";

function CompontentRefeicoes() {
  return (
    <Refeicoes className="wow fadeIn" data-wow-delay="550ms">
      <Container>
        <h2>
          Refeições <span>inclusas</span>
        </h2>

        <Conteudo>
          <div>
            <img src={ImagemRF00} />
            <Textos>
              <h3>Café da Manhã</h3>
              <p>
                Nada como começar o dia com um farto e delicioso café da manhã!
                Principalmente com pães artesanais, broas, sucos naturais,
                bolos, queijos, manteiga, requeijão, mel, entre outras delicias
                produzidas e cultivadas na fazenda do Hotel. Alguns pratos são
                preparados na hora, como omeletes, tapioca, waffles e ovo
                mexido.
              </p>
            </Textos>
          </div>
          <div>
            <img src={alta_gastronomia} />
            <Textos>
              <h3>Alta Gastronomia</h3>
              <p>
                Nossa comida é um assunto à parte. Um blend de comida caseira
                com a mais alta gastronomia, preparada com muito esmero e
                transformadas em refeições fartas para serem saboreadas à
                vontade.
              </p>
            </Textos>
          </div>
          <div>
            <img src={sobremesas} />
            <Textos>
              <h3>Sobremesas</h3>
              <p>
                Para fechar com chave de ouro essa experiência gastronômica, uma
                grande variedade de doces deliciosos é disposta, para seu
                deleite, em um lindo carro de boi. E claro que não poderia ser
                diferente, todos os doces são produzidos em nossa confeitaria!
              </p>
            </Textos>
          </div>
        </Conteudo>
      </Container>
    </Refeicoes>
  );
}

export default CompontentRefeicoes;
