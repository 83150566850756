import React from "react";

import * as S from "./style";
import img01 from "../../assets/EventosHotel/01.jpg";
import img02 from "../../assets/EventosHotel/02.jpg";
import img03 from "../../assets/EventosHotel/03.jpg";
import img04 from "../../assets/EventosHotel/04.jpg";
import img05 from "../../assets/EventosHotel/05.jpg";
import img06 from "../../assets/EventosHotel/06.jpg";
import img07 from "../../assets/EventosHotel/07.jpg";
import img08 from "../../assets/EventosHotel/08.jpg";
import Estrutura from "../../components/Estruturas";

const EventosHotel = () => {
  const datasImagens = [
    { id: 1, imagem: img01 },
    { id: 2, imagem: img02 },
    { id: 3, imagem: img03 },
    { id: 4, imagem: img04 },
    { id: 5, imagem: img05 },
    { id: 6, imagem: img06 },
    { id: 7, imagem: img07 },
    { id: 8, imagem: img08 },
  ];
  const datasTopo = {
    titulo: "Eventos",
    titulo2: "Realizados no nosso hotel",
    descricao: "",
    colorTitutlo: "#fff",
    backgroundColorTitulo: "#fff",
    fontSizeTitulo: "62px",
    fontSizeTitulo2: "52px",
    colorDescricao: "#585858",
    fontSizeDescricao: "22px",
    paddingTopTP: "70px",
    displayButton: "none",
    fontFamily: "'LibreBaskervilleItalic'",
  };
  return (
    <S.Container>
      <Estrutura
        datasImagens={datasImagens}
        datasTopo={datasTopo}
        datasButton="/fotos"
        displayButton="none"
      />
    </S.Container>
  );
};
export default EventosHotel;
