import styled from "styled-components";

import Folha1 from "../../assets/historia/folha-1.png";
import Folha2 from "../../assets/historia/folha-2.png";
import Esp from "../../assets/historia/esp.png";
import Direita from "../../assets/historia/icone_direita.png";
import Esquerda from "../../assets/historia/icone_esquerda.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 1100px;

  @media only screen and (max-width: 1101px) {
    width: 100%;
  }
`;

export const Historia = styled.div`
  background-color: #0fa592;
  padding-top: 176px;
  display: inline-block;
  width: 100%;
  position: relative;
  @media only screen and (max-width: 1025px) {
    padding-top: 86px;
  }
  @media only screen and (max-width: 991px) {
    padding-bottom: 50px;
  }
`;

export const Conteudo = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  z-index: 2;
  top: 110px;
  margin-top: -110px;

  @media only screen and (max-width: 901px) {
    top: 0px;
    margin-top: 0px;
    margin-bottom: 50px;
  }
`;

export const Imagem = styled.div`
  padding-left: 63px;
  padding-right: 34px;
  width: 50%;
  float: left; 

  div {
    position: relative; 
  }

  img {
    border: 50px solid #EFCFBA;
    border-right: 43px solid #EFCFBA;
    border-left: 43px solid #EFCFBA;
  }

  @media only screen and (max-width: 1025px) {
    img {
      width: 90%;
      border: 34px solid #EFCFBA;
      border-right: 28px solid #EFCFBA;  
      border-left: 28px solid #EFCFBA;
    }  
  }


  @media only screen and (max-width: 901px) {
    width: 100%;

    img {
      margin: auto;
      display: block;
      width:70%;
    }

  }


  @media only screen and (max-width: 650px) {
    padding: 0px 20px;
    img{
        width: 80%;
        border: 25px solid #EFCFBA;
        border-right: 20px solid #EFCFBA;
        border-left: 20px solid #EFCFBA;
    }   
  }

}

`;

export const IconeEsquerda = styled.span`
  background-image: url(${Esquerda});
  background-repeat: no-repeat;
  width: 91px;
  height: 91px;
  position: absolute;
  z-index: 1;
  background-size: 91px 91px;
  left: 40px;
  bottom: 40px;

  @media only screen and (max-width: 650px) {
    width: 50px;
    height: 50px;
    background-size: 50px 50px;
    left: 20px;
    bottom: 20px;
  }
`;

export const IconeDireita = styled.span`
  background-image: url(${Direita});
  background-repeat: no-repeat;
  width: 91px;
  height: 91px;
  position: absolute;
  z-index: 1;
  background-size: 91px 91px;
  right: 40px;
  bottom: 40px;

  @media only screen and (max-width: 650px) {
    width: 50px;
    height: 50px;
    background-size: 50px 50px;
    right: 20px;
    bottom: 20px;
  }
`;

export const IconeFolha2 = styled.span`
  background-image: url(${Folha2});
  background-repeat: no-repeat;
  width: 84px;
  height: 101px;
  position: absolute;
  bottom: 59px;
  right: 130px;
  z-index: 2;
  background-size: 84px 101px;

  @media only screen and (max-width: 1025px) {
    bottom: 10px;
  }

  @media only screen and (max-width: 901px) {
    bottom: -146px;
  }

  @media only screen and (max-width: 650px) {
    right: 18%;
  }
`;

export const IconeEsp = styled.span`
  background-image: url(${Esp});
  background-repeat: no-repeat;
  width: 101px;
  height: 82px;
  position: absolute;
  top: 98px;
  z-index: 2;
  left: 437px;
  background-size: 101px 82px;

  @media only screen and (max-width: 1025px) {
    left: 37%;
  }

  @media only screen and (max-width: 901px) {
    left: 75%;
  }

  @media only screen and (max-width: 426px) {
    width: 81px;
    height: 62px;
    background-size: 81px 62px;
  }

  @media only screen and (max-width: 376px) {
    left: 70%;
  }
`;

export const IconeFolha = styled.span`
  background-image: url(${Folha1});
  background-repeat: no-repeat;
  width: 106px;
  height: 130px;
  position: absolute;
  top: 23px;
  z-index: 2;
  left: 21px;
  background-size: 106px 130px;

  @media only screen and (max-width: 901px) {
    left: 126px;
  }

  @media only screen and (max-width: 650px) {
    left: 0%;
  }

  @media only screen and (max-width: 426px) {
    width: 74px;
    height: 88px;
    left: 10%;
    background-size: 74px 88px;
    left: 0%;
  }
`;

export const Textos = styled.div`
  width: 50%;
  float: left;
  color: #fff;
  padding-left: 34px;
  padding-top: 48px;
  h2 {
    font-size: 74px;
    line-height: 60px;
    margin-bottom: 18px;
    font-weight: 500;
  }
  span {
    font-size: 62px;
    font-family: "GilroyBold", sans-serif;
  }

  p {
    font-size: 22px;
    line-height: 25px;
    margin-bottom: 30px;
  }

  a {
    color: #392c23;
    font-size: 18px;
    text-transform: uppercase;
    font-family: "GilroyBold", sans-serif;
  }

  @media only screen and (max-width: 1025px) {
    h2 {
      font-size: 64px;
      line-height: 50px;
    }

    span {
      font-size: 56px;
    }

    p {
      font-size: 21px;
    }
  }

  @media only screen and (max-width: 901px) {
    width: 100%;
    padding-left: 0px;
    text-align: center;
  }

  @media only screen and (max-width: 650px) {
    h2 {
      font-size: 48px;
      line-height: 40px;
    }

    span {
      font-size: 40px;
    }

    p {
      margin: auto;
      font-size: 19px;
      width: 90%;
      margin-bottom: 20px;
    }
  }

  @media only screen and (max-width: 426px) {
    h2 {
      font-size: 42px;
      line-height: 40px;
    }

    span {
      font-size: 37px;
    }

    p {
      margin: auto;
      font-size: 16px;
      width: 95%;
      margin-bottom: 20px;
      line-height: 21px;
    }
  }
`;
