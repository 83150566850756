import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import "swiper/css/swiper.css";

// import {
//   Modal,
//   BoxModal,
//   BtnFecharModal,
//   Group
// } from "./styles";

import * as S from "./styles";

import closeButton from "../../assets/close.png";
import logoPet from "../../assets/logo-pet-friendly-novo.png";

function ComponentModalPet({ modalController, onClose }) {
  function handleClose() {
    onClose();
  }

  if (modalController === true) {
    document.body.style.overflow = "hidden";
  }

  return (
    <>
      <S.Modal open={modalController}>
        <S.Group>
          <S.BtnFecharModal onClick={handleClose}>
            <img src={closeButton} alt="Close" />
          </S.BtnFecharModal>
          <img className="logopet" src={logoPet} alt="logo pet friendly" />
          <S.TextItalic>
            Nosso maior objetivo é oferecer uma experiência de hospedagem e
            serviços completa. Para isso, abrimos nossas portas para o seu Pet.
            Cuidando também da experiência dos demais hóspedes que não têm
            animais. Em nosso hotel, é permitida a permanência de até 2 pets
            (cachorros ou gatos) por apartamento de até 15 kg cada, com até 35cm
            de altura estando com as 4 patinhas no chão. Nosso hotel fornece
            tigela para água, para comida e cama. Toda a alimentação do pet é de
            responsabilidade de seu tutor. Leia abaixo com atenção as condições
            e procedimentos para mantermos um ambiente agradável para todos.
            Incluindo locais permitidos para circulação do seu pet.
          </S.TextItalic>
          <S.TextItalic>
            É cobrada uma diária do pet para higienização e limpeza do quarto e
            demais ambientes por onde os animais circulam. O valor é acertado no
            check-in por Pet de R$ 90,00 a diária.
          </S.TextItalic>
          <S.TextItalic>
            Caso o seu pet seja de um porte não aceito em nosso hotel a região
            possui um fornecedor que oferece o serviço de hospedagem para cães.
            Thaty Pet Hotel - (24) 98111-7286
          </S.TextItalic>

          <S.TitleItalic>Condições</S.TitleItalic>
          <ul>
            <li>
              1. É permitida a permanência de até dois Pets (cachorros ou gatos)
              de até 15kg cada pet por apartamento;
            </li>
            <li>
              2. O animal deverá estar tratado contra pulgas, carrapatos e
              outros parasitas e o cartão de vacinação atualizado deve ser
              apresentado no momento de check-in.
            </li>
            <li>
              3. O Tutor se responsabilizará em garantir que o animal permaneça
              silencioso, de forma a evitar incomodar outros hóspedes.
            </li>
            <li>
              4.É de responsabilidade do tutor a limpeza de resíduos sólidos e
              líquidos de seus animais;
            </li>
            <li>
              5. Os animais devem circular nas áreas comuns e externas do hotel
              devidamente seguros com guia. Nas áreas sociais devem permanecer
              no colo;
            </li>
            <li>
              6. Não é permitida a circulação do animal nas áreas de lazer,
              cozinha, restaurante e espaços de eventos;
            </li>
            <li>
              7.A limpeza do apartamento será feita somente com a presença do
              tutor do animal. Caso o animal apresente instabilidade social,
              deverá permanecer alojado em caixa ou bolsa de transporte ou na
              parte externa.
            </li>
            <li>
              8. É proibido o uso de qualquer enxoval do hotel para o animal;
            </li>
            <li>
              9. Todo e qualquer dano que possa acontecer aos tecidos,
              mobiliários e estrutura do apartamento deverão ser ressarcidos
              integralmente.
            </li>
          </ul>
        </S.Group>
      </S.Modal>
    </>
  );
}

export default withRouter(ComponentModalPet);
