import React, { useEffect } from "react";
import WOW from "wowjs";
import { Helmet } from "react-helmet";
import Informacoes from "../../components/Informacoes";
import ComoChegar from "../../components/ComoChegar";
import NossaHistoria from "../../components/NossaHistoria";
import Menu from "../../components/Menu";
import Sobrenos from "../../components/Sobrenos";
import Reserva from "../../components/Reserva";
import NewReserva from "../../components/NewReserva";
import ReservaMobile from "../../components/Reserva/mobile";
import SelecionarEstrutura from "../../components/Estruturas/selecionar";

import ImageEstrutura1 from "../../assets/estrutura/hotel/hotel-01.jpg";
import ImageEstrutura2 from "../../assets/estrutura/hotel/hotel-02.jpg";
import ImageEstrutura3 from "../../assets/estrutura/hotel/hotel-03.jpg";
import ImageEstrutura4 from "../../assets/estrutura/hotel/hotel-04.jpg";
import ImageEstrutura5 from "../../assets/estrutura/hotel/hotel-05.jpg";
import ImageEstrutura6 from "../../assets/estrutura/hotel/hotel-06.jpg";
import ImageEstrutura7 from "../../assets/estrutura/hotel/hotel-07.jpg";
import ImageEstrutura8 from "../../assets/estrutura/hotel/hotel-08.jpg";

import ImageEstrutura9 from "../../assets/estrutura/acomodacoes/acomodacoes-01.jpg";
import ImageEstrutura10 from "../../assets/estrutura/acomodacoes/acomodacoes-02.jpg";
import ImageEstrutura11 from "../../assets/estrutura/acomodacoes/acomodacoes-03.jpg";
import ImageEstrutura12 from "../../assets/estrutura/acomodacoes/acomodacoes-04.jpg";
import ImageEstrutura13 from "../../assets/estrutura/acomodacoes/acomodacoes-05.jpg";
import ImageEstrutura14 from "../../assets/estrutura/acomodacoes/acomodacoes-06.jpg";
import ImageEstrutura15 from "../../assets/estrutura/acomodacoes/acomodacoes-07.jpg";
import ImageEstrutura16 from "../../assets/estrutura/acomodacoes/acomodacoes-08.jpg";

import ImageEstrutura17 from "../../assets/estrutura/restaurante/restaurante-01.jpg";
import ImageEstrutura18 from "../../assets/estrutura/restaurante/restaurante-02.jpg";
import ImageEstrutura19 from "../../assets/estrutura/restaurante/restaurante-03.jpg";
import ImageEstrutura20 from "../../assets/estrutura/restaurante/restaurante-04.jpg";
import ImageEstrutura21 from "../../assets/estrutura/restaurante/restaurante-05.jpg";
import ImageEstrutura22 from "../../assets/estrutura/restaurante/restaurante-06.jpg";
import ImageEstrutura23 from "../../assets/estrutura/restaurante/restaurante-07.jpg";
import ImageEstrutura24 from "../../assets/estrutura/restaurante/restaurante-08.jpg";

import ImageEstrutura25 from "../../assets/estrutura/lazer/lazer-01.jpg";
import ImageEstrutura26 from "../../assets/estrutura/lazer/lazer-02.jpg";
import ImageEstrutura27 from "../../assets/estrutura/lazer/lazer-03.jpg";
import ImageEstrutura28 from "../../assets/estrutura/lazer/lazer-04.jpg";
import ImageEstrutura29 from "../../assets/estrutura/lazer/lazer-05.jpg";
import ImageEstrutura30 from "../../assets/estrutura/lazer/lazer-06.jpg";
import ImageEstrutura31 from "../../assets/estrutura/lazer/lazer-07.jpg";
import ImageEstrutura32 from "../../assets/estrutura/lazer/lazer-08.jpg";

import ImageEstrutura33 from "../../assets/estrutura/fazenda/fazenda-01.jpg";
import ImageEstrutura34 from "../../assets/estrutura/fazenda/fazenda-02.jpg";
import ImageEstrutura35 from "../../assets/estrutura/fazenda/fazenda-03.jpg";
import ImageEstrutura36 from "../../assets/estrutura/fazenda/fazenda-04.jpg";
import ImageEstrutura37 from "../../assets/estrutura/fazenda/fazenda-05.jpg";
import ImageEstrutura38 from "../../assets/estrutura/fazenda/fazenda-06.jpg";
import ImageEstrutura39 from "../../assets/estrutura/fazenda/fazenda-07.jpg";
import ImageEstrutura40 from "../../assets/estrutura/fazenda/fazenda-08.jpg";

import ImageEstrutura41 from "../../assets/estrutura/eventos/eventos-01.jpg";
import ImageEstrutura42 from "../../assets/estrutura/eventos/eventos-02.jpg";
import ImageEstrutura43 from "../../assets/estrutura/eventos/eventos-03.jpg";
import ImageEstrutura44 from "../../assets/estrutura/eventos/eventos-04.jpg";
import ImageEstrutura45 from "../../assets/estrutura/eventos/eventos-05.jpg";
import ImageEstrutura46 from "../../assets/estrutura/eventos/eventos-06.jpg";
import ImageEstrutura47 from "../../assets/estrutura/eventos/eventos-07.jpg";
import ImageEstrutura48 from "../../assets/estrutura/eventos/eventos-08.jpg";

import ImageSpa1 from "../../assets/estrutura/spa/spa_01.jpg";
import ImageSpa2 from "../../assets/estrutura/spa/spa_02.jpg";
import ImageSpa3 from "../../assets/estrutura/spa/spa_03.jpg";
import ImageSpa4 from "../../assets/estrutura/spa/spa_04.jpg";
import ImageSpa5 from "../../assets/estrutura/spa/spa_05.jpg";
import ImageSpa6 from "../../assets/estrutura/spa/spa_06.jpg";
import ImageSpa7 from "../../assets/estrutura/spa/spa_07.jpg";
import ImageSpa8 from "../../assets/estrutura/spa/spa_08.jpg";
import ImageSpa9 from "../../assets/estrutura/spa/spa_09.jpg";
import ImageSpa10 from "../../assets/estrutura/spa/spa_10.jpg";
import ImageSpa11 from "../../assets/estrutura/spa/spa_11.jpg";
import ImageSpa12 from "../../assets/estrutura/spa/spa_12.jpg";

export default function PaginaNossaHotel() {
  const GetdatasImagensHotel = [
    { id: 1, imagem: ImageEstrutura1 },
    { id: 2, imagem: ImageEstrutura2 },
    { id: 3, imagem: ImageEstrutura3 },
    { id: 4, imagem: ImageEstrutura4 },
    { id: 5, imagem: ImageEstrutura5 },
    { id: 6, imagem: ImageEstrutura6 },
    { id: 7, imagem: ImageEstrutura7 },
    { id: 8, imagem: ImageEstrutura8 },
  ];

  const GetdatasImagensAcomodacoes = [
    { id: 1, imagem: ImageEstrutura9 },
    { id: 2, imagem: ImageEstrutura10 },
    { id: 3, imagem: ImageEstrutura11 },
    { id: 4, imagem: ImageEstrutura12 },
    { id: 5, imagem: ImageEstrutura13 },
    { id: 6, imagem: ImageEstrutura14 },
    { id: 7, imagem: ImageEstrutura15 },
    { id: 8, imagem: ImageEstrutura16 },
  ];

  const GetdatasImagensRestaurante = [
    { id: 1, imagem: ImageEstrutura17 },
    { id: 2, imagem: ImageEstrutura18 },
    { id: 3, imagem: ImageEstrutura19 },
    { id: 4, imagem: ImageEstrutura20 },
    { id: 5, imagem: ImageEstrutura21 },
    { id: 6, imagem: ImageEstrutura22 },
    { id: 7, imagem: ImageEstrutura23 },
    { id: 8, imagem: ImageEstrutura24 },
  ];

  const GetdatasImagensAreasDeLazer = [
    { id: 1, imagem: ImageEstrutura25 },
    { id: 2, imagem: ImageEstrutura26 },
    { id: 3, imagem: ImageEstrutura27 },
    { id: 4, imagem: ImageEstrutura28 },
    { id: 5, imagem: ImageEstrutura29 },
    { id: 6, imagem: ImageEstrutura30 },
    { id: 7, imagem: ImageEstrutura31 },
    { id: 8, imagem: ImageEstrutura32 },
  ];

  const GetdatasImagensFazenda = [
    { id: 1, imagem: ImageEstrutura33 },
    { id: 2, imagem: ImageEstrutura34 },
    { id: 3, imagem: ImageEstrutura35 },
    { id: 4, imagem: ImageEstrutura36 },
    { id: 5, imagem: ImageEstrutura37 },
    { id: 6, imagem: ImageEstrutura38 },
    { id: 7, imagem: ImageEstrutura39 },
    { id: 8, imagem: ImageEstrutura40 },
  ];

  const GetdatasImagensEventos = [
    { id: 1, imagem: ImageEstrutura41 },
    { id: 2, imagem: ImageEstrutura42 },
    { id: 3, imagem: ImageEstrutura43 },
    { id: 5, imagem: ImageEstrutura44 },
    { id: 5, imagem: ImageEstrutura45 },
    { id: 6, imagem: ImageEstrutura46 },
    { id: 7, imagem: ImageEstrutura47 },
    { id: 8, imagem: ImageEstrutura48 },
  ];

  const GetdatasImagensSpa = [
    { id: 1, imagem: ImageSpa1 },
    { id: 2, imagem: ImageSpa2 },
    { id: 3, imagem: ImageSpa3 },
    { id: 4, imagem: ImageSpa4 },
    { id: 5, imagem: ImageSpa5 },
    { id: 6, imagem: ImageSpa6 },
    { id: 7, imagem: ImageSpa7 },
    { id: 8, imagem: ImageSpa8 },
    { id: 9, imagem: ImageSpa9 },
    { id: 10, imagem: ImageSpa10 },
    { id: 11, imagem: ImageSpa11 },
    { id: 12, imagem: ImageSpa12 },
  ];

  const datasTopo = {
    titulo: "Nossa",
    titulo2: "estrutura",
    descricao:
      "Natureza exuberante e diversos ambientes de lazer, esporte e diversão para toda a família curtir e relaxar.",
    colorTitutlo: "#392C23",
    backgroundColorTitulo: "#fff",
    fontSizeTitulo: "62px",
    fontSizeTitulo2: "52px",
    colorDescricao: "#585858",
    fontSizeDescricao: "22px",
    paddingTopTP: "70px",
    displayButton: "block",
    displayButtonFotos: "none",
  };

  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  return (
    <>
      <Helmet>
        <title>O Hotel - Resort Fazenda 3 Pinheiros</title>
      </Helmet>
      <Menu />
      <NewReserva />
      {/* <Reserva /> */}
      <Sobrenos />
      <ReservaMobile bgColor="#262C40" />
      <NossaHistoria />
      <SelecionarEstrutura
        datasTopo={datasTopo}
        GetdatasImagensHotel={GetdatasImagensHotel}
        GetdatasImagensAcomodacoes={GetdatasImagensAcomodacoes}
        GetdatasImagensRestaurante={GetdatasImagensRestaurante}
        GetdatasImagensAreasDeLazer={GetdatasImagensAreasDeLazer}
        GetdatasImagensFazenda={GetdatasImagensFazenda}
        GetdatasImagensEventos={GetdatasImagensEventos}
        GetdatasImagensSpa={GetdatasImagensSpa}
        datasButton="/fotos"
      />
      <ComoChegar bgColorMobile="#FF7E01" />
      <Informacoes />
    </>
  );
}
