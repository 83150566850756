import React from "react";
import * as S from "./styles";

function HolyContent() {
  return (
    <>
      <S.Container>
        <S.Content>
          <S.MineMenu>
            <a href="https://3pinheiros.com.br/">Voltar ao Site </a>
            <a href="https://3pinheiros.com.br/reserva/reserva.html">
              Faça sua Reserva!
            </a>
          </S.MineMenu>
          <S.TopBar />
          <S.GroupBg>
            <S.Bg />
            <h1>
              Páscoa: o que está
              <br />
              incluso no pacote.
            </h1>
          </S.GroupBg>
          <S.MainGroup>
            <S.GroupInfo>
              <S.OrnamentOne />
              <S.OrnamentTwo />
              <S.Items>
                <div>
                  <p className="title">Programação de Páscoa</p>
                </div>
                <div>
                  <span>🍴</span>
                  <p>Pensão Completa</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Café da Manhã</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Almoço</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Chá da Tarde</p>
                </div>
              </S.Items>
              <S.Items className="divisor">
                <div>
                  <span>✅</span>
                  <p>Jantar</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>😎 </span>
                  <p>Caça aos ovos de Páscoa</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Música ao vivo todos os dias</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Beach Tennis</p>
                </div>
              </S.Items>
              <S.Items className="divisor">
                <div>
                  <span>✅</span>
                  <p>Pintura em ovos</p>
                </div>
              </S.Items>

              <S.Items>
                <div>
                  <span>💥</span>
                  <p>APROVEITE TAMBÉM</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Recreação com programação infantil e adulta</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Musica ao vivo todos os dias</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>
                    Passeio à Fazendinha com criações de diversos animais,
                    passeio de charrete de pônei e búfalo
                  </p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>
                    quadras de beach tennis, vôlei, basquete, tênis e campo de
                    futebol
                  </p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Piscinas climatizadas</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>
                    Esporte Bar com Sinuca, boliche, baralho, dominó Música ao
                    vivo
                  </p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Futebol de Sabão</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Escorrega inflável</p>
                </div>
              </S.Items>

              <S.Items>
                <S.Warning>
                  <span>
                    📢 Programação sujeita a alteração sem prévio aviso.
                  </span>
                </S.Warning>
              </S.Items>

              <S.CallToAction href="/reserva/reserva.html">
                Faça já sua reserva!
              </S.CallToAction>
              <S.Footer>
                <div className="l_left">
                  <S.LogoFooter />
                </div>
                <div className="l_right">
                  <p className="bold">(24) 2108 1000</p>
                  <p className="bold last">3pinheiros.com.br</p>
                  <p>Rod. Rio-Caxambu. Km23,</p>
                  <p>Engenheiro Passos/RJ</p>
                </div>
              </S.Footer>
            </S.GroupInfo>
          </S.MainGroup>
        </S.Content>
      </S.Container>
    </>
  );
}
export default HolyContent;
