import styled from "styled-components";

export const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  min-height: 200px;
  background: #dfbda7;
  padding-bottom: 100px;
  @media only screen and (max-width: 1100px) {
    padding: 0;
    margin: 0;
    background: none;
  }
`;

export const AreaMenu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
`;

export const GroupContent = styled.div`
  display: flex;
  width: 100%;
  max-width: 1100px;
  min-height: 200px;
  margin: 180px auto 0 auto;
  flex: 1;
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
    margin: 100px auto 0 auto;
  }
`;

export const LeftPannel = styled.div`
  position: relative;
  width: 65%;
  height: auto;
  padding: 0;
  img {
    width: 100%;
    height: 100%;
    @media only screen and (max-width: 1100px) {
      height: 70vh;
      margin: 0;
      padding: 0;
    }
  }
  div.textMobile {
    display: none;
    @media only screen and (max-width: 1100px) {
      position: absolute;
      top: 15%;
      left: 50%;
      transform: translate(-50%, 0);
      display: flex;
      flex-direction: column;
      background: rgba(0, 0, 0, 0.5);
      width: 80%;
      align-items: center;
      padding: 30px;
      @media only screen and (max-width: 430px) {
        top: 5%;
      }

      p {
        color: #fff;
        margin: 20px 0;
        width: 90%;
        max-width: 400px;
        text-align: center;
        font-family: "GilroyRegular";
        font-size: 21px;
        @media only screen and (max-width: 400px) {
          font-size: medium;
        }
      }

      h2.orange {
        font-family: "LibreBaskervilleItalic";
        font-size: 55px;
        letter-spacing: -1px;
        color: #fe7422;
        line-height: 1em;
        text-align: center;
        @media only screen and (max-width: 400px) {
          font-size: xx-large;
        }
      }
      h2.white {
        font-family: "GilroySemiBold";
        font-size: 45px;
        letter-spacing: -1px;
        color: #fff;
        line-height: 1em;
        text-align: center;
        @media only screen and (max-width: 400px) {
          font-size: xx-large;
        }
      }
    }
  }
  @media only screen and (max-width: 1100px) {
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: center;
  }
`;
export const RightPannel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: 55%;

  padding: 0 20px;
  height: auto;
  p {
    font-family: "GilroyRegular";
    font-size: 21px;
    color: #fff;
    max-width: 250px;
    line-height: 1em;
    letter-spacing: 1px;
    margin-bottom: 20px;
    @media only screen and (max-width: 1100px) {
      width: 90%;
      max-width: 400px;
      font-size: 22px;
      text-align: center;
    }
  }
  @media only screen and (max-width: 1100px) {
    display: none;
  }
`;

export const CallToAction = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width:90%;
  max-width:250px;
  background: #FE7422
  padding: 10px 15px;
  color:#fff;
  font-family: "GilroySemiBold";
  font-size: 18px;
  cursor:pointer;
  margin:0;

`;

export const GroupTitle = styled.div`
  position: absolute;
  top: 20px;
  left: -50px;
  display: flex;
  flex-direction: column;
  width: 100%;

  min-height: 30px;

  h2.orange {
    font-family: "LibreBaskervilleItalic";
    font-size: 46px;
    letter-spacing: -1px;
    color: #fe7422;
    line-height: 1em;
    max-width: 250px;
  }
  h2.white {
    font-family: "GilroySemiBold";
    font-size: 35px;
    letter-spacing: -1px;
    color: #fff;
    line-height: 1em;
  }
  @media only screen and (max-width: 1100px) {
    display: none;
  }
`;
