import React, { useState } from "react";
import ReactMapGL, { Marker } from "react-map-gl";
import { FaMapMarkerAlt } from "react-icons/fa";
import * as S from "./styles";
import pointer from "../../assets/icones/local.png";

import desktopMap from "../../assets/image2-maps.jpg";
import mobileMap from "../../assets/image-mobile-maps.jpg";

function ComoChegar() {
  // const TOKEN =
  //   "pk.eyJ1IjoiZm9jb2NvbXVuaWNhY2FvIiwiYSI6ImNscmhycXc2eTAyOHUya212YzNsaTdpd3cifQ.8R2U7He5IceTb5AaJPJAQQ";
  const [viewport, setViewport] = useState({
    width: 400,
    height: 400,
    latitude: -22.4935219, // Latitude da localização
    longitude: -44.6893513, // Longitude da localização
    zoom: 12,
  });
  return (
    <S.Container>
      <div className="orange">
        <img className="point" src={pointer} alt="Local" />
        <S.Title>Como Chegar</S.Title>
        <S.subtitle>
          Rodovia Rio-Caxambu, Km 23,
          <br />
          Engenheiro Passos - RJ
        </S.subtitle>
        <S.Separator></S.Separator>
        <S.GroupCards>
          <S.CardM>
            <span className="black">
              Saindo do
              <br />
              Rio de Janeiro
            </span>
            <span className="white">
              Cerca de 190Km
              <br />
              02h30 de viagem
            </span>
          </S.CardM>
          <S.CardM>
            <span className="black">
              Saindo de
              <br />
              São Paulo,SP
            </span>
            <span className="white">
              Cerca de 250Km
              <br />
              03h00 de viagem
            </span>
          </S.CardM>
          <S.CardM>
            <span className="black">
              Saindo de
              <br />
              Pouso Alegre, MG
            </span>
            <span className="white">
              Cerca de 190Km
              <br />
              03h00 de viagem
            </span>
          </S.CardM>
        </S.GroupCards>
        <S.Helico>
          Coordenadas de vôo para pouso de helicóptero no hotel
          <br />
          (22º 29.6815U 44º 41* 407W)
        </S.Helico>
      </div>

      <div className="map">
        {/* <ReactMapGL
          {...viewport}
          width="100%"
          heigh={100}
          mapboxApiAccessToken={TOKEN}
          mapStyle="mapbox://styles/fococomunicacao/cjxup1pyp0rs31ctcpixkv3nk"
          attributionControl={false}
          onViewportChange={setViewport}>
          <Marker
            latitude={-22.4935219}
            longitude={-44.6893513}
            offsetLeft={-20}
            offsetTop={-10}>
            <FaMapMarkerAlt size={48} color="#ED8C2B" />
          </Marker>
        </ReactMapGL> */}
        <a target="_blank" href="https://maps.app.goo.gl/g7ncj9bjyiQaa9239">
          <S.GoogleMapDesktop src={desktopMap} alt="Mapa" />
        </a>
      </div>

      {/* -----------map mobile----------  */}
      <div className="mapMobile">
        {/* <ReactMapGL
          {...viewport}
          width="100%"
          heigh={100}
          mapboxApiAccessToken={TOKEN}
          mapStyle="mapbox://styles/fococomunicacao/cjxup1pyp0rs31ctcpixkv3nk"
          attributionControl={false}
          onViewportChange={setViewport}>
          <Marker
            latitude={-22.4935219}
            longitude={-44.6893513}
            offsetLeft={-20}
            offsetTop={-10}>
            <FaMapMarkerAlt size={48} color="#ED8C2B" />
          </Marker>
        </ReactMapGL> */}
        <a target="_blank" href="https://maps.app.goo.gl/g7ncj9bjyiQaa9239">
          <S.GoogleMapMobile src={mobileMap} alt="Mapa" />
        </a>
      </div>
    </S.Container>
  );
}

export default ComoChegar;
