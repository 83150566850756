import React from "react";
import {
  Container,
  TopoInterna,
  Top,
  Titulo,
  SubTitulo,
  Descricao,
  BGG01,
  BGG02,
  BGG03,
} from "./styles";

function CompontentTopoInterna({
  titulo,
  titulo2,
  texto,
  subtitulo,
  bgColor,
  fontColor,
  displayImgBG,
}) {
  return (
    <TopoInterna
      bgColor={bgColor}
      className="wow fadeIn"
      data-wow-delay="450ms">
      <Container>
        <Top>
          <BGG01
            displayImgBG={displayImgBG}
            className="wow fadeIn"
            data-wow-delay="550ms"
          />
          <BGG02
            displayImgBG={displayImgBG}
            className="wow fadeIn"
            data-wow-delay="750ms"
          />
          <BGG03
            displayImgBG={displayImgBG}
            className="wow fadeIn"
            data-wow-delay="850ms"
          />

          <Titulo
            className="wow fadeIn"
            data-wow-delay="550ms"
            fontColor={fontColor}>
            {titulo}
            <span>{titulo2}</span>
          </Titulo>
          <SubTitulo className="wow fadeIn" data-wow-delay="650ms">
            {subtitulo}
          </SubTitulo>
          <Descricao
            className="wow fadeIn"
            data-wow-delay="850ms"
            fontColor={fontColor}>
            {texto}
          </Descricao>
        </Top>
      </Container>
    </TopoInterna>
  );
}

export default CompontentTopoInterna;
