import React from "react";

// import Off20 from "../../assets/ate-20-off.png";
// import Off40 from "../../assets/ate-40-off.png";
// import Box04 from "../../assets/box-04.png";
// import Off50 from "../../assets/ate-50-off.png";

import Box22 from "../../assets/ferias-2024.jpg";
import Box24 from "../../assets/box-24.png";
import Box18 from "../../assets/pascoa-2024.jpg";

import {
  Box,
  BoxBtn,
  BoxSubTitle,
  BoxTitle,
  Container,
  Content,
  TextLegal,
  ButtonCadastro,
  Title,
  TitleBold,
  MoreInfo,
} from "./styles";

export default function Pacotes() {
  return (
    <Container>
      <Title>
        Pacotes com descontos <TitleBold>por tempo limitado</TitleBold>
      </Title>
      <Content>
        <Box className="wow fadeIn" data-wow-delay="300ms">
          {/* <img className="selo" src={Off40} alt="Selo" /> */}
          {/* <img className="selo" src={Off20} alt="Selo" /> */}
          <img src={Box22} alt="Box" />
          <BoxTitle>Dias de semana com desconto</BoxTitle>
          <BoxSubTitle>
            Aproveite nossas promoções para dias de semana e relaxe em nosso
            Resort Fazenda.
          </BoxSubTitle>
          <MoreInfo href="pacotes/dia-de-semana">
            O que tem neste pacote
          </MoreInfo>

          <BoxBtn href="http://book.omnibees.com/hotel/6628" target="_blank">
            Comprar pacote
          </BoxBtn>
        </Box>
        <Box className="wow fadeIn" data-wow-delay="400ms">
          {/* <img className="selo" src={Off20} alt="Selo" /> */}
          <img src={Box18} alt="Carnaval" />
          <BoxTitle>Aproveite férias com a gente</BoxTitle>
          <BoxSubTitle>
            Garanta o seu lugar no melhor Resort Fazenda..
          </BoxSubTitle>
          <MoreInfo href="pacotes/pascoa">O que tem neste pacote</MoreInfo>
          <BoxBtn href="http://book.omnibees.com/hotel/6628" target="_blank">
            Comprar pacote
          </BoxBtn>
        </Box>
        <Box className="wow fadeIn" data-wow-delay="400ms">
          {/* <img className="selo" src={Off20} alt="Selo" /> */}
          <img src={Box24} alt="Box" />
          <BoxTitle>Vem aí o Arraiá do 3 Pinheiros.</BoxTitle>
          <BoxSubTitle>
            A mais tradicional festa junina do estado do Rio.
          </BoxSubTitle>
          <MoreInfo href="pacotes/festa-junina">
            O que tem neste pacote
          </MoreInfo>
          <BoxBtn href="http://book.omnibees.com/hotel/6628" target="_blank">
            Comprar pacote
          </BoxBtn>
        </Box>
      </Content>
      <ButtonCadastro
        target="_blank"
        href="http://3pinheiros.com.br/cadastro/index.html">
        Cadastre-se e fique por dentro de nossas promoções e sorteios
      </ButtonCadastro>
      {/* <TextLegal>
        *Válido apenas para baixa temporada. Não é válido para feriados.
      </TextLegal> */}
      <Content />
    </Container>
  );
}
