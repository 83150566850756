import React from "react";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";

import * as S from "./styles";

function FloatMenuBar() {
  return (
    <>
      <S.Container>
        <S.Bar>
          {/* <S.Reserva href="reserva/reserva.html" target="_blank">
            Reserve Agora
          </S.Reserva> */}
          <RouterLink to="/aviso-reserva" className="reserva">
            Reserve Já!
          </RouterLink>
          <S.GroupText>
            <Link
              className="link"
              to="tour"
              spy={true}
              smooth={true}
              offset={50}
              duration={1000}>
              <S.TextNormal>Conheça nosso</S.TextNormal>
              <S.TextTour>Tour</S.TextTour>
              <S.Bold360>360º</S.Bold360>
              <S.TextNormal>
                &nbsp;&nbsp;
                <i class="fa-solid fa-eye"></i>
              </S.TextNormal>
            </Link>
          </S.GroupText>
        </S.Bar>
      </S.Container>
    </>
  );
}
export default FloatMenuBar;
