import React from "react";
import { Helmet } from "react-helmet";
import BannerCasamentos from "../../components/BannerCasamentos";
import ComoChegar from "../../components/ComoChegar";
import Informacoes from "../../components/Informacoes";
import ImageEstrutura1 from "../../assets/estrutura/estrurura-home-01.jpg";
import ImageEstrutura2 from "../../assets/estrutura/estrurura-home-02.jpg";
import ImageEstrutura3 from "../../assets/estrutura/estrurura-home-03.jpg";
import ImageEstrutura4 from "../../assets/estrutura/estrurura-home-04.jpg";
import ImageEstrutura5 from "../../assets/estrutura/estrurura-home-05.jpg";
import ImageEstrutura6 from "../../assets/estrutura/estrurura-home-06.jpg";
import ImageEstrutura7 from "../../assets/estrutura/estrurura-home-07.jpg";
import ImageEstrutura8 from "../../assets/estrutura/estrurura-home-08.jpg";
import Estrutura from "../../components/Estruturas";
import EventosHotel from "../../components/EventosHotel";

import * as S from "./styles";

const Casamentos = () => {
  const datasTopo = {
    titulo: "Nossa",
    titulo2: "estrutura",
    descricao:
      "Natureza exuberante e diversos ambientes de lazer, esporte e diversão para toda a família curtir e relaxar.",
    colorTitutlo: "#392C23",
    backgroundColorTitulo: "#fff",
    fontSizeTitulo: "62px",
    fontSizeTitulo2: "52px",
    colorDescricao: "#585858",
    fontSizeDescricao: "22px",
    paddingTopTP: "70px",
  };

  const datasImagens = [
    { id: 1, imagem: ImageEstrutura1 },
    { id: 2, imagem: ImageEstrutura2 },
    { id: 3, imagem: ImageEstrutura3 },
    { id: 4, imagem: ImageEstrutura4 },
    { id: 5, imagem: ImageEstrutura5 },
    { id: 6, imagem: ImageEstrutura6 },
    { id: 7, imagem: ImageEstrutura7 },
    { id: 8, imagem: ImageEstrutura8 },
  ];

  return (
    <S.Container>
      <Helmet>
        <title>
          Casamentos - Realize o casamento dos sonhos no Resort 3 Pinheiros
        </title>
      </Helmet>
      <BannerCasamentos />
      <EventosHotel />

      <ComoChegar bgColorMobile="#FF7E01" />
      <Informacoes />
    </S.Container>
  );
};
export default Casamentos;
