import styled from "styled-components";

export const Container = styled.section`
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  height: auto;
  margin: 0 auto;
`;

export const GroupEstrutura = styled.div`
  width: 100%;
  max-width: 1100px;
  height: auto;
  margin: 0 auto;
`;
