import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 16px;
  margin-bottom: 24px;

  &:last-child {
    margin-right: 0;
  }

  label {
    font-family: "GilroyBold", sans-serif;
    font-size: 16px;
    color: #fff;
    margin-bottom: 8px;
  }

  span.danger {
    font-size: 14px;
    font-family: "GilroyBold";
    font-weight: bold;
    color: #ffff00;
    margin: 4px 0;
  }
`;
