import React from "react";
import { Helmet } from "react-helmet";

import Menu from "../../components/Menu";
import ProgCarnaval from "../../components/Carnaval";
import NewReserva from "../../components/NewReserva";
import Informacoes from "../../components/Informacoes";

import * as S from "./styles";

function ProgramacaoCarnaval() {
  return (
    <S.Container>
      <Helmet>
        <title>Gastronomia - Resort Fazenda 3 Pinheiros</title>
      </Helmet>
      <Menu />
      <NewReserva />
      <ProgCarnaval />
     
      <Informacoes />
    </S.Container>
  );
}

export default ProgramacaoCarnaval;
