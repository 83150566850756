import React from "react";
import * as S from "./styles";

function HolyContent() {
  return (
    <>
      <S.Container>
        <S.Content>
          <S.MineMenu>
            <a href="https://3pinheiros.com.br/">Voltar ao Site </a>
            <a href="https://3pinheiros.com.br/reserva/reserva.html">
              Faça sua Reserva!
            </a>
          </S.MineMenu>
          <S.TopBar />
          <S.GroupBg>
            <S.Bg />
            <h1>
              Feriadão: o que está
              <br />
              incluso no pacote.
            </h1>
          </S.GroupBg>
          <S.MainGroup>
            <S.GroupInfo>
              <S.OrnamentOne />
              <S.OrnamentTwo />
              <S.Items>
                <div>
                  <p className="title">Programação Feriadão</p>
                </div>
                <div>
                  <span>🍴</span>
                  <p>Pensão Completa</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Café da Manhã</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Almoço</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Chá da Tarde</p>
                </div>
              </S.Items>
              <S.Items className="divisor">
                <div>
                  <span>✅</span>
                  <p>Jantar</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>😎 </span>
                  <p>Lazer para toda a família</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Recreação Adulto e Infantil</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Spa com Piscina Aquecida e Saunas</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Piscina externa com Toboágua e Piscina Kids </p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Visita a fazendinha com ordenha da vaquinha</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Passeio de carro de boi</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Trilha até cascatinha</p>
                </div>
              </S.Items>
              <S.Items className="divisor">
                <div>
                  <span>✅</span>
                  <p>Montaria no Búfalo</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>💥</span>
                  <p>Atividades extras</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Passeio a Cavalo, Charrete e pônei</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Passeio radical de quadriciclo</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Arvorismos adulto e infantil</p>
                </div>
              </S.Items>
              <S.Items>
                <div>
                  <span>✅</span>
                  <p>Tirolesa</p>
                </div>
              </S.Items>
              <S.Items className="divisor">
                <div>
                  <span>✅</span>
                  <p>Massagens relaxantes no Spa</p>
                </div>
              </S.Items>

              <S.Items>
                <S.Warning>
                  <span>
                    📢 Programação sujeita a alteração sem prévio aviso.
                  </span>
                </S.Warning>
              </S.Items>

              <S.CallToAction href="/reserva/reserva.html">
                Faça já sua reserva!
              </S.CallToAction>
              <S.Footer>
                <div className="l_left">
                  <S.LogoFooter />
                </div>
                <div className="l_right">
                  <p className="bold">(24) 2108 1000</p>
                  <p className="bold last">3pinheiros.com.br</p>
                  <p>Rod. Rio-Caxambu. Km23,</p>
                  <p>Engenheiro Passos/RJ</p>
                </div>
              </S.Footer>
            </S.GroupInfo>
          </S.MainGroup>
        </S.Content>
      </S.Container>
    </>
  );
}
export default HolyContent;
