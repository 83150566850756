import React from "react";
import ReactGA from "react-ga";
import { Switch, Route } from "react-router-dom";

import Home from "../pages/Home";
import Hotel from "../pages/Hotel";
import Lazer from "../pages/Lazer";
import Eventos from "../pages/Eventos";
import Local from "../pages/Local";
import Acomodacoes from "../pages/Acomodacoes";
import Fotos from "../pages/Fotos";
import Gastronomia from "../pages/Gastronomia";
import Diretrizes from "../pages/Diretrizes";
import Carnaval from "../pages/ProgramacaoCarnaval";
import Funcionamento from "../pages/Funcionamento";
import TrabalheConosco from "../pages/TrabalheConosco";
import XmasContent from "../components/PacksContent/xmas";
import HolyContent from "../components/PacksContent/holiday";
import WeekContent from "../components/PacksContent/weekday";
import EasterContent from "../components/PacksContent/easter";
import JulyContent from "../components/PacksContent/julyParty";
import ReveillonContent from "../components/PacksContent/reveillon";
import Warning from "../pages/Aviso_Reserva";

import Casamentos from "../pages/Casamentos";
import Spa from "../pages/Spa";

export default function Routes({ history }) {
  ReactGA.pageview(history.location.pathname);
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/hotel" exact component={Hotel} />
      <Route path="/lazer" exact component={Lazer} />
      <Route path="/eventos" exact component={Eventos} />
      <Route path="/funcionamento" exact component={Funcionamento} />
      <Route path="/local" exact component={Local} />
      <Route path="/acomodacoes" exact component={Acomodacoes} />
      <Route path="/fotos" exact component={Fotos} />
      <Route path="/fotos/:galleryId" exact component={Fotos} />
      <Route path="/gastronomia" exact component={Gastronomia} />
      <Route path="/novas-diretrizes" exact component={Diretrizes} />
      <Route path="/programacao-carnaval" exact component={Carnaval} />
      <Route path="/trabalhe-conosco" exact component={TrabalheConosco} />
      <Route path="/casamentos" exact component={Casamentos} />
      <Route path="/pacotes/natal" exact component={XmasContent} />
      <Route path="/pacotes/feriados" exact component={HolyContent} />
      <Route path="/pacotes/pascoa" exact component={EasterContent} />
      <Route path="/pacotes/festa-junina" exact component={JulyContent} />
      <Route path="/pacotes/dia-de-semana" exact component={WeekContent} />
      <Route path="/pacotes/reveillon" exact component={ReveillonContent} />
      <Route path="/spa" exact component={Spa} />
      <Route path="/aviso-reserva" exact component={Warning} />
    </Switch>
  );
}
