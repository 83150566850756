import React from "react";
import { Button } from "./styles";

function CompontentEstruturaButton({
  Url,
  displayButton,
  buttonText,
  buttonBg,
  buttonTextColor,
}) {
  return (
    <Button
      href={Url}
      displayButton={displayButton}
      buttonBg={buttonBg}
      buttonTextColor={buttonTextColor}>
      <button>{buttonText ? buttonText : `Ver mais fotos`}</button>
    </Button>
  );
}

export default CompontentEstruturaButton;
