import styled from "styled-components";

import BGtopo from "../../assets/gastronomia/producao/bg_topo.png";
import BGNav from "../../assets/icones/bg_estrutura_top.png";
import ImagemBtnFecharModal from "../../assets/icones/btn_fechar_modal2.svg";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 1127px;

  @media only screen and (max-width: 1280px) {
    width: 90%;
  }
`;

export const Estrutura = styled.div`
  padding-bottom: 90px;
`;

export const ConteudoMobile = styled.div`
  display: inline-block;
  width: 100%;
  text-align: center;

  .swiper-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Conteudo = styled.div`
  display: inline-block;
  width: 100%;
  padding-top: 43px;

  img {
    cursor: pointer;
    width: 275px;
    height: 314px;
    object-fit: cover;
  }

  div {
    float: left;
    padding-right: 9px;
    margin-bottom: -4px;
    padding-bottom: 9px;
  }

  div:nth-child(4n) {
    padding-right: 0px;
  }

  @media only screen and (max-width: 1250px) {
    width: 844px;
    margin: auto;
    div:nth-child(3n) {
      padding-right: 0px;
    }
    div:nth-child(4n) {
      padding-right: 9px;
    }
  }

  @media only screen and (max-width: 900px) {
    width: 568px;
    div:nth-child(3n) {
      padding-right: 9px;
    }
    div:nth-child(2n) {
      padding-right: 0px;
    }
  }

  @media only screen and (max-width: 600px) {
    width: 100%;

    div {
      text-align: center;
      margin: auto;
      display: inline-block;
      padding-right: 0px;
      width: 100%;
      padding: 0px;
      margin-bottom: 15px;
    }

    img {
      width: 70%;
    }

    div:nth-child(3n) {
      padding-right: 0px;
    }
  }

  @media only screen and (max-width: 321px) {
    img {
      width: 75%;
    }
  }
`;

export const BtnMaisFotos = styled.button`
  ${props => `display: ${props.displayButton}`};
`;

export const Button = styled.a`
  display: inline-block;
  width: 100%;
  margin-top: 58px;
  button {
    cursor: pointer;
    font-size: 24px;
    margin-top: 20px;
    color: #fff;
    ${props => `color: ${props.buttonTextColor}`};
    height: 62px;
    display: block;
    background-color: #392c23;
    ${props => `background-color: ${props.buttonBg}`};
    border: none;
    padding: 19px 40px;
    margin: auto;
    ${props => `display: ${props.displayButton}`};
    /* display: none; */
  }

  span {
    font-size: 20px;
    font-family: "GilroyBold", sans-serif;
  }

  @media only screen and (max-width: 900px) {
    margin-top: 33px;
    button {
      font-size: 20px;
      margin-top: 0px;
      color: #fff;
      height: 55px;
      padding: 13px 35px;
    }
  }

  @media only screen and (max-width: 600px) {
    button {
      font-size: 18px;
      height: 49px;
      padding: 13px 35px;
    }
  }

  @media only screen and (max-width: 426px) {
    button {
      font-size: 16px;
    }
    span {
      font-size: 17px;
    }
  }
`;

export const Producao = styled.div`
  display: none;
  ${props => `display: ${props.displayBGTop}`};

  div {
    display: inline-block;
    float: left;
    width: 316px;
    margin-right: 57px;
  }

  p {
    margin-top: 20px;
    color: #6f6d6d;
    line-height: 26px;
    font-size: 20px;
  }

  span {
    background-image: url(${BGtopo});
    background-repeat: no-repeat;
    width: 237px;
    height: 30px;
    background-size: 237px 30px;
    position: relative;
    z-index: 2;
    display: block;
    margin: auto;
    margin-bottom: 48px;
    margin-top: 13px;
  }

  @media only screen and (max-width: 1300px) {
    div {
      width: 30%;
      margin-right: 3%;
    }
  }

  @media only screen and (max-width: 900px) {
    div {
      width: 100%;
      margin-right: 0%;
      text-align: center;
      margin-bottom: 30px;
    }

    span {
      margin-bottom: 25px;
    }
  }

  @media only screen and (max-width: 376px) {
    p {
      line-height: 23px;
      font-size: 18px;
    }
  }
`;

export const MenuNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto;
  background: #f9f9f9;
  border: 1px solid #c5c5c5;
  width: 90%;
  padding: 10px;
  max-width: 900px;
  height: auto;
  margin-top: 50px;
  border-radius: 10px;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    column-gap: 20px;
    @media only screen and (max-width: 540px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  li {
    max-width: 200px;
    width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: left;
    padding: 5px 0;
    height: 40px;
  }

  a {
    display: block;
    padding: 15px;
    cursor: pointer;
    font-family: "GilroyBold", sans-serif;
    font-size: 16px;
    color: #000;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 820px) {
    width: 100%;
    height: auto;
    background-size: 100% 50px;
  }

  @media only screen and (max-width: 700px) {
    background-image: none;
    background-color: #f5f5f4;
    border-top: 1px solid #ddc7c7;
    border-bottom: 1px solid #ddc7c7;
    padding: 10px 0px 10px 0px;
    border-radius: 14px;
    border: 1px solid #ddc7c7;

    a {
      padding: 10px;
      font-size: 15px;
    }
  }

  @media only screen and (max-width: 426px) {
    a {
      padding: 8px;
      font-size: 14px;
    }
  }

  @media only screen and (max-width: 321px) {
    padding: 7px 0px 7px 0px;

    a {
      padding: 8px;
      font-size: 14px;
    }
  }
`;

export const Modal = styled.div`
  z-index: 97;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 48px;
  ${props => props.show && "display: flex;"}

  div {
    padding-right: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    height: auto;
  }

  img {
    width: 100%;
    height: auto;
  }

  @media only screen and (max-width: 1100px) {
    padding: 20px;
  }
`;

export const Box = styled.div`
  display: inline-block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #262c40;
  width: 697px;
  height: 522.75px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.5);
  position: relative;
  padding-right: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;

  img {
    width: 100%;
  }

  @media only screen and (max-width: 1100px) {
    width: 95%;
    height: auto;
  }
`;

export const CloseButton = styled.button`
  background-image: url(${ImagemBtnFecharModal});
  background-repeat: no-repeat;
  width: 28px;
  height: 28px;
  position: absolute;
  background-size: 28px 28px;
  right: -10px;
  top: -9px;
  z-index: 1;
  transition: 300ms ease;
  font-size: 18px;
  &:active {
    transform: scale(0.9);
  }
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

export const ImagesMobile = styled.div`
  margin-top: 30px;
  display: none;
  @media only screen and (max-width: 650px) {
    display: block;
  }
`;

export const ImagesDesktop = styled.div`
  display: inline-block;
  width: max-content;
  max-width: 100%;
  text-align: center;
  margin: 0 auto;

  @media only screen and (max-width: 650px) {
    display: none;
  }
`;

export const Imagem = styled.div`
  display: block !important;
  width: 100% !important;
  margin: 0 !important;

  .video-react {
    padding: 0 !important;
  }
  .video-react-video {
    width: 100%;
  }
  .video-react-poster {
    width: 100%;
  }
  .video-react.video-react-fluid,
  .video-react.video-react-16-9,
  .video-react.video-react-4-3 {
    min-height: 380px !important;
    max-width: 800px !important;
    margin: 0 auto 68px auto;
    float: none !important;
    display: block;
  }
  .video-react-time-divider span {
    display: none;
  }

  @media only screen and (max-width: 797px) {
    .video-react.video-react-fluid,
    .video-react.video-react-16-9,
    .video-react.video-react-4-3 {
      min-height: 300px !important;
    }
  }
`;

export const InfoPhotos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  min-height: 30px;
  margin: 50px auto -10px auto;
  h2 {
    font-family: "LibreBaskervilleItalic";
    font-size: xx-large;
    color: #000;
  }
  p {
    font-family: "BaskervilleRegular", sans-serif;
    font-size: 18px;
    color: #000;
    max-width: 500px;
    text-align: center;
  }
`;
