import React, { useEffect } from "react";
import WOW from "wowjs";
import { Helmet } from "react-helmet";

import TopInterna from "../../components/TopoInterna";
import Estrutura from "../../components/Estruturas";
import Informacoes from "../../components/Informacoes";
import Menu from "../../components/Menu";
import Festas from "../../components/Festas";
import NewReserva from "../../components/NewReserva";
import ReservaMobile from "../../components/Reserva/mobile";

import ImageSpa1 from "../../assets/estrutura/spa/spa_01.jpg";
import ImageSpa2 from "../../assets/estrutura/spa/spa_02.jpg";
import ImageSpa3 from "../../assets/estrutura/spa/spa_03.jpg";
import ImageSpa4 from "../../assets/estrutura/spa/spa_04.jpg";
import ImageSpa5 from "../../assets/estrutura/spa/spa_05.jpg";
import ImageSpa6 from "../../assets/estrutura/spa/spa_06.jpg";
import ImageSpa7 from "../../assets/estrutura/spa/spa_07.jpg";
import ImageSpa8 from "../../assets/estrutura/spa/spa_08.jpg";
import ImageSpa9 from "../../assets/estrutura/spa/spa_09.jpg";
import ImageSpa10 from "../../assets/estrutura/spa/spa_10.jpg";
import ImageSpa11 from "../../assets/estrutura/spa/spa_11.jpg";
import ImageSpa12 from "../../assets/estrutura/spa/spa_12.jpg";

export default function PaginaSpa() {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const datasTopo = {
    titulo: "Moderno",
    titulo2: "Centro de lazer",
    descricao: "",
    colorTitutlo: "#392C23",
    backgroundColorTitulo: "#fff",
    fontSizeTitulo: "62px",
    fontSizeTitulo2: "52px",
    colorDescricao: "#585858",
    fontSizeDescricao: "22px",
    paddingTopTP: "81px",
    displayButton: "none",
  };

  const datasImagens = [
    { id: 1, imagem: ImageSpa1 },
    { id: 2, imagem: ImageSpa2 },
    { id: 3, imagem: ImageSpa3 },
    { id: 4, imagem: ImageSpa4 },
    { id: 5, imagem: ImageSpa5 },
    { id: 6, imagem: ImageSpa6 },
    { id: 7, imagem: ImageSpa7 },
    { id: 8, imagem: ImageSpa8 },
    { id: 9, imagem: ImageSpa9 },
    { id: 10, imagem: ImageSpa10 },
    { id: 11, imagem: ImageSpa11 },
    { id: 12, imagem: ImageSpa12 },
  ];

  return (
    <>
      <Helmet>
        <title>Spa - Resort Fazenda 3 Pinheiros</title>
      </Helmet>
      <Menu />
      <NewReserva />
      <TopInterna
        titulo="SPA"
        titulo2="3 Pinheiros"
        subtitulo="lazer"
        bgColor="#262C40"
        fontColor="#fff"
        texto="O Resort Fazenda 3 Pinheiros oferece a seus hóspedes um moderno centro de lazer e spa, com piscinas aquecidas, saunas secas e a vapor, área de descanso, sala de massagem além de excelentes aparelhos de musculação para aqueles que desejam manter a forma, num ambiente descontraído, aconchegante e com vista para a natureza que circunda o Resort."
      />
      <ReservaMobile bgColor="#262C40" />
      <Estrutura
        datasTopo={datasTopo}
        datasImagens={datasImagens}
        datasButton=""
      />
      <Informacoes />
    </>
  );
}
