import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1920px;
  margin: auto;
  padding: 64px 32px;
  background-color: #fff6f2;

  img {
    margin-right: 32px;
  }
`;
export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 342px;
  justify-content: center;
  align-items: center;

  p {
    font-family: "BaskervilleBT";
    font-size: 20px;
    line-height: 20px;
    color: #585858;
  }

  img {
    margin-right: 35px;
    transform: translateY(32px);
  }

  h3 {
    width: 100%;
    font-family: "GilroyBold";
    font-size: 30px;
    line-height: 30px;
    color: #0fa592;
    margin-bottom: 16px;
  }

  @media (max-width: 788px) {
    max-width: 368px;
    img {
      display: none;
    }

    h3 {
      font-size: 27px;
    }
  }
`;
