import styled from "styled-components";

import ImagemBtnFecharModal from "../../assets/icones/btn_fechar_modal2.svg";
import ImagemButtonNext from "../../assets/icones/seta_direita.png";
import ImagemButtonPrev from "../../assets/icones/seta_esquerda.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 1100px;

  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const Conteudo = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 48px;
  margin: auto;

  img {
    width: 100%;
  }

  div {
    margin-right: 32px;
    text-align: left;
    display: inline-block;
    max-width: 345px;
    margin-bottom: 50px;
    float: left;
  }

  div:nth-child(3n) {
    margin-right: 0px;
  }

  p {
    font-size: 22px;
    color: #585858;
  }

  @media only screen and (max-width: 1100px) {
    text-align: center;
    div:nth-child(3n) {
      margin-right: 32px;
    }
    div {
      margin-bottom: 30px;
    }
  }

  @media only screen and (max-width: 801px) {
    width: 375px;
  }

  @media only screen and (max-width: 691px) {
    margin-top: 0px;

    div {
      margin-right: 0px;
    }

    img {
      width: 100%;
    }

    div:nth-child(3n) {
      margin-right: 0px;
    }
  }

  @media only screen and (max-width: 376px) {
    width: 100%;

    div {
      max-width: 90%;
    }
  }
`;

export const Quartos = styled.div`
  padding-top: 90px;
  padding-left: 20px;
  padding-right: 20px;

  @media only screen and (max-width: 1025px) {
    padding-top: 50px;
  }
`;

export const Textos = styled.div`
  margin-top: 21px;
  margin-right: 0 !important;

  h2 {
    font-size: 30px;
    color: #262c40;
    font-weight: 500;
    margin-bottom: 10px;
  }

  h3 {
    font-family: "GilroyBold", sans-serif;
    font-size: 20px;
    color: #ff7e01;
    margin-top: -10px;
  }

  p {
    font-size: 22px;
    line-height: 25px;
    color: #585858;
    margin-top: 20px;
    min-height: 150px;
  }

  button {
    background-color: #262c40;
    padding: 15px 27px;
    color: #fff;
    font-size: 16px;
    border: none;
    font-family: "GilroyBold", sans-serif;
    max-height: 50px;
    max-width: 140px;
    margin-top: 50px;
    cursor: pointer;
  }

  @media only screen and (max-width: 1100px) {
    h2 {
      font-size: 34px;
    }

    h3 {
      font-size: 27px;
    }

    p {
      font-size: 20px;
      line-height: 25px;
      margin-top: 15px;
    }

    button {
      padding: 12px 23px;
      font-size: 17px;
      margin-top: 30px;
    }
  }

  @media only screen and (max-width: 797px) {
    p {
      min-height: auto;
    }
  }

  @media only screen and (max-width: 650px) {
    p {
      font-size: 18px;
      line-height: 22px;
      margin-top: 10px;
    }

    button {
      font-size: 16px;
    }
  }
`;

// Teste Modal

export const Modal = styled.div`
  z-index: 97;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 48px;
  ${props => props.show && "display: flex;"}
  img {
    width: 100%;
    max-height: 576px;
    object-fit: cover;
  }

  @media only screen and (max-width: 1100px) {
    padding: 20px;
  }
`;

export const Box = styled.div`
  display: inline-block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #262c40;
  width: 860px;
  height: auto;
  /* padding: 32px; */
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.5);
  position: relative;

  @media only screen and (max-width: 1100px) {
    width: 95%;
  }
`;

export const Title = styled.h3`
  font-size: 24px;
  color: #fff;
  margin-bottom: 24px;
`;

export const Description = styled.p`
  color: #fff !important;
  font-size: 16px;
  line-height: 24px;
`;

export const CloseButton = styled.button`
  background-image: url(${ImagemBtnFecharModal});
  background-repeat: no-repeat;
  width: 28px;
  height: 28px;
  position: absolute;
  background-size: 28px 28px;
  right: -10px;
  top: -9px;
  z-index: 1;
  transition: 300ms ease;
  font-size: 18px;
  &:active {
    transform: scale(0.9);
  }
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

export const ButtonPrev = styled.button`
  background-image: url(${ImagemButtonPrev});
  background-repeat: no-repeat;
  width: 38px;
  height: 38px;
  background-size: 38px 38px;
  position: absolute;
  z-index: 2;
  display: block;
  top: 40%;
  left: 55px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  @media only screen and (max-width: 650px) {
    display: none;
  }
`;

export const ButtonNext = styled.button`
  background-image: url(${ImagemButtonNext});
  background-repeat: no-repeat;
  width: 38px;
  height: 38px;
  background-size: 38px 38px;
  position: absolute;
  z-index: 2;
  display: block;
  top: 40%;
  right: 55px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  @media only screen and (max-width: 650px) {
    display: none;
  }
`;
