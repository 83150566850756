import Styled from "styled-components";
import firula from "../../assets/firula-laranja.png";

export const Container = Styled.div`
  display:flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  background-color: #262C40;
  padding: 64px 32px;
  align-items: center;

`;

export const Hospedagem = Styled.div``;

export const Firula = Styled.img.attrs({ src: firula })`
  margin: 16px 0;
  width: 100%;
  max-width: max-content;

`;

export const Conteudo = Styled.div`
  margin-top: 21px;
  width: 100%;
  max-width: 620px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 22px;
    text-align: center;
    line-height: 25px;
    color: #fff;
    margin-top: 28px;
  }

  span{

    width: 214px;
    height: 27px;
    background-size: 214px 27px;
    position: relative;
    z-index: 2;
    display: block;   
    margin: auto;
  }


@media only screen and (max-width: 426px) {    

  p {
    font-size: 20px;
    width: 90%;
    line-height: 25px;
    margin: auto;
    margin-top: 20px;
  }
      
}
`;
