import React, { useState } from "react";
import Swiper from "react-id-swiper";
import "react-id-swiper/lib/styles/css/swiper.css";
import {
  ConteudoMobile,
  Modal,
  Box,
  CloseButton,
  ImagesMobile,
} from "./styles";

function CompontentImagensEstrurtura({ ListaImagens }) {
  const [point, setPoint] = useState(0);
  const [open, setOpen] = useState(false);
  const [swiper, updateSwiper] = useState(null);

  const params = {
    Swiper,
    initialSlide: 0,
    pagination: {
      el: ".swiper-pagination",
    },
    spaceBetween: 30,
    getSwiper: updateSwiper,
  };

  function AbrirImagem(id) {
    setPoint(id);
    setOpen(true);
  }

  const RenderListaImagens = ListaImagens.map(todo => (
    <div key={todo.id}>
      <img onClick={() => AbrirImagem(todo.id)} src={todo.imagem} />
    </div>
  ));

  return (
    <ImagesMobile>
      <ConteudoMobile className="wow fadeIn" data-wow-delay="350ms">
        <Swiper {...params}>{RenderListaImagens}</Swiper>
      </ConteudoMobile>
      <Modal show={open}>
        <Box>
          {ListaImagens.map(
            p =>
              p.id === point && (
                <>
                  <img src={p.imagem} />
                </>
              )
          )}
          <CloseButton onClick={() => setOpen(false)} />
        </Box>
      </Modal>
    </ImagesMobile>
  );
}

export default CompontentImagensEstrurtura;
