import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 16px;
  margin-bottom: 24px;

  &:last-child {
    margin-right: 0;
  }

  label {
    font-family: "GilroyBold", sans-serif;
    font-size: 14px;
    color: #000;
    margin-bottom: 8px;
  }

  input {
    width: 100%;
    height: 54px;
    border: 1px #d1bebe solid;
    padding: 16px;
    font-size: 16px;
    color: #000;
    background-color: rgba(255, 255, 255, 0.2);
    font-family: "Open Sans";
    ::placeholder {
      font-size: 14px;
      color: #392c23;
      font-style: Italic;
    }
  }

  input[type="color"] {
    padding: 8px;
    border: none;
    color: transparent;
  }

  span {
    font-size: 14px;
    font-family: "GilroyBold";
    color: #a11010;
    margin: 4px 0;
  }
`;
