import React from "react";
import ReactPlayer from "react-player";
import { Player, BigPlayButton } from "video-react";

import { Sobrenos, Imagem, Textos } from "./styles";
import "../../../node_modules/video-react/dist/video-react.css";
import "./app.css";

import ImagemHotelPlayer from "../../assets/hotel/sobre_hotel_player.png";
import VideoPlayer from "../../assets/videos/video.mp4";

function CompontentSobrenos() {
  return (
    <Sobrenos>
      <Imagem className="wow fadeIn" data-wow-delay="350ms">
        {/* <img src={ImagemHotelPlayer} /> */}
        {/* 
        <ReactPlayer
          url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
          url={VideoPlayer}
          width="100%"
          height="100%"
        /> */}

        <Player
          // playsInline
          poster={ImagemHotelPlayer}
          src={VideoPlayer}
          width="100%"
          height="100%">
          <BigPlayButton display="none" position="center" />
        </Player>
      </Imagem>
      <Textos className="wow fadeInRight" data-wow-delay="350ms">
        <div>
          <h1>
            Resort <span>3 Pinheiros</span>
          </h1>
          <h2>Sobre Nós</h2>

          <p>
            O resort consegue harmoniosamente unir o conforto, o requinte e o
            rústico.
          </p>
        </div>
      </Textos>
    </Sobrenos>
  );
}

export default CompontentSobrenos;
