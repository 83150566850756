import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 1100px;

  @media only screen and (max-width: 1441px) {
    width: 90%;
  }
`;

export const Informacoes = styled.div`
  background-color: #fff;
  padding-top: 60px;
  background-color: #0fa592;
  text-align: center;
  display: inline-block;
  width: 100%;
  h2 {
    color: #fff;
    font-size: 41px;
    font-family: "GilroyBold", sans-serif;
  }

  h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    margin-top: 15px;
  }

  a {
    color: #fff;
    font-family: "GilroyBold", sans-serif;
  }

  @media only screen and (max-width: 1025px) {
    h2 {
      font-size: 36px;
    }
  }

  @media only screen and (max-width: 426px) {
    h2 {
      font-size: 30px;
    }
    h3 {
      font-size: 19px;
    }

    p {
      font-size: 19px;
      width: 80%;
      margin: auto;
      overflow-x: auto;
    }
  }

  @media only screen and (max-width: 321px) {
    p {
      font-size: 17px;
      width: 100%;
    }
  }
`;

export const Reserva = styled.div`
  margin-top: 25px;
  p {
    color: #262c40;
    font-size: 20px;
    line-height: 25px;
  }
`;

export const Button = styled.a`
  margin-top:30px;  
  button {   
    cursor:pointer;  
    font-size: 18px;
    color: #fff;
    display: block;
    background-color: #262C40;
    border: none;
    padding: 15px 20px;  
    margin: auto; 
    font-family: 'GilroyBold', sans-serif;   
  }

  span{
    font-size: 20px;
    font-family: 'GilroyBold', sans-serif;  
  }

  @media only screen and (max-width: 426px) {
    button {
      font-size: 17px;
      padding: 12px 15px;
    }
  }

  @media only screen and (max-width: 321px) {

  p {
    font-size: 18px;
    line-height: 20px;
}
]

`;

export const Curriculo = styled.span`
  display: block;
  font-family: "BaskervilleRegular";
  font-size: 16px;
  margin-top: 60px;
  color: #000;
  letter-spacing: 1px;
  a {
    font-family: "BaskervilleRegular";
    font-size: 16px;
    color: #fff;
    text-decoration: underline;
    letter-spacing: 1px;
  }
`;

export const Desenvolvido = styled.span`
  display: block;
  width: 100%;
  background: #088373;
  font-family: "GilroyRegular";
  font-size: 14px;
  color: #fff;
  letter-spacing: 1px;

  margin-top: 60px;
  margin-bottom: 0;
  padding: 15px 0;
  a {
    font-family: "GilroyBold", sans-serif;
    color: #fff;
  }

  @media only screen and (max-width: 426px) {
    font-size: 15px;
    margin-top: 30px;
    margin-bottom: 0;
  }
`;
export const GroupParther = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  column-gap: 10px;

  span {
    font-family: "BaskervilleRegular";
    font-size: 16px;
    color: #000;
  }
  a {
    font-family: "BaskervilleRegular";
    font-size: 16px;
    color: #fff;
    text-decoration: underline;
  }
`;
