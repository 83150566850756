import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import WOW from "wowjs";
import { Helmet } from "react-helmet";

import TopInterna from "../../components/TopoInterna";
import Menu from "../../components/Menu";
import Reserva from "../../components/Reserva";
import NewReserva from "../../components/NewReserva";
import Informacoes from "../../components/Informacoes";
import ReservaMobile from "../../components/Reserva/mobile.js";
import SelecionarEstrutura from "../../components/Estruturas/selecionar.js";

import ImageEstrutura1 from "../../assets/estrutura/hotel/hotel-01.jpg";
import ImageEstrutura2 from "../../assets/estrutura/hotel/hotel-02.jpg";
import ImageEstrutura3 from "../../assets/estrutura/hotel/hotel-03.jpg";
import ImageEstrutura4 from "../../assets/estrutura/hotel/hotel-04.jpg";
import ImageEstrutura5 from "../../assets/estrutura/hotel/hotel-05.jpg";
import ImageEstrutura6 from "../../assets/estrutura/hotel/hotel-06.jpg";
import ImageEstrutura7 from "../../assets/estrutura/hotel/hotel-07.jpg";
import ImageEstrutura8 from "../../assets/estrutura/hotel/hotel-08.jpg";
import ImageEstrutura49 from "../../assets/estrutura/hotel/hotel-09.png";
import ImageEstrutura50 from "../../assets/estrutura/hotel/hotel-10.png";
import ImageEstrutura51 from "../../assets/estrutura/hotel/hotel-11.png";
import ImageEstrutura52 from "../../assets/estrutura/hotel/hotel-12.png";
import ImageEstrutura53 from "../../assets/estrutura/hotel/hotel-13.jpg";
import ImageEstrutura54 from "../../assets/estrutura/hotel/hotel-14.jpg";
import ImageEstrutura55 from "../../assets/estrutura/hotel/hotel-15.jpg";
import ImageEstrutura56 from "../../assets/estrutura/hotel/hotel-16.jpg";
import ImageEstrutura57 from "../../assets/estrutura/hotel/hotel-17.jpg";
import ImageEstrutura58 from "../../assets/estrutura/hotel/hotel-18.jpg";
import ImageEstrutura59 from "../../assets/estrutura/hotel/hotel-19.jpg";
import ImageEstrutura60 from "../../assets/estrutura/hotel/hotel-20.jpg";
import ImageEstrutura61 from "../../assets/estrutura/hotel/hotel-21.jpg";
import ImageEstrutura62 from "../../assets/estrutura/hotel/hotel-22.jpg";
import ImageEstrutura63 from "../../assets/estrutura/hotel/hotel-23.jpg";
import ImageEstrutura64 from "../../assets/estrutura/hotel/hotel-24.jpg";
import ImageEstrutura65 from "../../assets/estrutura/hotel/hotel-25.jpg";
import ImageEstrutura66 from "../../assets/estrutura/hotel/hotel-26.jpg";
import ImageEstrutura67 from "../../assets/estrutura/hotel/hotel-27.jpg";
import ImageEstrutura68 from "../../assets/estrutura/hotel/hotel-28.jpg";
import ImageEstrutura69 from "../../assets/estrutura/hotel/hotel-29.jpg";
import ImageEstrutura70 from "../../assets/estrutura/hotel/hotel-30.jpg";
import ImageEstrutura71 from "../../assets/estrutura/hotel/hotel-31.jpg";
import ImageEstrutura72 from "../../assets/estrutura/hotel/hotel-32.jpg";
import ImageEstrutura73 from "../../assets/estrutura/hotel/hotel-33.jpg";
import ImageEstrutura74 from "../../assets/estrutura/hotel/hotel-34.jpg";
import ImageEstrutura75 from "../../assets/estrutura/hotel/hotel-35.jpg";
import ImageEstrutura76 from "../../assets/estrutura/hotel/hotel-36.jpg";
import ImageEstrutura77 from "../../assets/estrutura/hotel/hotel-37.jpg";
import ImageEstrutura78 from "../../assets/estrutura/hotel/hotel-38.jpg";
import ImageEstrutura79 from "../../assets/estrutura/hotel/hotel-39.jpg";
import ImageEstrutura80 from "../../assets/estrutura/hotel/hotel-40.jpg";
import ImageEstrutura81 from "../../assets/estrutura/hotel/hotel-41.jpg";
import ImageEstrutura82 from "../../assets/estrutura/hotel/hotel-42.jpg";
import ImageEstrutura83 from "../../assets/estrutura/hotel/hotel-43.jpg";
import ImageEstrutura84 from "../../assets/estrutura/hotel/hotel-44.jpg";
import ImageEstrutura85 from "../../assets/estrutura/hotel/hotel-45.jpg";
import ImageEstrutura86 from "../../assets/estrutura/hotel/hotel-46.jpg";
import ImageEstrutura87 from "../../assets/estrutura/hotel/hotel-47.jpg";
import ImageEstrutura88 from "../../assets/estrutura/hotel/hotel-48.jpg";
import ImageEstrutura89 from "../../assets/estrutura/hotel/hotel-49.jpg";
import ImageEstrutura90 from "../../assets/estrutura/hotel/hotel-50.jpg";
import ImageEstrutura91 from "../../assets/estrutura/hotel/hotel-51.jpg";
import ImageEstrutura92 from "../../assets/estrutura/hotel/hotel-52.jpg";
import ImageEstrutura93 from "../../assets/estrutura/hotel/hotel-53.jpg";
import ImageEstrutura94 from "../../assets/estrutura/hotel/hotel-54.jpg";
import ImageEstrutura95 from "../../assets/estrutura/hotel/hotel-55.jpg";
import ImageEstrutura96 from "../../assets/estrutura/hotel/hotel-56.jpg";
import ImageEstrutura97 from "../../assets/estrutura/hotel/hotel-57.jpg";
import ImageEstrutura98 from "../../assets/estrutura/hotel/hotel-58.jpg";
import ImageEstrutura99 from "../../assets/estrutura/hotel/hotel-59.jpg";
import ImageEstrutura100 from "../../assets/estrutura/hotel/hotel-60.jpg";
import ImageEstrutura101 from "../../assets/estrutura/hotel/hotel-61.jpg";
import ImageEstrutura102 from "../../assets/estrutura/hotel/hotel-62.jpg";
import ImageEstrutura103 from "../../assets/estrutura/hotel/hotel-63.jpg";
import ImageEstrutura104 from "../../assets/estrutura/hotel/hotel-64.jpg";
import ImageEstrutura105 from "../../assets/estrutura/hotel/hotel-65.jpg";
import ImageEstrutura106 from "../../assets/estrutura/hotel/hotel-66.jpg";
import ImageEstrutura107 from "../../assets/estrutura/hotel/hotel-67.jpg";
import ImageEstrutura108 from "../../assets/estrutura/hotel/hotel-68.jpg";

import ImageEstrutura9 from "../../assets/estrutura/acomodacoes/acomodacoes-01.jpg";
import ImageEstrutura10 from "../../assets/estrutura/acomodacoes/acomodacoes-02.jpg";
import ImageEstrutura11 from "../../assets/estrutura/acomodacoes/acomodacoes-03.jpg";
import ImageEstrutura12 from "../../assets/estrutura/acomodacoes/acomodacoes-04.jpg";
import ImageEstrutura13 from "../../assets/estrutura/acomodacoes/acomodacoes-05.jpg";
import ImageEstrutura14 from "../../assets/estrutura/acomodacoes/acomodacoes-06.jpg";
import ImageEstrutura15 from "../../assets/estrutura/acomodacoes/acomodacoes-07.jpg";
import ImageEstrutura16 from "../../assets/estrutura/acomodacoes/acomodacoes-08.jpg";
import ImageEstrutura109 from "../../assets/estrutura/acomodacoes/acomodacoes-9.jpg";
import ImageEstrutura110 from "../../assets/estrutura/acomodacoes/acomodacoes-10.jpg";
import ImageEstrutura111 from "../../assets/estrutura/acomodacoes/acomodacoes-11.jpg";
import ImageEstrutura112 from "../../assets/estrutura/acomodacoes/acomodacoes-12.jpg";
import ImageEstrutura113 from "../../assets/estrutura/acomodacoes/acomodacoes-13.jpg";
import ImageEstrutura114 from "../../assets/estrutura/acomodacoes/acomodacoes-14.jpg";
import ImageEstrutura115 from "../../assets/estrutura/acomodacoes/acomodacoes-15.jpg";
import ImageEstrutura116 from "../../assets/estrutura/acomodacoes/acomodacoes-16.jpg";
import ImageEstrutura117 from "../../assets/estrutura/acomodacoes/acomodacoes-17.jpg";

import ImageEstrutura17 from "../../assets/estrutura/restaurante/restaurante-01.jpg";
import ImageEstrutura18 from "../../assets/estrutura/restaurante/restaurante-02.jpg";
import ImageEstrutura19 from "../../assets/estrutura/restaurante/restaurante-03.jpg";
import ImageEstrutura20 from "../../assets/estrutura/restaurante/restaurante-04.jpg";
import ImageEstrutura21 from "../../assets/estrutura/restaurante/restaurante-05.jpg";
import ImageEstrutura22 from "../../assets/estrutura/restaurante/restaurante-06.jpg";
import ImageEstrutura23 from "../../assets/estrutura/restaurante/restaurante-07.jpg";
import ImageEstrutura24 from "../../assets/estrutura/restaurante/restaurante-08.jpg";
import ImageEstrutura193 from "../../assets/estrutura/restaurante/restaurante-09.jpg";
import ImageEstrutura194 from "../../assets/estrutura/restaurante/restaurante-10.jpg";
import ImageEstrutura195 from "../../assets/estrutura/restaurante/restaurante-11.jpg";
import ImageEstrutura196 from "../../assets/estrutura/restaurante/restaurante-12.jpg";
import ImageEstrutura197 from "../../assets/estrutura/restaurante/restaurante-13.jpg";
import ImageEstrutura198 from "../../assets/estrutura/restaurante/restaurante-14.jpg";
import ImageEstrutura199 from "../../assets/estrutura/restaurante/restaurante-15.jpg";

import ImageEstrutura25 from "../../assets/estrutura/lazer/lazer-01.jpg";
import ImageEstrutura26 from "../../assets/estrutura/lazer/lazer-02.jpg";
import ImageEstrutura27 from "../../assets/estrutura/lazer/lazer-03.jpg";
import ImageEstrutura28 from "../../assets/estrutura/lazer/lazer-04.jpg";
import ImageEstrutura29 from "../../assets/estrutura/lazer/lazer-05.jpg";
import ImageEstrutura30 from "../../assets/estrutura/lazer/lazer-06.jpg";
import ImageEstrutura31 from "../../assets/estrutura/lazer/lazer-07.jpg";
import ImageEstrutura32 from "../../assets/estrutura/lazer/lazer-08.jpg";
import ImageEstrutura118 from "../../assets/estrutura/lazer/lazer-09.jpg";
import ImageEstrutura119 from "../../assets/estrutura/lazer/lazer-10.jpg";
import ImageEstrutura120 from "../../assets/estrutura/lazer/lazer-11.jpg";
import ImageEstrutura121 from "../../assets/estrutura/lazer/lazer-12.jpg";
import ImageEstrutura122 from "../../assets/estrutura/lazer/lazer-13.jpg";
import ImageEstrutura123 from "../../assets/estrutura/lazer/lazer-14.jpg";
import ImageEstrutura124 from "../../assets/estrutura/lazer/lazer-15.jpg";
import ImageEstrutura125 from "../../assets/estrutura/lazer/lazer-16.jpg";
import ImageEstrutura126 from "../../assets/estrutura/lazer/lazer-17.jpg";
import ImageEstrutura127 from "../../assets/estrutura/lazer/lazer-18.jpg";
import ImageEstrutura128 from "../../assets/estrutura/lazer/lazer-19.jpg";
import ImageEstrutura129 from "../../assets/estrutura/lazer/lazer-20.jpg";
import ImageEstrutura130 from "../../assets/estrutura/lazer/lazer-21.jpg";
import ImageEstrutura131 from "../../assets/estrutura/lazer/lazer-22.jpg";
import ImageEstrutura132 from "../../assets/estrutura/lazer/lazer-23.jpg";
import ImageEstrutura133 from "../../assets/estrutura/lazer/lazer-24.jpg";
import ImageEstrutura134 from "../../assets/estrutura/lazer/lazer-25.jpg";
import ImageEstrutura135 from "../../assets/estrutura/lazer/lazer-26.jpg";
import ImageEstrutura136 from "../../assets/estrutura/lazer/lazer-27.jpg";
import ImageEstrutura137 from "../../assets/estrutura/lazer/lazer-28.jpg";
import ImageEstrutura138 from "../../assets/estrutura/lazer/lazer-29.jpg";
import ImageEstrutura139 from "../../assets/estrutura/lazer/lazer-30.jpg";
import ImageEstrutura140 from "../../assets/estrutura/lazer/lazer-31.jpg";
import ImageEstrutura141 from "../../assets/estrutura/lazer/lazer-32.jpg";
import ImageEstrutura142 from "../../assets/estrutura/lazer/lazer-33.jpg";
import ImageEstrutura143 from "../../assets/estrutura/lazer/lazer-34.jpg";
import ImageEstrutura144 from "../../assets/estrutura/lazer/lazer-35.jpg";
import ImageEstrutura145 from "../../assets/estrutura/lazer/lazer-36.jpg";
import ImageEstrutura146 from "../../assets/estrutura/lazer/lazer-37.jpg";
import ImageEstrutura147 from "../../assets/estrutura/lazer/lazer-38.jpg";
import ImageEstrutura148 from "../../assets/estrutura/lazer/lazer-39.jpg";
import ImageEstrutura149 from "../../assets/estrutura/lazer/lazer-40.jpg";
import ImageEstrutura150 from "../../assets/estrutura/lazer/lazer-41.jpg";
import ImageEstrutura151 from "../../assets/estrutura/lazer/lazer-42.jpg";
import ImageEstrutura152 from "../../assets/estrutura/lazer/lazer-43.jpg";
import ImageEstrutura153 from "../../assets/estrutura/lazer/lazer-44.jpg";
import ImageEstrutura154 from "../../assets/estrutura/lazer/lazer-45.jpg";
import ImageEstrutura155 from "../../assets/estrutura/lazer/lazer-46.jpg";

import ImageEstrutura33 from "../../assets/estrutura/fazenda/fazenda-01.jpg";
import ImageEstrutura34 from "../../assets/estrutura/fazenda/fazenda-02.jpg";
import ImageEstrutura35 from "../../assets/estrutura/fazenda/fazenda-03.jpg";
import ImageEstrutura36 from "../../assets/estrutura/fazenda/fazenda-04.jpg";
import ImageEstrutura37 from "../../assets/estrutura/fazenda/fazenda-05.jpg";
import ImageEstrutura38 from "../../assets/estrutura/fazenda/fazenda-06.jpg";
import ImageEstrutura39 from "../../assets/estrutura/fazenda/fazenda-07.jpg";
import ImageEstrutura40 from "../../assets/estrutura/fazenda/fazenda-08.jpg";
import ImageEstrutura156 from "../../assets/estrutura/fazenda/fazenda-09.jpg";
import ImageEstrutura157 from "../../assets/estrutura/fazenda/fazenda-10.jpg";
import ImageEstrutura158 from "../../assets/estrutura/fazenda/fazenda-11.jpg";
import ImageEstrutura159 from "../../assets/estrutura/fazenda/fazenda-12.jpg";
import ImageEstrutura160 from "../../assets/estrutura/fazenda/fazenda-13.jpg";
import ImageEstrutura161 from "../../assets/estrutura/fazenda/fazenda-14.jpg";
import ImageEstrutura162 from "../../assets/estrutura/fazenda/fazenda-15.jpg";
import ImageEstrutura163 from "../../assets/estrutura/fazenda/fazenda-16.jpg";
import ImageEstrutura164 from "../../assets/estrutura/fazenda/fazenda-17.jpg";
import ImageEstrutura165 from "../../assets/estrutura/fazenda/fazenda-18.jpg";
import ImageEstrutura166 from "../../assets/estrutura/fazenda/fazenda-19.jpg";
import ImageEstrutura167 from "../../assets/estrutura/fazenda/fazenda-20.jpg";
import ImageEstrutura168 from "../../assets/estrutura/fazenda/fazenda-21.jpg";
import ImageEstrutura169 from "../../assets/estrutura/fazenda/fazenda-22.jpg";
import ImageEstrutura170 from "../../assets/estrutura/fazenda/fazenda-23.jpg";
import ImageEstrutura171 from "../../assets/estrutura/fazenda/fazenda-24.jpg";
import ImageEstrutura172 from "../../assets/estrutura/fazenda/fazenda-25.jpg";
import ImageEstrutura173 from "../../assets/estrutura/fazenda/fazenda-26.jpg";
import ImageEstrutura174 from "../../assets/estrutura/fazenda/fazenda-27.jpg";
import ImageEstrutura175 from "../../assets/estrutura/fazenda/fazenda-28.jpg";
import ImageEstrutura176 from "../../assets/estrutura/fazenda/fazenda-29.jpg";
import ImageEstrutura177 from "../../assets/estrutura/fazenda/fazenda-30.jpg";
import ImageEstrutura178 from "../../assets/estrutura/fazenda/fazenda-31.jpg";
import ImageEstrutura179 from "../../assets/estrutura/fazenda/fazenda-32.jpg";
import ImageEstrutura180 from "../../assets/estrutura/fazenda/fazenda-33.jpg";
import ImageEstrutura181 from "../../assets/estrutura/fazenda/fazenda-34.jpg";
import ImageEstrutura182 from "../../assets/estrutura/fazenda/fazenda-35.jpg";
import ImageEstrutura183 from "../../assets/estrutura/fazenda/fazenda-36.jpg";
import ImageEstrutura184 from "../../assets/estrutura/fazenda/fazenda-37.jpg";
import ImageEstrutura185 from "../../assets/estrutura/fazenda/fazenda-38.jpg";
import ImageEstrutura186 from "../../assets/estrutura/fazenda/fazenda-39.jpg";
import ImageEstrutura187 from "../../assets/estrutura/fazenda/fazenda-40.jpg";
import ImageEstrutura188 from "../../assets/estrutura/fazenda/fazenda-41.jpg";
import ImageEstrutura189 from "../../assets/estrutura/fazenda/fazenda-42.jpg";
import ImageEstrutura190 from "../../assets/estrutura/fazenda/fazenda-43.jpg";
import ImageEstrutura191 from "../../assets/estrutura/fazenda/fazenda-44.jpg";
import ImageEstrutura192 from "../../assets/estrutura/fazenda/fazenda-45.jpg";

import ImageEstrutura41 from "../../assets/estrutura/eventos/eventos-01.jpg";
import ImageEstrutura42 from "../../assets/estrutura/eventos/eventos-02.jpg";
import ImageEstrutura43 from "../../assets/estrutura/eventos/eventos-03.jpg";
import ImageEstrutura44 from "../../assets/estrutura/eventos/eventos-04.jpg";
import ImageEstrutura45 from "../../assets/estrutura/eventos/eventos-05.jpg";
import ImageEstrutura46 from "../../assets/estrutura/eventos/eventos-06.jpg";
import ImageEstrutura47 from "../../assets/estrutura/eventos/eventos-07.jpg";
import ImageEstrutura48 from "../../assets/estrutura/eventos/eventos-08.jpg";

import ImageHistoria1 from "../../assets/estrutura/historia/historia1.jpg";
import ImageHistoria2 from "../../assets/estrutura/historia/historia2.jpg";
import ImageHistoria3 from "../../assets/estrutura/historia/historia3.jpg";
import ImageHistoria4 from "../../assets/estrutura/historia/historia4.jpg";
import ImageHistoria5 from "../../assets/estrutura/historia/historia5.jpg";
import ImageHistoria6 from "../../assets/estrutura/historia/historia6.jpg";
import ImageHistoria7 from "../../assets/estrutura/historia/historia7.jpg";
import ImageHistoria8 from "../../assets/estrutura/historia/historia8.jpg";

import ImageSpa1 from "../../assets/estrutura/spa/spa_01.jpg";
import ImageSpa2 from "../../assets/estrutura/spa/spa_02.jpg";
import ImageSpa3 from "../../assets/estrutura/spa/spa_03.jpg";
import ImageSpa4 from "../../assets/estrutura/spa/spa_04.jpg";
import ImageSpa5 from "../../assets/estrutura/spa/spa_05.jpg";
import ImageSpa6 from "../../assets/estrutura/spa/spa_06.jpg";
import ImageSpa7 from "../../assets/estrutura/spa/spa_07.jpg";
import ImageSpa8 from "../../assets/estrutura/spa/spa_08.jpg";
import ImageSpa9 from "../../assets/estrutura/spa/spa_09.jpg";
import ImageSpa10 from "../../assets/estrutura/spa/spa_10.jpg";
import ImageSpa11 from "../../assets/estrutura/spa/spa_11.jpg";
import ImageSpa12 from "../../assets/estrutura/spa/spa_12.jpg";

import ImageRevellion01 from "../../assets/estrutura/revellion/revellion_01.jpg";
import ImageRevellion02 from "../../assets/estrutura/revellion/revellion_02.jpg";
import ImageRevellion03 from "../../assets/estrutura/revellion/revellion_03.jpg";
import ImageRevellion04 from "../../assets/estrutura/revellion/revellion_04.jpg";
import ImageRevellion05 from "../../assets/estrutura/revellion/revellion_05.jpg";
import ImageRevellion06 from "../../assets/estrutura/revellion/revellion_06.jpg";
import ImageRevellion07 from "../../assets/estrutura/revellion/revellion_07.jpg";
import ImageRevellion08 from "../../assets/estrutura/revellion/revellion_08.jpg";
import ImageRevellion09 from "../../assets/estrutura/revellion/revellion_09.jpg";
import ImageRevellion10 from "../../assets/estrutura/revellion/revellion_10.jpg";
import ImageRevellion11 from "../../assets/estrutura/revellion/revellion_11.jpg";
import ImageRevellion12 from "../../assets/estrutura/revellion/revellion_12.jpg";
import ImageRevellion13 from "../../assets/estrutura/revellion/revellion_13.jpg";
import ImageRevellion14 from "../../assets/estrutura/revellion/revellion_14.jpg";
import ImageRevellion15 from "../../assets/estrutura/revellion/revellion_15.jpg";
import ImageRevellion16 from "../../assets/estrutura/revellion/revellion_16.jpg";
import ImageRevellion17 from "../../assets/estrutura/revellion/revellion_17.jpg";
import ImageRevellion18 from "../../assets/estrutura/revellion/revellion_18.jpg";
import ImageRevellion19 from "../../assets/estrutura/revellion/revellion_19.jpg";
import ImageRevellion20 from "../../assets/estrutura/revellion/revellion_20.jpg";
import ImageRevellion21 from "../../assets/estrutura/revellion/revellion_21.jpg";
import ImageRevellion22 from "../../assets/estrutura/revellion/revellion_22.jpg";
import ImageRevellion23 from "../../assets/estrutura/revellion/revellion_23.jpg";
import ImageRevellion24 from "../../assets/estrutura/revellion/revellion_24.jpg";
import ImageRevellion25 from "../../assets/estrutura/revellion/revellion_25.jpg";
import ImageRevellion26 from "../../assets/estrutura/revellion/revellion_26.jpg";
import ImageRevellion27 from "../../assets/estrutura/revellion/revellion_27.jpg";
import ImageRevellion28 from "../../assets/estrutura/revellion/revellion_28.jpg";
import ImageRevellion29 from "../../assets/estrutura/revellion/revellion_29.jpg";
import ImageRevellion30 from "../../assets/estrutura/revellion/revellion_30.jpg";
import ImageRevellion31 from "../../assets/estrutura/revellion/revellion_31.jpg";
import ImageRevellion32 from "../../assets/estrutura/revellion/revellion_32.jpg";
import ImageRevellion33 from "../../assets/estrutura/revellion/revellion_33.jpg";
import ImageRevellion34 from "../../assets/estrutura/revellion/revellion_34.jpg";
import ImageRevellion35 from "../../assets/estrutura/revellion/revellion_35.jpg";
import ImageRevellion36 from "../../assets/estrutura/revellion/revellion_36.jpg";
import ImageRevellion37 from "../../assets/estrutura/revellion/revellion_37.jpg";
import ImageRevellion38 from "../../assets/estrutura/revellion/revellion_38.jpg";
import ImageRevellion39 from "../../assets/estrutura/revellion/revellion_39.jpg";
import ImageRevellion40 from "../../assets/estrutura/revellion/revellion_40.jpg";
import ImageRevellion41 from "../../assets/estrutura/revellion/revellion_41.jpg";
import ImageRevellion42 from "../../assets/estrutura/revellion/revellion_42.jpg";
import ImageRevellion43 from "../../assets/estrutura/revellion/revellion_43.jpg";
import ImageRevellion44 from "../../assets/estrutura/revellion/revellion_44.jpg";
import ImageRevellion45 from "../../assets/estrutura/revellion/revellion_45.jpg";
import ImageRevellion46 from "../../assets/estrutura/revellion/revellion_46.jpg";
import ImageRevellion47 from "../../assets/estrutura/revellion/revellion_47.jpg";
import ImageRevellion48 from "../../assets/estrutura/revellion/revellion_48.jpg";
import ImageRevellion49 from "../../assets/estrutura/revellion/revellion_49.jpg";

import ImageRevellion50 from "../../assets/estrutura/revellion/revellion_50.jpg";
import ImageRevellion51 from "../../assets/estrutura/revellion/revellion_51.jpg";
import ImageRevellion52 from "../../assets/estrutura/revellion/revellion_52.jpg";
import ImageRevellion53 from "../../assets/estrutura/revellion/revellion_53.jpg";
import ImageRevellion54 from "../../assets/estrutura/revellion/revellion_54.jpg";
import ImageRevellion55 from "../../assets/estrutura/revellion/revellion_55.jpg";
import ImageRevellion56 from "../../assets/estrutura/revellion/revellion_56.jpg";
import ImageRevellion57 from "../../assets/estrutura/revellion/revellion_57.jpg";
import ImageRevellion58 from "../../assets/estrutura/revellion/revellion_58.jpg";
import ImageRevellion59 from "../../assets/estrutura/revellion/revellion_59.jpg";
import ImageRevellion60 from "../../assets/estrutura/revellion/revellion_60.jpg";
import ImageRevellion61 from "../../assets/estrutura/revellion/revellion_61.jpg";
import ImageRevellion62 from "../../assets/estrutura/revellion/revellion_62.jpg";
import ImageRevellion63 from "../../assets/estrutura/revellion/revellion_63.jpg";
import ImageRevellion64 from "../../assets/estrutura/revellion/revellion_64.jpg";
import ImageRevellion65 from "../../assets/estrutura/revellion/revellion_65.jpg";
import ImageRevellion66 from "../../assets/estrutura/revellion/revellion_66.jpg";
import ImageRevellion67 from "../../assets/estrutura/revellion/revellion_67.jpg";
import ImageRevellion68 from "../../assets/estrutura/revellion/revellion_68.jpg";
import ImageRevellion69 from "../../assets/estrutura/revellion/revellion_69.jpg";
import ImageRevellion70 from "../../assets/estrutura/revellion/revellion_70.jpg";
import ImageRevellion71 from "../../assets/estrutura/revellion/revellion_71.jpg";
import ImageRevellion72 from "../../assets/estrutura/revellion/revellion_72.jpg";
import ImageRevellion73 from "../../assets/estrutura/revellion/revellion_73.jpg";
import ImageRevellion74 from "../../assets/estrutura/revellion/revellion_74.jpg";
import ImageRevellion75 from "../../assets/estrutura/revellion/revellion_75.jpg";
import ImageRevellion76 from "../../assets/estrutura/revellion/revellion_76.jpg";
import ImageRevellion77 from "../../assets/estrutura/revellion/revellion_77.jpg";
import ImageRevellion78 from "../../assets/estrutura/revellion/revellion_78.jpg";
import ImageRevellion79 from "../../assets/estrutura/revellion/revellion_79.jpg";
import ImageRevellion80 from "../../assets/estrutura/revellion/revellion_80.jpg";
import ImageRevellion81 from "../../assets/estrutura/revellion/revellion_81.jpg";
import ImageRevellion82 from "../../assets/estrutura/revellion/revellion_82.jpg";
import ImageRevellion83 from "../../assets/estrutura/revellion/revellion_83.jpg";

import ImageArraia01 from "../../assets/estrutura/arraia/arraia_01.jpg";
import ImageArraia02 from "../../assets/estrutura/arraia/arraia_02.jpg";
import ImageArraia03 from "../../assets/estrutura/arraia/arraia_03.jpg";
import ImageArraia04 from "../../assets/estrutura/arraia/arraia_04.jpg";
import ImageArraia05 from "../../assets/estrutura/arraia/arraia_05.jpg";
import ImageArraia06 from "../../assets/estrutura/arraia/arraia_06.jpg";
import ImageArraia07 from "../../assets/estrutura/arraia/arraia_07.jpg";
import ImageArraia08 from "../../assets/estrutura/arraia/arraia_08.jpg";
import ImageArraia09 from "../../assets/estrutura/arraia/arraia_09.jpg";
import ImageArraia10 from "../../assets/estrutura/arraia/arraia_10.jpg";
import ImageArraia11 from "../../assets/estrutura/arraia/arraia_11.jpg";
import ImageArraia12 from "../../assets/estrutura/arraia/arraia_12.jpg";
import ImageArraia13 from "../../assets/estrutura/arraia/arraia_13.jpg";
import ImageArraia14 from "../../assets/estrutura/arraia/arraia_14.jpg";
import ImageArraia15a from "../../assets/estrutura/arraia/arraia_15.jpg";
import ImageArraia16 from "../../assets/estrutura/arraia/arraia_16.jpg";
import ImageArraia17 from "../../assets/estrutura/arraia/arraia_17.jpg";
import ImageArraia18 from "../../assets/estrutura/arraia/arraia_18.jpg";
import ImageArraia19 from "../../assets/estrutura/arraia/arraia_19.jpg";
import ImageArraia20 from "../../assets/estrutura/arraia/arraia_20.jpg";
import ImageArraia21 from "../../assets/estrutura/arraia/arraia_21.jpg";

import ImageNatal01 from "../../assets/estrutura/natal/natal_01.jpg";
import ImageNatal02 from "../../assets/estrutura/natal/natal_02.jpg";
import ImageNatal03 from "../../assets/estrutura/natal/natal_03.jpg";
import ImageNatal04 from "../../assets/estrutura/natal/natal_04.jpg";
import ImageNatal05 from "../../assets/estrutura/natal/natal_05.jpg";
import ImageNatal06 from "../../assets/estrutura/natal/natal_06.jpg";
import ImageNatal07 from "../../assets/estrutura/natal/natal_07.jpg";
import ImageNatal08 from "../../assets/estrutura/natal/natal_08.jpg";
import ImageNatal09 from "../../assets/estrutura/natal/natal_09.jpg";
import ImageNatal10 from "../../assets/estrutura/natal/natal_10.jpg";

import { C } from "styled-icons/simple-icons";

export default function PaginaFotos() {
  useEffect(() => {
    const wow = new WOW.WOW();
    wow.init();
  }, []);

  const GetdatasImagensHotel = [
    { id: 1, imagem: ImageEstrutura1 },
    { id: 2, imagem: ImageEstrutura2 },
    { id: 3, imagem: ImageEstrutura3 },
    { id: 4, imagem: ImageEstrutura4 },
    { id: 5, imagem: ImageEstrutura5 },
    { id: 6, imagem: ImageEstrutura6 },
    { id: 7, imagem: ImageEstrutura7 },
    { id: 8, imagem: ImageEstrutura8 },
    // { id: 9, imagem: ImageEstrutura49 },
    { id: 10, imagem: ImageEstrutura50 },
    { id: 11, imagem: ImageEstrutura51 },
    { id: 12, imagem: ImageEstrutura52 },
    { id: 13, imagem: ImageEstrutura53 },
    { id: 14, imagem: ImageEstrutura54 },
    { id: 15, imagem: ImageEstrutura55 },
    { id: 16, imagem: ImageEstrutura56 },
    { id: 17, imagem: ImageEstrutura57 },
    { id: 18, imagem: ImageEstrutura58 },
    { id: 19, imagem: ImageEstrutura59 },
    { id: 20, imagem: ImageEstrutura60 },
    { id: 21, imagem: ImageEstrutura61 },
    { id: 22, imagem: ImageEstrutura62 },
    { id: 23, imagem: ImageEstrutura63 },
    { id: 24, imagem: ImageEstrutura64 },
    { id: 25, imagem: ImageEstrutura65 },
    { id: 26, imagem: ImageEstrutura66 },
    { id: 27, imagem: ImageEstrutura67 },
    { id: 28, imagem: ImageEstrutura68 },
    { id: 29, imagem: ImageEstrutura69 },
    { id: 30, imagem: ImageEstrutura70 },
    { id: 31, imagem: ImageEstrutura71 },
    { id: 32, imagem: ImageEstrutura72 },
    { id: 33, imagem: ImageEstrutura73 },
    { id: 34, imagem: ImageEstrutura74 },
    { id: 35, imagem: ImageEstrutura75 },
    { id: 36, imagem: ImageEstrutura76 },
    { id: 37, imagem: ImageEstrutura77 },
    { id: 38, imagem: ImageEstrutura78 },
    { id: 39, imagem: ImageEstrutura79 },
    { id: 40, imagem: ImageEstrutura80 },
    { id: 41, imagem: ImageEstrutura81 },
    { id: 42, imagem: ImageEstrutura82 },
    { id: 43, imagem: ImageEstrutura83 },
    { id: 44, imagem: ImageEstrutura84 },
    { id: 45, imagem: ImageEstrutura85 },
    { id: 46, imagem: ImageEstrutura86 },
    { id: 47, imagem: ImageEstrutura87 },
    { id: 48, imagem: ImageEstrutura88 },
    { id: 49, imagem: ImageEstrutura89 },
    { id: 50, imagem: ImageEstrutura90 },
    { id: 51, imagem: ImageEstrutura91 },
    { id: 52, imagem: ImageEstrutura92 },
    { id: 53, imagem: ImageEstrutura93 },
    { id: 54, imagem: ImageEstrutura94 },
    { id: 55, imagem: ImageEstrutura95 },
    { id: 56, imagem: ImageEstrutura96 },
    { id: 57, imagem: ImageEstrutura97 },
    { id: 58, imagem: ImageEstrutura98 },
    { id: 59, imagem: ImageEstrutura99 },
    { id: 60, imagem: ImageEstrutura100 },
    { id: 61, imagem: ImageEstrutura101 },
    { id: 62, imagem: ImageEstrutura102 },
    { id: 63, imagem: ImageEstrutura103 },
    { id: 64, imagem: ImageEstrutura104 },
    { id: 65, imagem: ImageEstrutura105 },
    { id: 66, imagem: ImageEstrutura106 },
    { id: 67, imagem: ImageEstrutura107 },
    { id: 68, imagem: ImageEstrutura108 },
  ];

  const GetdatasImagensAcomodacoes = [
    { id: 1, imagem: ImageEstrutura9 },
    { id: 2, imagem: ImageEstrutura10 },
    { id: 3, imagem: ImageEstrutura11 },
    { id: 4, imagem: ImageEstrutura12 },
    { id: 5, imagem: ImageEstrutura13 },
    { id: 6, imagem: ImageEstrutura14 },
    { id: 7, imagem: ImageEstrutura15 },
    { id: 8, imagem: ImageEstrutura16 },
    { id: 9, imagem: ImageEstrutura109 },
    { id: 10, imagem: ImageEstrutura110 },
    { id: 11, imagem: ImageEstrutura111 },
    { id: 12, imagem: ImageEstrutura112 },
    { id: 13, imagem: ImageEstrutura113 },
    { id: 14, imagem: ImageEstrutura114 },
    { id: 15, imagem: ImageEstrutura115 },
    { id: 16, imagem: ImageEstrutura116 },
    { id: 17, imagem: ImageEstrutura117 },
  ];
  const GetdatasImagensArraia = [
    { id: 1, imagem: ImageArraia01 },
    { id: 2, imagem: ImageArraia02 },
    { id: 3, imagem: ImageArraia03 },
    { id: 4, imagem: ImageArraia04 },
    { id: 5, imagem: ImageArraia05 },
    { id: 6, imagem: ImageArraia06 },
    { id: 7, imagem: ImageArraia07 },
    { id: 8, imagem: ImageArraia08 },
    { id: 9, imagem: ImageArraia09 },
    { id: 10, imagem: ImageArraia10 },
    { id: 11, imagem: ImageArraia11 },
    { id: 12, imagem: ImageArraia12 },
    { id: 13, imagem: ImageArraia13 },
    { id: 14, imagem: ImageArraia14 },
    { id: 15, imagem: ImageArraia15a },
    { id: 16, imagem: ImageArraia16 },
    { id: 17, imagem: ImageArraia17 },
    { id: 18, imagem: ImageArraia18 },
    { id: 19, imagem: ImageArraia19 },
    { id: 20, imagem: ImageArraia20 },
    { id: 21, imagem: ImageArraia21 },
  ];

  const GetdatasImagensRestaurante = [
    { id: 1, imagem: ImageEstrutura17 },
    { id: 2, imagem: ImageEstrutura18 },
    { id: 3, imagem: ImageEstrutura19 },
    { id: 4, imagem: ImageEstrutura20 },
    { id: 5, imagem: ImageEstrutura21 },
    { id: 6, imagem: ImageEstrutura22 },
    { id: 7, imagem: ImageEstrutura23 },
    { id: 8, imagem: ImageEstrutura24 },
    { id: 9, imagem: ImageEstrutura193 },
    { id: 10, imagem: ImageEstrutura194 },
    { id: 11, imagem: ImageEstrutura195 },
    { id: 12, imagem: ImageEstrutura196 },
    { id: 13, imagem: ImageEstrutura197 },
    { id: 14, imagem: ImageEstrutura198 },
    { id: 15, imagem: ImageEstrutura199 },
  ];

  const GetdatasImagensAreasDeLazer = [
    { id: 1, imagem: ImageEstrutura25 },
    { id: 2, imagem: ImageEstrutura26 },
    { id: 3, imagem: ImageEstrutura27 },
    { id: 4, imagem: ImageEstrutura28 },
    { id: 5, imagem: ImageEstrutura29 },
    { id: 6, imagem: ImageEstrutura30 },
    { id: 7, imagem: ImageEstrutura31 },
    { id: 8, imagem: ImageEstrutura32 },
    { id: 9, imagem: ImageEstrutura118 },
    { id: 10, imagem: ImageEstrutura119 },
    // { id: 11, imagem: ImageEstrutura120 },
    // { id: 12, imagem: ImageEstrutura121 },
    // { id: 13, imagem: ImageEstrutura122 },
    { id: 14, imagem: ImageEstrutura123 },
    { id: 15, imagem: ImageEstrutura124 },
    { id: 16, imagem: ImageEstrutura125 },
    { id: 17, imagem: ImageEstrutura126 },
    { id: 18, imagem: ImageEstrutura127 },
    { id: 19, imagem: ImageEstrutura128 },
    { id: 20, imagem: ImageEstrutura129 },
    { id: 21, imagem: ImageEstrutura130 },
    { id: 22, imagem: ImageEstrutura131 },
    { id: 23, imagem: ImageEstrutura132 },
    { id: 24, imagem: ImageEstrutura133 },
    { id: 25, imagem: ImageEstrutura134 },
    { id: 26, imagem: ImageEstrutura135 },
    { id: 27, imagem: ImageEstrutura136 },
    { id: 28, imagem: ImageEstrutura137 },
    { id: 29, imagem: ImageEstrutura138 },
    // { id: 30, imagem: ImageEstrutura139 },
    { id: 31, imagem: ImageEstrutura140 },
    { id: 32, imagem: ImageEstrutura141 },
    { id: 33, imagem: ImageEstrutura142 },
    { id: 34, imagem: ImageEstrutura143 },
    // { id: 35, imagem: ImageEstrutura144 },
    { id: 36, imagem: ImageEstrutura145 },
    { id: 37, imagem: ImageEstrutura146 },
    { id: 38, imagem: ImageEstrutura147 },
    { id: 39, imagem: ImageEstrutura148 },
    { id: 40, imagem: ImageEstrutura149 },
    { id: 41, imagem: ImageEstrutura150 },
    { id: 42, imagem: ImageEstrutura151 },
    { id: 43, imagem: ImageEstrutura152 },
    { id: 44, imagem: ImageEstrutura153 },
    { id: 45, imagem: ImageEstrutura154 },
    { id: 46, imagem: ImageEstrutura155 },
  ];
  const GetdatasImagensFazenda = [
    { id: 1, imagem: ImageEstrutura33 },
    { id: 2, imagem: ImageEstrutura34 },
    { id: 3, imagem: ImageEstrutura35 },
    { id: 4, imagem: ImageEstrutura36 },
    { id: 5, imagem: ImageEstrutura37 },
    { id: 6, imagem: ImageEstrutura38 },
    { id: 7, imagem: ImageEstrutura39 },
    { id: 8, imagem: ImageEstrutura40 },
    { id: 9, imagem: ImageEstrutura156 },
    { id: 10, imagem: ImageEstrutura157 },
    { id: 11, imagem: ImageEstrutura158 },
    { id: 12, imagem: ImageEstrutura159 },
    { id: 13, imagem: ImageEstrutura160 },
    { id: 14, imagem: ImageEstrutura161 },
    { id: 15, imagem: ImageEstrutura162 },
    { id: 16, imagem: ImageEstrutura163 },
    { id: 17, imagem: ImageEstrutura164 },
    { id: 18, imagem: ImageEstrutura165 },
    { id: 19, imagem: ImageEstrutura166 },
    { id: 20, imagem: ImageEstrutura167 },
    { id: 21, imagem: ImageEstrutura168 },
    { id: 22, imagem: ImageEstrutura169 },
    { id: 23, imagem: ImageEstrutura170 },
    { id: 24, imagem: ImageEstrutura171 },
    { id: 25, imagem: ImageEstrutura172 },
    { id: 26, imagem: ImageEstrutura173 },
    { id: 27, imagem: ImageEstrutura174 },
    { id: 28, imagem: ImageEstrutura175 },
    { id: 29, imagem: ImageEstrutura176 },
    { id: 30, imagem: ImageEstrutura177 },
    { id: 31, imagem: ImageEstrutura178 },
    { id: 32, imagem: ImageEstrutura179 },
    { id: 33, imagem: ImageEstrutura180 },
    { id: 34, imagem: ImageEstrutura181 },
    { id: 35, imagem: ImageEstrutura182 },
    { id: 36, imagem: ImageEstrutura183 },
    { id: 37, imagem: ImageEstrutura184 },
    { id: 38, imagem: ImageEstrutura185 },
    { id: 39, imagem: ImageEstrutura186 },
    { id: 40, imagem: ImageEstrutura187 },
    { id: 41, imagem: ImageEstrutura188 },
    { id: 42, imagem: ImageEstrutura189 },
    { id: 43, imagem: ImageEstrutura190 },
    { id: 44, imagem: ImageEstrutura191 },
    { id: 45, imagem: ImageEstrutura192 },
  ];

  const GetdatasImagensEventos = [
    { id: 1, imagem: ImageEstrutura41 },
    { id: 2, imagem: ImageEstrutura42 },
    { id: 3, imagem: ImageEstrutura43 },
    { id: 5, imagem: ImageEstrutura44 },
    { id: 5, imagem: ImageEstrutura45 },
    { id: 6, imagem: ImageEstrutura46 },
    { id: 7, imagem: ImageEstrutura47 },
    { id: 8, imagem: ImageEstrutura48 },
  ];

  const GetdatasImagensHistoria = [
    { id: 1, imagem: ImageHistoria1 },
    { id: 2, imagem: ImageHistoria2 },
    { id: 3, imagem: ImageHistoria3 },
    { id: 4, imagem: ImageHistoria4 },
    { id: 5, imagem: ImageHistoria5 },
    { id: 6, imagem: ImageHistoria6 },
    { id: 7, imagem: ImageHistoria7 },
    { id: 8, imagem: ImageHistoria8 },
  ];

  const GetdatasImagensSpa = [
    { id: 1, imagem: ImageSpa1 },
    { id: 2, imagem: ImageSpa2 },
    { id: 3, imagem: ImageSpa3 },
    { id: 4, imagem: ImageSpa4 },
    { id: 5, imagem: ImageSpa5 },
    { id: 6, imagem: ImageSpa6 },
    { id: 7, imagem: ImageSpa7 },
    { id: 8, imagem: ImageSpa8 },
    { id: 9, imagem: ImageSpa9 },
    { id: 10, imagem: ImageSpa10 },
    { id: 11, imagem: ImageSpa11 },
    { id: 12, imagem: ImageSpa12 },
  ];

  const GetdatasImagensRevellion = [
    // { id: 1, imagem: ImageRevellion01 },
    // { id: 2, imagem: ImageRevellion02 },
    // { id: 3, imagem: ImageRevellion03 },
    // { id: 4, imagem: ImageRevellion04 },
    // { id: 5, imagem: ImageRevellion05 },
    // { id: 6, imagem: ImageRevellion06 },
    // { id: 7, imagem: ImageRevellion07 },
    { id: 8, imagem: ImageRevellion08 },
    { id: 9, imagem: ImageRevellion09 },
    // { id: 10, imagem: ImageRevellion10 },
    // { id: 11, imagem: ImageRevellion11 },
    { id: 12, imagem: ImageRevellion12 },
    { id: 13, imagem: ImageRevellion13 },
    { id: 14, imagem: ImageRevellion14 },
    // { id: 15, imagem: ImageRevellion15 },
    { id: 16, imagem: ImageRevellion16 },
    // { id: 17, imagem: ImageRevellion17 },
    // { id: 18, imagem: ImageRevellion18 },
    // { id: 19, imagem: ImageRevellion19 },
    { id: 20, imagem: ImageRevellion20 },
    // { id: 21, imagem: ImageRevellion21 },
    { id: 22, imagem: ImageRevellion22 },
    { id: 23, imagem: ImageRevellion23 },
    { id: 24, imagem: ImageRevellion24 },
    // { id: 25, imagem: ImageRevellion25 },
    // { id: 26, imagem: ImageRevellion26 },
    { id: 27, imagem: ImageRevellion27 },
    { id: 28, imagem: ImageRevellion28 },
    // { id: 29, imagem: ImageRevellion29 },
    // { id: 30, imagem: ImageRevellion30 },
    { id: 31, imagem: ImageRevellion31 },
    // { id: 32, imagem: ImageRevellion32 },
    // { id: 33, imagem: ImageRevellion33 },
    // { id: 34, imagem: ImageRevellion34 },
    // { id: 35, imagem: ImageRevellion35 },
    // { id: 36, imagem: ImageRevellion36 },
    // { id: 37, imagem: ImageRevellion37 },
    { id: 38, imagem: ImageRevellion38 },
    { id: 39, imagem: ImageRevellion39 },
    // { id: 40, imagem: ImageRevellion40 },
    { id: 41, imagem: ImageRevellion41 },
    // { id: 42, imagem: ImageRevellion42 },
    { id: 43, imagem: ImageRevellion43 },
    { id: 44, imagem: ImageRevellion44 },
    { id: 45, imagem: ImageRevellion45 },
    { id: 46, imagem: ImageRevellion46 },
    { id: 47, imagem: ImageRevellion47 },
    { id: 48, imagem: ImageRevellion48 },
    // { id: 49, imagem: ImageRevellion49 },
    { id: 50, imagem: ImageRevellion50 },
    // { id: 51, imagem: ImageRevellion51 },
    // { id: 52, imagem: ImageRevellion52 },
    // { id: 53, imagem: ImageRevellion53 },
    { id: 54, imagem: ImageRevellion54 },
    { id: 55, imagem: ImageRevellion55 },
    // { id: 56, imagem: ImageRevellion56 },
    { id: 57, imagem: ImageRevellion57 },
    { id: 58, imagem: ImageRevellion58 },
    { id: 59, imagem: ImageRevellion59 },
    { id: 60, imagem: ImageRevellion60 },
    { id: 61, imagem: ImageRevellion61 },
    // { id: 62, imagem: ImageRevellion62 },
    { id: 63, imagem: ImageRevellion63 },
    // { id: 64, imagem: ImageRevellion64 },
    { id: 65, imagem: ImageRevellion65 },
    { id: 66, imagem: ImageRevellion66 },
    { id: 67, imagem: ImageRevellion67 },
    { id: 68, imagem: ImageRevellion68 },
    { id: 69, imagem: ImageRevellion69 },
    { id: 70, imagem: ImageRevellion70 },
    // { id: 71, imagem: ImageRevellion71 },
    { id: 72, imagem: ImageRevellion72 },
    { id: 73, imagem: ImageRevellion73 },
    { id: 74, imagem: ImageRevellion74 },
    { id: 75, imagem: ImageRevellion75 },
    { id: 76, imagem: ImageRevellion76 },
    { id: 77, imagem: ImageRevellion77 },
    { id: 78, imagem: ImageRevellion78 },
    { id: 79, imagem: ImageRevellion79 },
    { id: 80, imagem: ImageRevellion80 },
    { id: 81, imagem: ImageRevellion81 },
    { id: 82, imagem: ImageRevellion82 },
    { id: 83, imagem: ImageRevellion83 },
  ];

  const GetdatasImagensNatal = [
    { id: 90, imagem: ImageNatal01 },
    { id: 91, imagem: ImageNatal02 },
    { id: 92, imagem: ImageNatal03 },
    { id: 93, imagem: ImageNatal04 },
    { id: 94, imagem: ImageNatal05 },
    { id: 95, imagem: ImageNatal06 },
    { id: 96, imagem: ImageNatal07 },
    { id: 97, imagem: ImageNatal08 },
    { id: 98, imagem: ImageNatal09 },
    { id: 99, imagem: ImageNatal10 },
  ];

  let datasTopo = {
    titulo: "Nossa",
    titulo2: "estrutura",
    descricao:
      "Natureza exuberante e diversos ambientes de lazer, esporte e diversão para toda a família curtir e relaxar.",
    colorTitutlo: "#392C23",
    backgroundColorTitulo: "#fff",
    fontSizeTitulo: "62px",
    fontSizeTitulo2: "52px",
    colorDescricao: "#585858",
    fontSizeDescricao: "22px",
    paddingTopTP: "70px",
    displayButton: "none",
    displayButtonFotos: "none",
  };

  const params = useParams();
  let idGallery = null;
  idGallery = params.galleryId ? params.galleryId : null;

  return (
    <>
      <Helmet>
        <title>Fotos - Resort Fazenda 3 Pinheiros</title>
      </Helmet>

      <Menu />
      <NewReserva />
      {/* <Reserva /> */}

      <TopInterna
        titulo="Galeria"
        titulo2="3 Pinheiros"
        subtitulo="Fotos"
        bgColor="#262C40"
        fontColor="#fff"
        texto="Confira as fotos do nosso Hotel e apaixone-se também por toda a beleza que você só encontra aqui."
      />

      <ReservaMobile bgColor="#262C40" />
      <SelecionarEstrutura
        galleryId={idGallery}
        datasTopo={datasTopo}
        GetdatasImagensHotel={GetdatasImagensHotel}
        GetdatasImagensAcomodacoes={GetdatasImagensAcomodacoes}
        GetdatasImagensRestaurante={GetdatasImagensRestaurante}
        GetdatasImagensAreasDeLazer={GetdatasImagensAreasDeLazer}
        GetdatasImagensFazenda={GetdatasImagensFazenda}
        GetdatasImagensEventos={GetdatasImagensEventos}
        GetdatasImagensHistoria={GetdatasImagensHistoria}
        GetdatasImagensSpa={GetdatasImagensSpa}
        GetdatasImagensRevellion={GetdatasImagensRevellion}
        GetdatasImagensArraia={GetdatasImagensArraia}
        GetdatasImagensNatal={GetdatasImagensNatal}
        datasButton={("", "none")}
      />
      <Informacoes />
    </>
  );
}
