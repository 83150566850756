import React from "react";
import Estrutura from "../Estruturas";
import * as S from "./style";

import img01 from "../../assets/casamentoHome/01.png";
import img02 from "../../assets/casamentoHome/02.png";
import img03 from "../../assets/casamentoHome/03.png";

const datasTopo = {
  titulo: "Realize seu Casamento",
  titulo2: "Em um Resort Fazenda Completo",
  descricao: "",
  colorTitutlo: "#1D4550",
  backgroundColorTitulo: "#fff",
  fontSizeTitulo: "62px",
  fontSizeTitulo2: "52px",
  colorDescricao: "#1D4550",
  fontSizeDescricao: "22px",
  paddingTopTP: "70px",
  // displayButton: "none",
  buttonText: "Saiba Mais",
  buttonBg: "#FE7422",
  buttonTextColor: "#fff",
  fontFamily: "'LibreBaskervilleItalic'",
  maxWidth: "860px",
};

const datasImagens = [
  { id: 1, imagem: img01 },
  { id: 2, imagem: img02 },
  { id: 3, imagem: img03 },
];

const Casamentos = () => {
  return (
    <S.Cointainer>
      <Estrutura
        datasImagens={datasImagens}
        datasTopo={datasTopo}
        datasButton="/casamentos"
      />
    </S.Cointainer>
  );
};
export default Casamentos;
