import Styled from "styled-components";

export const Top = Styled.div`
  text-align:center; 
  ${props => `padding-top: ${props.paddingTop}`};
  display: inline-block;


  @media only screen and (max-width: 1025px) {
    padding-top: 50px;
  }
  
  @media only screen and (max-width: 426px) {
    padding-top: 30px;
  }
  
  
`;

export const Titulo = Styled.h2`
text-align: center;  
font-family: "LibreBaskervilleItalic";
${props => `color: ${props.colorTitutlo}`};
${props => `font-size: ${props.fontSizeTitulo}`};
/* ${props => `background-color: ${props.backgroundColorTitulo}`}; */
${props => `background-color: none`};
line-height: 55px;
font-weight: 500;
margin-bottom: 18px;
  
span {
  ${props => `font-size: ${props.fontSizeTitulo2}`};
  display:block;
}   

@media only screen and (max-width: 1025px) {
  
  font-size: 52px;  
  line-height: 45px;
  span {
    font-size: 42px;
  }
}


@media only screen and (max-width: 769px) {
  
  font-size: 45px;  
  line-height: 45px;
  span {
    font-size: 38px;
  }
}



@media only screen and (max-width: 600px) {
    font-size: 42px;
    line-height: 38px;
    span {
      font-size: 35px;
    }
}

@media only screen and (max-width: 426px) {
    font-size: 32px;
    line-height: 33px;

    span { 
      font-size: 33px;
    }

}




`;

export const Descricao = Styled.p`  
${props => `font-size: ${props.fontSizeDescricao}`};
margin-top:20px;
${props => `color: ${props.colorDescricao}`};
line-height: 25px; 
width: 494px;
margin: auto; 
${props => `display: ${props.displayDescricao}`};

  @media only screen and (max-width: 1025px) {
    font-size: 20px;
    margin-top: 20px;
    line-height: 25px;
    width: 432px;
  }

  @media only screen and (max-width: 600px) {
    width: 80%;
    font-size: 18px;
    margin-top: 16px;
    line-height: 21px;
  }


  @media only screen and (max-width: 426px) {
      width: 90%;
    font-size: 17px;
  }


`;
