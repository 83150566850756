import React, { useRef, useEffect, useCallback, useState } from "react";
import { useField } from "@unform/core";
import { Container } from "./styles";

export default function InputFile({ name, label, required, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue = "", registerField, error } = useField(name);
  const [selectedFile, setSelectedFile] = useState("Selecionar ");

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "files[0]",
    });
  }, [fieldName, registerField]);

  const handleClick = event => {
    inputRef.current.click();
    return;
  };

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    setSelectedFile(fileUploaded.name);
  };

  return (
    <Container>
      <label htmlFor={fieldName}>
        {label}
        {required && <span>*</span>}
      </label>
      <input
        type="file"
        style={{ display: "none" }}
        onChange={handleChange}
        ref={inputRef}
        {...rest}
      />
      <inputFake>
        <p>{selectedFile}</p>
        <a onClick={handleClick}>Selecionar</a>
      </inputFake>
      {error && <span className="error">{error}</span>}
    </Container>
  );
}
