import React from "react";
import * as S from "./styles";

import foto01 from "../../assets/projeto-piscinas/projetoPiscina_01.jpg";
import foto02 from "../../assets/projeto-piscinas/projetoPiscina_02.jpg";
import foto03 from "../../assets/projeto-piscinas/projetoPiscina_03.jpg";

function ProjetoPiscinas() {
  return (
    <S.Main>
      <S.Container>
        <S.Title>
          Resort Fazenda 3 Pinheiros apresenta projeto de complexo aquático
        </S.Title>
        <S.SubTitle>
          A nova piscina ocupará uma área de 700 metros quadrados e tem previsão
          de inauguração em dezembro
        </S.SubTitle>
        <S.Text>
          O Resort Fazenda 3 Pinheiros iniciou em junho a transformação da sua
          maior piscina, atualmente com 150 m², em um complexo aquático que
          ocupará cerca de 700 m². Com previsão de inauguração para dezembro
          deste ano, a estrutura contará com um rio lento, dez jacuzzis, um bar
          molhado, chafariz e playground aquático. No projeto, também está
          prevista a ampliação do jardim da área, com especial atenção ao
          paisagismo. O investimento na área de lazer é o maior da última
          década.
        </S.Text>
        <S.Text>
          Ao longo de mais de 80 anos sob a gestão da família Carvalho, o 3
          Pinheiros passou por várias fases e nomes: Fazenda Três Pinheiros,
          Repouso Fazenda Três Pinheiros, Hotel Fazenda 3 Pinheiros e,
          atualmente, Resort Fazenda 3 Pinheiros.
        </S.Text>
        <S.Text>
          “Desde que ampliamos nossas instalações para nos classificarmos como
          resort, acreditávamos que um complexo aquático completaria nosso
          portfólio de atrações voltadas ao lazer. Priorizamos algumas
          modernizações e novos espaços, mas chegou a hora de realizarmos essa
          grande obra, que será mais um marco na história do 3 Pinheiros”,
          explica Yago Noel, sócio-diretor do Resort Fazenda 3 Pinheiros.
        </S.Text>
        <S.Text>
          A nova estrutura enriquecerá ainda mais o portfólio de opções de lazer
          dos hóspedes do resort e promete fazer de 2025 um ano de grande
          sucesso. “Temos certeza de que em breve teremos um belo complexo
          aquático com atrações para toda a família, o ano inteiro. Sendo um
          grande atrativo nos meses mais quentes e uma ótima opção durante toda
          a temporada, uma vez que teremos um moderno sistema de aquecimento da
          água, o que proporcionará conforto térmico até mesmo no inverno”,
          resume Yago.
        </S.Text>
        <S.Text>
          O projeto é assinado pela Construtora Conref Engenharia de Piscinas,
          empresa paulistana especializada em soluções e construções de piscinas
          de grande porte. "Estamos sempre atualizados sobre as novas tendências
          na área de piscinas e parques aquáticos no mundo e, em mais de 40
          anos, participamos de quase todos os grandes empreendimentos do setor
          no Brasil e vários no exterior. É essa experiência que trazemos para o
          Resort Fazenda 3 Pinheiros", afirma o engenheiro Antonio Carlos
          Ignacio, sócio-diretor da Conref. Os brinquedos que compõem o parque
          aquático são da Reforfibra.
        </S.Text>

        <S.GroupImages className="first">
          <img src={foto01} alt="imagem" />
        </S.GroupImages>
        <S.GroupImages className="first">
          <img src={foto02} alt="imagem" />
        </S.GroupImages>
        <S.GroupImages className="first">
          <img src={foto03} alt="imagem" />
        </S.GroupImages>
      </S.Container>
    </S.Main>
  );
}

export default ProjetoPiscinas;
