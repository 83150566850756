import styled from "styled-components";

import imageBackground from "../../assets/modal/background.png";
import loader from "../../assets/loading/ajax-loader.gif";

export const Modal = styled.div`
  display: ${props => (props.open ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 50;
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
`;

export const BoxModal = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 90%;
  max-width: 725px;
  padding: 80px;
  text-align: center;
  background: #fff;

  @media only screen and (max-width: 540px) {
    padding: 20px;
  }
`;

export const BtnFecharModal = styled.a`
  position: absolute;
  max-width: 30px;
  top: -15px;
  right: -15px;
  z-index: 1001;
  cursor: pointer;
`;

export const Title = styled.h1`
  font-family: "GilroyBold";
  color: #262c40;
  font-size: 32px;
  margin-bottom: 32px;
  @media only screen and (max-width: 540px) {
    font-size: 24px;
  }
`;

export const GroupForm = styled.div`
  width: 100%;
  display: flex;

  form {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    @media only screen and (max-width: 540px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @media only screen and (max-width: 540px) {
      width: 95%;
    }
  }
`;

export const FormFieldn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  padding: 3px;
  max-width: 417px;
  margin-right: 16px;
`;

export const GroupSubmit = styled.div`
  display: flex;
  width: 20%;
  align-items: center;
  justify-content: center;
  min-height: 60px;
`;

export const ButtonSubmit = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "GilroyBold";
  color: #fff;
  width: 132px;
  height: 54px;
  background: #ff7e01;
  color: #fff;
  padding: 16px 24px;
  margin-bottom: 13px;
  outline: 0;
  border: none;
  margin-top: 8px;
`;

export const Loader = styled.img.attrs({
  src: loader,
})`
  display: flex;
`;

export const GroupTerms = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 50px;
  input {
    margin-right: 5px;
  }
  p,
  a {
    font-family: "GilroyBold";
    font-size: medium;
    color: #999;
    text-decoration: none;
  }
  a {
    color: #000;
    margin: 0 5px;
    text-decoration: underline;
    cursor: pointer;
  }
`;
export const LegalTerms = styled.div`
  display: ${props => (props.controlTerms ? "flex" : "none")};
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;
  margin-top: 20px;
  border h3 {
    padding: 15px;
    font-family: "GilroyBold";
    font-size: medium;
    color: #999;
    margin: 20x 0 0px 0;
  }
  p {
    text-align: left;
    font-size: medium;
    padding: 15px;
    margin-bottom: 15px;
    line-height: 1.2em;
  }
`;
